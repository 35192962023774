import Navi from "./Navi";

// 네비게이션바 이미지
import photo_icon from "../../PublicPage/Navi/static/img/Group 17280.svg";
import home_icon_disabled from "../../PublicPage/Navi/static/img/home_icon_disabled.svg";

// 네비게이션바 이미지(활성상태)
import photo_icon_activated from "../../PublicPage/Navi/static/img/Group 17280 activated.svg";
import home_icon from "../../PublicPage/Navi/static/img/home_icon.svg";

const AdminNavi = (
    {
        isOpenMenu,
        setIsOpenMenu
    }:{
        isOpenMenu: boolean
        setIsOpenMenu: React.Dispatch<React.SetStateAction<boolean>>
    }
) => {
    return(
        <Navi 
            isOpenMenu={isOpenMenu}
            setIsOpenMenu={setIsOpenMenu}
            navi_item_list={[  
                {
                    name: "홈",
                    img_src: home_icon_disabled,
                    activated_img_src: home_icon,
                    navigate: "/",
                    is_badge_on: false,
                },                              
                {
                    name: "테마북",
                    img_src: photo_icon,
                    activated_img_src: photo_icon_activated,
                    navigate: "/themeBook",
                    is_badge_on: false,
                },
            ]}
        />
    )
}

export default AdminNavi;