import { useContext, useEffect, useRef, useState } from "react";
import UserProfileInfoCard from "./components/UserProfileInfoCard";
import { commonContext } from "../../App";

import CSS from "./static/css/myProfile.module.css"

const MyProfile = () => {
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [newProfileImgSrc, setNewProfileImgSrc] = useState<string>("");

    const [profileImgFile, setProfileImgFile] = useState<File | null>(null);

    const spaceWrapRef = useRef<HTMLDivElement>(null);

    const common_context = useContext(commonContext);
    
    return (
        <div className={CSS.l_my_profile_main}>
            {/* 프로파일 이미지 출력 영역 */}
            {/* <div className={CSS.l_user_profile_photo}>
                <UserProfilePhoto 
                    userImageSrc={common_context.userInfo?.img_url || ""}
                    setProfileImgFile={setProfileImgFile}
                    isEditMode={isEditMode}
                    newProfileImgSrc={newProfileImgSrc}
                    setNewProfileImgSrc={setNewProfileImgSrc}
                />
            </div> */}
            {/* 프로파일 이미지 출력 공간 확보를 위한 div */}
            {/* <div 
                className={CSS.l_space_wrap} 
                ref={spaceWrapRef}
            /> */}

            <div className={`g_card inner ${CSS.l_user_profile_content}`}>
                {/* 프로파일 이미지 출력 공간 확보를 위한 div */}
                {/* <div 
                    className={CSS.l_space_wrap}
                    ref={spaceWrapRef}
                /> */}
                {
                    common_context.userInfoData
                    &&  <UserProfileInfoCard 
                            isEditMode={isEditMode}
                            setIsEditMode={setIsEditMode}
                            profileImgFile={profileImgFile}
                            setProfileImgFile={setProfileImgFile}
                            setNewProfileImgSrc={setNewProfileImgSrc}
                            originUserInfo={common_context.userInfoData}
                        />
                }
            </div>
        </div>
    );
}
export default MyProfile;