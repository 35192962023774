import Text from "../../Component/Elements/Text/Text";
import DocsList from "./components/DocsList/DocsList";
import CSS from "./static/css/ContractDocs.module.css";
import DocsImgList from "./components/DocsImgList/DocsImgList";
import useContractDocs from "./hooks/useContractDocs.hook";
import { useEffect } from "react";
import { isAbleAccesser } from "../../Common/ts/commonTools";
import LoopAnimationModal from "../../Component/Elements/LoopAnimationModal/LoopAnimationModal";
import TitleContainerG from "../../Component/Elements/TitleContainerG/TitleContainerG";
import ReloadBtn from "../../Component/Elements/ReloadBtn/ReloadBtn";

const ContractDocs = () => {

    const {
        contractList,
        selectedContractDocs, 
        setSelectedContractDocs,
        contractListIsLoading,
    } = useContractDocs();

    useEffect(() => {
        isAbleAccesser(["C"]);
    }, [])

    return(
        <div className={CSS.l_contract_docs_main}>
            <TitleContainerG>
                <Text is_title={true}>
                    계약서
                </Text>

                <ReloadBtn />
            </TitleContainerG>
            <div className={CSS.l_contract_docs__contents}>
                <div className={CSS.l_contract_docs__contents__docs_list}>
                    <DocsList 
                        contractList={contractList}
                        selectedContractDocs={selectedContractDocs}
                        setSelectedContractDocs={setSelectedContractDocs}   
                    />
                </div>
                <div className={CSS.l_contract_docs__contents__docs}>
                    <DocsImgList 
                        selectedContractDocs={selectedContractDocs}
                    />
                </div>
            </div>


            <LoopAnimationModal 
                isModalOpen={contractListIsLoading}
            />
        </div>
    )
}

export default ContractDocs;