import { useQuery } from "@tanstack/react-query";
import { callAxios, customToast, dev_console, isSuccess, setQueryParameter, whoAmI } from "../../../Common/ts/commonTools";
import { useEffect } from "react";
import { GC_TIME, STALE_TIME } from "../../../Common/ts/const";
import { defaultApiReaponseInterface } from "../../../Common/interface/Common.interface";
import { downloadDataSumInterface } from "../interface/useFileSizeRest.interface";

const useFileSizeRest = (
    {
        preview_id,
        type,
    }:{
        preview_id: number;
        type: number;
    }
) => {
    const isAbleToRequest = () => {
        let result: boolean = false;

        if(whoAmI.isCustomer() && preview_id > 0 && type > 0){
            result = true;
        }

        return result;
    }

    const {
        data: fileSizeData = 0,
        error: fileSizeDataError,
        isLoading: fileSizeDataIsLoading,
        refetch: fileSizeDataRefetch,
    } = useQuery({
        queryKey: ["fileSizeRest", preview_id, type],
        queryFn: callDownloadDataSum,
        select: (response) => {
            if(isSuccess(response)){
                const data: defaultApiReaponseInterface<downloadDataSumInterface[]> = response.data;
                if(data.result.length > 0){
                    const result = data.result[0].image_volume || "";

                    const size = parseInt(result || "0");
                    if(isNaN(size)){
                        return 0;
                    }else{
                        return size;
                    }
                }else{
                    return 0;
                }
            }else{
                return 0;
            }
        },
        enabled: isAbleToRequest(),
        staleTime: STALE_TIME, // 5분동안은 신선함
        gcTime: GC_TIME, // 10분 캐시 유지
        refetchOnMount: false,  // 다시 마운트 되더라도 새로운 요청을 보내지 않게 처리
    })
    
    async function callDownloadDataSum(){
        const download_data_sum_url = "api/v1/pgp/download-data-sum";
        const query_parameter = setQueryParameter([
            {
                key: "preview_id",
                value: `${preview_id}`,
            },{
                key: "type",
                value: `${type}`,
            }
        ])
        const final_download_data_sum_url = download_data_sum_url.concat("?", query_parameter);
        const response = await callAxios.api({
            url: final_download_data_sum_url,
            method: "get"
        });

        dev_console.log(final_download_data_sum_url);
        dev_console.log(response);

        return response;
    }

    useEffect(() => {
        if(fileSizeDataError){
            customToast.error({msg: "사진 용량 정보를 요청하는 과정에서 문제가 발생했습니다."});
        }
    }, [fileSizeDataError])

    return({
        fileSizeData,
        fileSizeDataError,
        fileSizeDataIsLoading,
        fileSizeDataRefetch,
    })
}

export default useFileSizeRest;
