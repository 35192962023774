import DragAndDrop from "../../../../Component/Elements/DragAndDrop/DragAndDrop";
import Grid from "../../../../Component/Elements/Grid/Grid";
import Img from "../../../../Component/Elements/Media/components/Img/Img";
import { scheduleProductFolderInterface } from "../../static/interface/ProductionList.interface";
import CSS from "./static/css/ImgDargAndDropCard.module.css";
import Button from "../../../../Component/Elements/Button/Button";
import Text from "../../../../Component/Elements/Text/Text";
import { useEffect, useRef, useState } from "react";
import { arraysAreEqual, customToast, detectDuplicates, isBetweenNum } from "../../../../Common/ts/commonTools";
import check_icon from "./static/img/check_icon_custom.svg";
import Modal from "../../../../Component/Elements/Modal/Modal";
import WideCard from "../../../../Component/Elements/WideCard/WideCard";
import ImageGalleryContainer from "../../../../Component/Elements/ImageGallery/ImageGalleryContainer";
import FolderNameTag from "../../../../Component/Elements/FolderNameTag/FolderNameTag";
import ImgBlock from "../ImgBlock/ImgBlock";
import { viewerImageInterface } from "../../../../Component/Elements/ImageGallery/interface/ImageGallery.interface";
import { fileType } from "../../../../Common/ts/const";
import ReactPhotoSwipeGallery from "../../../../Component/Elements/ReactPhotoSwipeGallery/ReactPhotoSwipeGallery";
import ImgBlock_vr2 from "../ImgBlock/ImgBlock_vr2";

const ImgDargAndDropCard = (
    {
        imgFiles,
        setImgFiles,
        folder,
        needPhotoNum,
        isSatisfiedPhotoNums,
        setIsSatisfiedPhotoNums,
        disabled,
        initialImgList,
        isUseMultiFolder,
    }:{
        imgFiles?: {[key: number]: File[];};
        setImgFiles?: React.Dispatch<React.SetStateAction<{[key: number]: File[];}>>;
        folder?: scheduleProductFolderInterface;
        needPhotoNum: {
            min: number;
            max: number;
        };
        isSatisfiedPhotoNums?: {[key:number]: boolean;}
        setIsSatisfiedPhotoNums?: React.Dispatch<React.SetStateAction<{[key:number]: boolean;}>>;
        disabled?: boolean;
        initialImgList: viewerImageInterface[];
        isUseMultiFolder: boolean;
    }
) => {
    // 업로드되는 이미지 구분을 위해 사용하는 state
    // 초기값을 0으로 하는경우 미처 폴더리스트에 대한 처리가 되기 전에 0번의 폴더가 실제로 있는것처럼 업데이트되는 버그를 막기 위해 -1로 초기설정 그리고 하단에선 folderId가 -1보다 클 때만 처리하도록 해야함
    const [folderId, setFolderId] = useState<number>(-1);

    // 업로드된 이미지 주소
    const [previewUrls, setPreviewUrls] = useState<viewerImageInterface[]>([]);

    const [isUploading, setIsUploading] = useState<boolean>(false);

    const [isModalOpen, setisModalOpen] = useState<boolean>(false);
    const [detailViewImgSrc, setDetailViewImgSrc] = useState<string>("");

    const [imgListNode, setImgListNode] = useState<React.ReactNode | null>(null);

    const detailViewModalOpenHander = (src: string) => {
        setDetailViewImgSrc(src);
        setisModalOpen(true);
    }

    const detailViewModalCloseHander = () => {
        setDetailViewImgSrc("");
        setisModalOpen(false);
    }

    // input ref
    const uploadInputRef = useRef<HTMLInputElement>(null);

    // 객체에 해당 key값의 이미지 데이터 배열을 리턴하는 함수
    const getImgFileList = (folder_id: number) => {
        return !!imgFiles && !!imgFiles[folder_id] ? imgFiles[folder_id] : [];
    }

    // 새로운 이미지 데이터를 추가하는 함수
    const addFile = (files:  File[], folder_id: number) => {
        setIsUploading(true);

        const old_files = getImgFileList(folder_id);
        let total_files = [...old_files, ...files];
    
        // const is_allowd_extension: boolean = total_files.some(file => {
        //     const fileExtension = file.name.split('.').pop()?.toLowerCase();
        //     return fileExtension !== "jpg" || !file.type.startsWith("image/");
        // })

        const not_allowed_file_list = total_files.filter(file => {
            const fileExtension = file.name.split('.').pop()?.toLowerCase();

            const result = fileExtension !== "jpg" || !file.type.startsWith("image/");
            
            if(result){
                customToast.error({msg: `.jpg 확장자의 이미지만 업로드 가능합니다. (${file.name})`});
            }

            return result;
        })

        if(not_allowed_file_list.length > 0){
            customToast.info({msg: "형식에 맞지 않는 파일은 제외됩니다."});

            total_files = total_files.filter(file => {
                const fileExtension = file.name.split('.').pop()?.toLowerCase();
                return fileExtension === "jpg" && file.type.startsWith("image/");
            })
        }

        // 중복되는 파일이 있는지 검사
        detectDuplicates(total_files)
        .then((result) => {
            let final_file_list: File[] = [];
            if(result.duplicateFileNames.length > 0 || result.duplicateFiles.length > 0){
                customToast.info({msg: "중복되는 사진들은 제외됩니다."});
                final_file_list = result.uniqueFiles;
            }else {
                final_file_list = total_files;
            }

            const selected_img_file_list = getImgFileList(folder_id);
            if (!arraysAreEqual<File>(final_file_list, selected_img_file_list)) {
                if(setImgFiles){
                    setImgFiles((prev) => {
                        return {
                            ...prev,
                            [folder_id]: final_file_list
                        }
                    })
                }

                const urls = final_file_list.map((file) => {
                    const result: viewerImageInterface = {
                        file_name: file.name,
                        thumbnail_url: URL.createObjectURL(file),
                        origin_url: URL.createObjectURL(file),
                        file_type: fileType.img,
                        folder_id: folder_id,
                    }

                    return result;
                });
                setPreviewUrls(urls);
            }
        })
        .catch((error) => {
            console.error(error);
            customToast.error({msg: "파일 검증 과정에서 문제가 발생했습니다."});
        })
        .finally(() => {
            setIsUploading(false);

            // input value를 비워줘서 동일한 파일을 다시 선택할 때도 onChange 이벤트가 발생하도록 함
            if (uploadInputRef.current) {
                uploadInputRef.current.value = "";
            }
        })

    }

    // input에 파일이 업로드됬을 때, state에 해당 내용 업로드
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files || []);

        addFile(files, folderId);
    };

    // drag and drop동작
    const onDropHandler = (event: React.DragEvent<HTMLDivElement>) => {
        if(isUploading){
            customToast.info({msg: "잠시 기다려주세요"});
            return ;
        }

        const files = Array.from(event.dataTransfer.files); // 드롭된 파일들
        addFile(files, folderId);
    }

    // 특정 사진을 제거하는 함수
    const handleRemove = (index: number, folder_id: number) => {          
        if(isUploading){
            customToast.info({msg: "잠시 기다려주세요"});
            return ;
        }

        if(!window.confirm("해당 사진을 제거하시겠습니까?")){
            return ;
        }

        if(!!imgFiles && !!imgFiles[folder_id]){
            const new_list = imgFiles[folder_id].filter((_, i) => i !== index);
            if(setImgFiles){
                setImgFiles((prev) => {
                    return {
                        ...prev,
                        [folder_id]: new_list
                    }
                })
            }
    
            const newUrls = previewUrls.filter((_, i) => i !== index);
            setPreviewUrls(newUrls);
        }
    };

    // 사진 추가 버튼을 클릭했을때 input에 해당 이벤트를 전달하는 함수
    const handleClick = () => {
        if(isUploading){
            customToast.info({msg: "잠시 기다려주세요"});
            return ;
        }

        uploadInputRef.current?.click();
    };
    
    // 선택해야하는 사진의 수 안내문구를 생성해주는 함수
    const howManySelectPictuerText = ({min, max}:{min:number, max:number}) => {
        let num_text:string = "";
        
        if(min === max){
            num_text = `${min}`
        }else{
            num_text = `${min} ~ ${max}`
        }

        return `${num_text}장의 사진선택`
    }

    // 현재 업로드한 모든 사진 제거
    const removeImgAll = (folder_id: number) => {
        if(setImgFiles){
            setImgFiles((prev) => {
                const newState = { ...prev }; // 이전 상태를 복사
                delete newState[folder_id];    // 특정 key(folderId)를 삭제
                return newState;              // 새로운 객체 반환
            })
        }
        setPreviewUrls([]);
    }

    // 모든 사진 제거 버튼 동작 함수
    const handleRemoveAll = (folder?: scheduleProductFolderInterface) => {
        if(isUploading){
            customToast.info({msg: "잠시 기다려주세요"});
            return ;
        }

        if(!!imgFiles && !!imgFiles[folderId] && imgFiles[folderId].length > 0){ 
            let confirm_text: string = "모든 사진을 제거하시겠습니까?";

            if(folder){
                confirm_text = `${folder.folder_name}의 모든 사진을 제거하시겠습니까?`
            }

            if(!window.confirm(confirm_text)){
                return ;
            }
        }

        removeImgAll(folderId);
    }

    useEffect(() => {
        // 폴더 id: 폴더의 정보가 없는경우(폴더 구분 없이 업로드) 0으로 처리한다.
        const folder_id: number = folder ? folder.folder_id : 0;
        setFolderId(folder_id)
    }, [folder])

    // 초기에 렌더링됬을 때 이미지 데이터가 있다면 이를 미리보기 이미지에 추가
    useEffect(() => {
        const files = getImgFileList(folderId);
        const urls = files.map((file) => {
            const result: viewerImageInterface = {
                file_name: file.name,
                thumbnail_url: URL.createObjectURL(file),
                origin_url: URL.createObjectURL(file),
                file_type: fileType.img,    //  고객은 이미지 외의 파일을 업로드하는 일이 없으므로 무조건 이미지로 처리
                folder_id: folderId,
            }

            return result;
        });
        setPreviewUrls(urls);
    }, [folderId])

    useEffect(() => {
        if(setIsSatisfiedPhotoNums && folderId > -1){
            setIsSatisfiedPhotoNums((prev) => {

                const newStatus = isBetweenNum({
                    min: needPhotoNum.min,
                    max: needPhotoNum.max,
                    value: previewUrls.length,
                    inclusive: true
                });
        
                // 상태가 이전과 동일한 경우 업데이트하지 않음(무한 리렌더링 방지)
                if (prev[folderId] === newStatus) {
                    return prev; // 상태 변경 없음
                }

                return {
                    ...prev,
                    [folderId]: newStatus
                };
            });
        }
    }, [folderId, needPhotoNum, previewUrls, setIsSatisfiedPhotoNums])

    useEffect(() => {
        let state: number = 0;
        
        if(disabled){
            if(initialImgList.length === 0){
                state = 1;
            }else{
                state = 2;
            }
        }else{
            state = 3;
        }

        switch(state){
            case 1:
                setImgListNode(
                    <WideCard>
                        <div>
                            사진 정보가 없습니다.
                        </div>
                    </WideCard>
                );
                break ;
            case 2:
                setImgListNode(
                    <ReactPhotoSwipeGallery
                        img_list={initialImgList}
                    />
                )
                break ;
            case 3:
                setImgListNode(
                    <DragAndDrop
                        onDropHandler={onDropHandler}
                        innerText="사진 추가 버튼 혹은 드래그 앤 드롭으로 이미지파일을 업로드하세요.(jpg)"
                        isLoading={isUploading}
                    >
                        {
                            previewUrls.length > 0
                            &&  <Grid class_name_list={["g_card", "inner"]}>
                                    {
                                        previewUrls.map((item, index) => {
                                            return(
                                                <div 
                                                    className={`g_grid_item`}
                                                    key={index}
                                                >
                                                    <ImgBlock_vr2
                                                        index={index}
                                                        folder_id={folder?.folder_id || 0}
                                                        img_src={item.origin_url}
                                                        file_name={item.file_name}
                                                        removeImgFc={handleRemove}
                                                        imgClickHandler={detailViewModalOpenHander}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </Grid>
                        }
                    </DragAndDrop>
                )
                break ;
            default:
                setImgListNode(null);
        }
    }, [disabled, initialImgList, previewUrls])

    return(
        <ImageGalleryContainer class_name_list={[CSS.l_img_darg_and_drop_card_main]}>
            <div className={CSS.l_img_darg_and_drop_card__header}>
                {
                    folder && isUseMultiFolder 
                    &&  <div className={CSS.l_img_darg_and_drop_card__header__left}>
                            <FolderNameTag 
                                folder_name={folder.folder_name}
                            />
                        </div>
                }
                {
                    !disabled
                    &&  <div className={CSS.l_img_darg_and_drop_card__header__right}>
                            <div className={CSS.l_img_darg_and_drop_card__header__right__pictuer_num_contianer}>
                                <Text
                                    color={!!isSatisfiedPhotoNums && isSatisfiedPhotoNums[folder ? folder.folder_id : 0] ? "green" : undefined}
                                >
                                    {howManySelectPictuerText(needPhotoNum)} (현재 {previewUrls.length}장)
                                </Text>
                                {
                                    (!!isSatisfiedPhotoNums && isSatisfiedPhotoNums[folderId])
                                    &&  <span className={CSS.l_check_icon_cover}>
                                            <img src={check_icon} />
                                        </span>
                                }
                            </div>
                            <div className={CSS.l_img_darg_and_drop_card__header__right__btn_container}>
                                <input 
                                    type="file"
                                    multiple
                                    onChange={handleFileChange}
                                    ref={uploadInputRef}
                                    style={{
                                        display: "none"
                                    }}
                                    // accept="image/*"
                                    accept=".jpg"
                                />
                                <Button
                                    size={"size1_harf"}
                                    onClick={handleClick}
                                >
                                    사진 추가
                                </Button>
                                <Button
                                    size={"size1_harf"}
                                    purpose={"delete"}
                                    onClick={handleRemoveAll.bind(null, folder)}
                                >
                                    모두 삭제
                                </Button>
                            </div>
                        </div>
                }
            </div>

            {imgListNode}

            <Modal
                isModalOpen={isModalOpen}
                modalCloseHandler={detailViewModalCloseHander}
                isBackgroundClose={true}
            >
                <Img
                    src={detailViewImgSrc}
                    object_fit={"contain"}
                />
            </Modal>
        </ImageGalleryContainer>
    )
}

export default ImgDargAndDropCard;