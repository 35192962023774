import LoopAnimationModal from "../../Component/Elements/LoopAnimationModal/LoopAnimationModal";
import CSS from "./static/css/photoDetail.module.css";
import DownloadBtnContainer from "./components/DownloadBtnContainer/DownloadBtnContainer";
import CustomerConfirm from "./components/CustomerConfirm/CustomerConfirm";
import EventApplication from "./components/EventApplication/EventApplication";
import { useLocation } from "react-router-dom";
import { locationStateDataInterface } from "./static/interface/PhotoDetail.interface";
import usePhotoDetail from "./hooks/usePhotoDetail.hook";
import { useEffect, useRef, useState } from "react";
import CardContainer from "../../Component/Elements/CardContainer/CardContainer";
import { getMyCookieData, isAbleAccesser, isInAppBrowser } from "../../Common/ts/commonTools";
import DownloadLoadingModalForKakao from "./components/DownloadLoadingModal/DownloadLoadingModalForKakao";
import DownloadLoadingModal from "./components/DownloadLoadingModal/DownloadLoadingModal";
import Text from "../../Component/Elements/Text/Text";
import PhotoInfo from "./components/PhotoInfo/PhotoInfo";
import Modal from "../../Component/Elements/Modal/Modal";

import Tooltip from "../../Component/Elements/Tooltip/Tooltip";
import FolderImageViewer from "../../Component/Elements/FolderImageViewer/FolderImageViewer";

const PhotoDetail = () => {

    const [isPhotoInfoModalOpen, setIsPhotoInfoModalOpen] = useState<boolean>(false);

    const location = useLocation();
    const location_state_data = location.state as locationStateDataInterface;

    // 필요한 정보가 넘어오지 않았다면, 되돌려보낸다.
    if(!location_state_data || !(!!location_state_data.selected_photo_upload_id) || !(!!location_state_data.main_type)){
        window.location.href = "/photoList";
    }

    const cardRefs = useRef<Array<HTMLDivElement | null>>([]); 

    const {
        photo_type,
        download_type_code,

        isEventApplication,
        scheduleId,
        photoDetailDataIsLoading,
        upload_date,
        joined_event,
        photo_upload_id,
        customer_confirmed,
        manager_confirmed,
        confirm_reason,
        request_num,
        contract_product_id,

        folderDataList,
        scheduleDateString,
        isPrintDownloadBtnBadge,
        isPrintDownloadBtn,
        photoShootName,

        isDownloading, 
        setIsDownloading, 
        file_size, 
        download_time
    } = usePhotoDetail({
        location_state_data
    })

    useEffect(() => {
        isAbleAccesser(["C"]);
    }, [])

    const loopModalCloseHandler = () => {
        setIsDownloading(false);
    }

    const photoInfoModalOpenHandler = () => {
        setIsPhotoInfoModalOpen(true);
    }

    const photoInfoModalCloseHandler = () => {
        setIsPhotoInfoModalOpen(false);
    }

    return (
        <div className={CSS.l_photo_detail_main}>
            <Text is_title={true}>
                내 사진
            </Text>

            <div className={CSS.l_photo_detail__content_container}>
                <div className={CSS.l_left_wrap}>
                    <FolderImageViewer 
                        folderList={
                            folderDataList.map((item) => {
                                return({
                                    folder_id: item.folder_id,
                                    folder_name: item.folder_name,
                                    min_photo_count: 0,
                                    max_photo_count: 0,
                                    detail_product_id: 0,
                                    select_photo_list: item.select_photo_list.map((photo_list_item) => {
                                        return({
                                            album_save_id: 0,
                                            img_url: {
                                                small_url: photo_list_item.small_url, 
                                                middle_url: photo_list_item.middle_url
                                            },
                                            img_url_org: photo_list_item.middle_url,
                                            file_name: photo_list_item.file_name
                                        })
                                    }),
                                    folder_img_url: item.folder_img_url,
                                })
                            })
                        }
                        isReadOnly={true}
                        isUseMultiFolder={folderDataList.length > 0 && folderDataList[0].folder_id > 0}
                        cardRefs={cardRefs}
                    />
                </div>
                <div className={CSS.l_right_wrap}>

                    <PhotoInfo 
                        location_state_data={location_state_data}
                        hidden={"in_mobile"} 
                    />

                    <div 
                        className={`g_display_none_object web flex ${CSS.l_photo_info_view_btn_container}`}
                    >
                        <Tooltip 
                            onClick={photoInfoModalOpenHandler}
                            isUseIcon={true}
                        >
                            사진 상세정보 보기
                        </Tooltip>
                    </div>

                    <div className={CSS.l_btn_container}>

                        {
                            (isEventApplication && !!joined_event)
                            &&  <EventApplication 
                                    schedule_id={scheduleId}
                                    upload_date={upload_date}
                                    joined_event={joined_event}
                                    photo_upload_id={photo_upload_id}
                                />
                        }
                        
                        <CustomerConfirm
                            photo_upload_id={photo_upload_id}
                            photo_type={photo_type}
                            customer_confirmed={customer_confirmed}
                            manager_confirmed={manager_confirmed}
                            confirm_reason={confirm_reason}
                            request_num={request_num}
                            contract_product_id={contract_product_id}
                        />

                        {
                            isPrintDownloadBtn
                            &&  <DownloadBtnContainer
                                    download_type={download_type_code}
                                    file_name={`${scheduleDateString}-${getMyCookieData.name()}-${photoShootName}`}
                                    upload_date={upload_date}
                                    is_print_badge={isPrintDownloadBtnBadge}
                                    photo_upload_id={photo_upload_id}
                                    isDownloading={isDownloading}
                                    setIsDownloading={setIsDownloading}
                                />
                        }
                    </div>

                </div>
            </div>

            {/* 첫 로딩때 출력되는 모달 */}
            <LoopAnimationModal
                isModalOpen={photoDetailDataIsLoading}
            />

            {
                (isInAppBrowser() === "KakaoTalk" || isInAppBrowser() === "LINE")
                ?   <DownloadLoadingModalForKakao
                        isModalOpen={isDownloading}
                        modalCloseHandler={loopModalCloseHandler}
                        fileSize={file_size}
                        download_time={download_time}
                        auto_close={2}
                    />
                :   <DownloadLoadingModal
                        isModalOpen={isDownloading}
                        fileSize={file_size}
                        download_time={download_time}
                    />
            }

            <Modal
                isModalOpen={isPhotoInfoModalOpen}
                modalCloseHandler={photoInfoModalCloseHandler}
            >
                <PhotoInfo 
                    location_state_data={location_state_data}
                    hidden={"in_web"} 
                />
            </Modal>
        </div>
    )
}

export default PhotoDetail