import { useEffect, useState } from "react";
import CardContainer from "../../../../Component/Elements/CardContainer/CardContainer";
import TextFlexWrap from "../../../../Component/Elements/TextFlexWrap/TextFlexWrap";
import usePhotoDetail from "../../hooks/usePhotoDetail.hook";
import { locationStateDataInterface } from "../../static/interface/PhotoDetail.interface";
import CSS from "./static/css/PhotoInfo.module.css";

const PhotoInfo = (
    {
        location_state_data,
        hidden,
    }:{
        location_state_data: locationStateDataInterface;
        hidden?: "in_mobile" | "in_web";
    }
) => {
    const {
        photo_title,
        fileLength,
        scheduleDateString,
        uploadDateString,
        downloadDateString,
        photoShootName,
        photoShootTime,
        officeName,
        packageName,
        contractId,
        memo,
    } = usePhotoDetail({
        location_state_data
    })

    const [totalClassNameList, setTotalClassNameList] = useState<string[]>([])

    useEffect(() => {

        const total_class_name_list: string[] = [];

        if(hidden){
            total_class_name_list.push("g_display_none_object");

            if(hidden === "in_mobile"){
                total_class_name_list.push("mobile");
            }else{
                total_class_name_list.push("web");
            }

            total_class_name_list.push("flex");
        }

        total_class_name_list.push(CSS.l_photo_info_main);

        setTotalClassNameList(total_class_name_list);

    }, [hidden])

    return(
        <CardContainer 
            class_name_list={totalClassNameList}>
            {
                !!photoShootName
                &&  <TextFlexWrap 
                        title={photo_title}
                        text_list={[photoShootName]}
                        flex_direction={"column"}
                        justify_content={"flex_start"}
                    />
            }

            {
                !!scheduleDateString
                &&  <TextFlexWrap 
                        title={"촬영일"}
                        text_list={[scheduleDateString]}
                        flex_direction={"column"}
                        justify_content={"flex_start"}
                    />
            }

            {
                (!!photoShootTime.start_time || !!photoShootTime.end_time)
                &&  <TextFlexWrap 
                        title={"촬영시간"}
                        text_list={[photoShootTime.start_time, "-", photoShootTime.end_time]}
                        flex_direction={"column"}
                        justify_content={"flex_start"}
                    />
            }

            {
                !!officeName
                &&  <TextFlexWrap 
                        title={"촬영지점"}
                        text_list={[officeName]}
                        flex_direction={"column"}
                        justify_content={"flex_start"}
                    />
            }

            {
                !!packageName
                &&  <TextFlexWrap 
                        title={"패키지명"}
                        text_list={[packageName]}
                        flex_direction={"column"}
                        justify_content={"flex_start"}
                    />
            }

            {
                !!contractId
                &&  <TextFlexWrap 
                        title={"계약번호"}
                        text_list={[contractId]}
                        flex_direction={"column"}
                        justify_content={"flex_start"}
                    />
            }

            {/* 폴더별로 구분되지 않은경우에만 사진의 수를 출력시킨다 */}
            {
                fileLength > 0
                &&  <TextFlexWrap 
                        title={"파일 수"}
                        text_list={[`${fileLength}`]}
                        flex_direction={"column"}
                        justify_content={"flex_start"}
                    />
            }

            {
                !!uploadDateString
                &&  <TextFlexWrap 
                        title={"업로드 일자"}
                        text_list={[uploadDateString]}
                        flex_direction={"column"}
                        justify_content={"flex_start"}
                    />
            }

            {
                !!downloadDateString
                &&  <TextFlexWrap 
                        title={"다운로드 일자"}
                        text_list={[downloadDateString]}
                        flex_direction={"column"}
                        justify_content={"flex_start"}
                    />
            }
            
            {
                !!memo
                &&  <TextFlexWrap 
                        title={"메모"}
                        text_list={[memo]}
                        flex_direction={"column"}
                        justify_content={"flex_start"}
                    />
            }
        </CardContainer>
    )
}

export default PhotoInfo;