import { useEffect, useState } from "react";
import TitleBtnWrap from "../../Component/Elements/TitleBtnWrap/TitleBtnWrap";
import ProductBlock from "./components/ProductBlock/ProductBlock";
import { customToast, isAbleAccesser } from "../../Common/ts/commonTools";
import WideCard from "../../Component/Elements/WideCard/WideCard";
import UploadInputBtnContainer from "./components/UploadInputBtnContainer/UploadInputBtnContainer";
import { scheduleProductInterface } from "./static/interface/ProductionList.interface";
import CSS from "./static/css/productionList.module.css";
import { isNeedToSeletItem } from "./static/ts/productionListTools";
import { useLocation, useNavigate } from "react-router-dom";
import ProductBlockDetail from "./components/ProductBlockDetail/ProductBlockDetail";
import usePhotoProductDataREST from "./hooks/usePhotoProductDataREST.hook";
import LoopAnimation from "../../Component/Elements/LoopAnimation/LoopAnimation";
import usePhotoProductBadge from "./hooks/usePhotoProductBadge.hook";
import UploadedImageContainerModal from "./components/UploadedImageContainerModal";
import Grid from "../../Component/Elements/Grid/Grid";
import CardContainer from "../../Component/Elements/CardContainer/CardContainer";
import TitleContainerG from "../../Component/Elements/TitleContainerG/TitleContainerG";
import ReloadBtn from "../../Component/Elements/ReloadBtn/ReloadBtn";
import Text from "../../Component/Elements/Text/Text";

const ProductionList = () => {

    const [selectedProduct, setSelectedProduct] = useState<scheduleProductInterface | null>(null);

    const [searchType, setSearchType] = useState<0 | 1 | 2>(0);

    const [isUploadedImgModalOpen, setIsUploadedImgModalOpen] = useState<boolean>(false);

    // 상품 목록
    const {
        photoProductDataList,
        photoProductDataListIsLoading,
    } = usePhotoProductDataREST({
        search_type: searchType
    });

    // badge출력 정보
    const {
        photoProductBadgeList
    } = usePhotoProductBadge();

    const navigate = useNavigate();

    // 만약 초기설정된 제품 정보가 있다면 해당 제품을 선택한 제품으로 설정
    const location = useLocation();

    useEffect(() => {
        isAbleAccesser(["C"]);

        const state = location.state as {contract_product_id: number, modal_open: boolean};
        if(state){
            const contract_product_id = state.contract_product_id;
            const production_result = photoProductDataList.find((item) => item.contract_product_id === contract_product_id)
            if(production_result){
                setSelectedProduct(production_result);
                setIsUploadedImgModalOpen(state.modal_open);
            }
        }
    }, [])

    const reqestBtnHandler = () => {
        navigate("/productionRequest", {
            state: {
                production: selectedProduct
            }
        })
    }
    
    const viewReqestedInfoBtnHandler = () => {
        // setIsUploadedImgModalOpen(true);

        navigate("/productionDetail", {
            state: {
                contract_product_id: selectedProduct?.contract_product_id
            }
        })
    }

    // 제품카드를 선택할때 동작하는 핸들러
    const productOnClickHandler = (item: scheduleProductInterface) => {
        // 모바일 화면 사이즈에서는 선택이 불가능하게 막음
        if(window.innerWidth <= 854){
            customToast.error({
                msg: "제품 제작 신청은 PC환경에서만 가능합니다.", 
                toastId: "photo_product_select_is_disabled_at_mobile"
            })
            return ;
        }

        setSelectedProduct(item);
    }

    return (
        <div className={CSS.l_photo_select_main}>
            <TitleContainerG>
                <Text is_title={true}>
                    제품 제작 신청
                </Text>
                <ReloadBtn />
            </TitleContainerG>
            
            <div className={CSS.l_contents}>
                <CardContainer class_name_list={[CSS.l_select_product_container]}>
                    <TitleBtnWrap<0 | 1 | 2> 
                        valueState={searchType}
                        setValueState={setSearchType}
                        value_list={[0, 1, 2]}
                        innerText_list={["전체", "제품 제작 신청", "신청 완료"]}
                        is_activated={true}
                        is_print_badge_list={photoProductBadgeList}
                        category_tag="ProductionList_searchType"
                    />
                    {
                        photoProductDataListIsLoading
                        ?   <WideCard>
                                <LoopAnimation />
                            </WideCard>
                        :   photoProductDataList.length > 0
                            ?   <Grid>
                                    {
                                        photoProductDataList.map((item, index) => {
                                            let is_print_badge = isNeedToSeletItem(item);

                                            return (
                                                <ProductBlock 
                                                    key={index}

                                                    selectedProduct={item}
                                                    onClick={productOnClickHandler.bind(null, item)}

                                                    is_highlight={item.contract_product_id === selectedProduct?.contract_product_id}
                                                    is_hover_action_activate={true}
                                                    is_print_badge={is_print_badge}
                                                />
                                            )
                                        })
                                    }
                                </Grid>
                            :   <WideCard>
                                    제품 정보가 없습니다.
                                </WideCard>
                    }
                </CardContainer>
                {
                    selectedProduct
                    &&  <div className={`g_display_none_object mobile flex ${CSS.l_product_info}`}>
                            <ProductBlockDetail
                                selectedProduct={selectedProduct}
                                is_highlight={false}
                                is_hover_action_activate={false}
                            />                                
                            {
                                (selectedProduct.detail && selectedProduct.detail.length > 0)
                                &&   <UploadInputBtnContainer 
                                        selectedProduct={selectedProduct}
                                        reqestBtnHandler={reqestBtnHandler}
                                        viewReqestedInfoBtnHandler={viewReqestedInfoBtnHandler}
                                    />
                            }
                        </div>
                }
            </div>

            {
                selectedProduct
                &&  <UploadedImageContainerModal
                        selectedProduct={selectedProduct}
                        isModalOpen={isUploadedImgModalOpen}
                        setIsModalOpen={setIsUploadedImgModalOpen}
                    />
            }
        </div>
    )
}

export default ProductionList;