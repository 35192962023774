import { MouseEventHandler } from "react";
import CSS from "../../../static/css/viewDetailBtn.module.css";
import right_move_arrow from "../../../static/img/right_move_arrow.svg";
import Text from "../../../../../../Component/Elements/Text/Text";

const ViewDetailBtn = (
    {
        onClick
    }:{
        onClick: MouseEventHandler<HTMLDivElement>
    }
) => {
    return(
        <div 
            className={`g_click_event_item ${CSS.l_view_detail_btn_main}`}
            onClick={onClick}
        >
            <Text
                size={"size1"}
                color={"dark"}
            >
                View Detail
            </Text>
            <img
                className={CSS.l_view_detail_btn__icon} 
                src={right_move_arrow}
            />
        </div>
    )
}

export default ViewDetailBtn;