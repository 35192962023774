import { forwardRef, useEffect, useId, useState } from "react";
import Input from "../Input/Input";
import CSS from "./static/css/inputWrap.module.css";
import Text from "../Text/Text";

interface InputWrapInterface{
    label?: any;
    value?: string | number | readonly string[];
    onChange?: React.ChangeEventHandler<HTMLInputElement>; 
    type?: React.HTMLInputTypeAttribute;
    is_disabled?: boolean;
    is_readOnly?: boolean;
    name?: string;
    maxLength?: number;
    placeholder?: string;
    input_size?: "size1" | "size2" | "size3" | "size_full";
    checked?: boolean;
    justify_content?: "flex_start" | "flex_end" | "center" | "space_around" | "space_between" | "space_evenly";
    flex_direction?: "row" | "column" | "row_reverse" | "column_reverse";
    class_name_list?: string[];
}

const InputWrap = forwardRef<HTMLInputElement, InputWrapInterface> (({ 
    label, 
    value, 
    onChange, 
    type,
    is_disabled,
    is_readOnly,
    name,
    maxLength,
    placeholder,
    input_size,
    checked,
    justify_content,
    flex_direction,
    class_name_list,
}, ref) => {

    const [totalClassName, setTotalClassName] = useState<string>("");

    const input_id = useId();

    useEffect(() => {
        const total_class_name_list: string[] = [];

        if(class_name_list){
            for(const class_name of class_name_list){
                total_class_name_list.push(class_name);
            }
        }

        total_class_name_list.push(CSS.l_input_wrap_main);

        if(flex_direction){
            total_class_name_list.push(CSS.flex_direction);
            total_class_name_list.push(CSS[flex_direction]);
        }

        if(justify_content){
            total_class_name_list.push(CSS.justify_content);
            total_class_name_list.push(CSS[justify_content]);
        }

        setTotalClassName(total_class_name_list.join(" "));
    }, [class_name_list, justify_content, flex_direction])

    return (
        <div className={totalClassName}>
            {
                label
                &&  <label
                        className={CSS.l_input_wrap__label}
                        htmlFor={input_id}
                    >
                        <Text
                            color={"dark"}
                        >
                            {label}
                        </Text>
                    </label>
            }
            <Input
                id={input_id}
                type={type}
                value={value}
                onChange={onChange}
                disabled={is_disabled}
                readOnly={is_readOnly}
                name={name}
                placeholder={placeholder}
                maxLength={maxLength || 100}
                ref={ref}
                size={input_size}
                checked={checked}
            />
        </div>
    )
})

export default InputWrap;