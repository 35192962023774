import CSS from "./static/css/TitleContainerG.module.css";

const TitleContainerG = (
    {
        children
    }:{
        children?: React.ReactNode;
    }
) => {
    return(
        <div className={CSS.l_title_container_g_main}>
            {children}
        </div>
    )
}

export default TitleContainerG;