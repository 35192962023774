import { useEffect, useState } from "react";
import { isAbleAccesser, whoAmI } from "../../Common/ts/commonTools";
import ThemeBook from "./ThemeBook";
import TitleBtnWrap from "../../Component/Elements/TitleBtnWrap/TitleBtnWrap";
import CSS from "./static/css/themeBookHome.module.css";
import useOffiecListFromRegion from "./hooks/useOffiecListFromRegion.hook";
import { regionInterface } from "../../Common/interface/Common.interface";

const ThemeBookHome = () => {

    //  공용으로 사용하는 state
    const [isAdminMode, setIsAdminMode] = useState<boolean>(false);

    const {
        REGION_LIST,
        selectedRegion,
        setSelectedRegion,
        officeList,
        selectedOfficeId,
        setSelectedOfficeId,
        photoProductList,
        selectedPhotoProduct,
        setSelectedPhotoProduct,
        themeList,
        isLoading,
        isError,
    } = useOffiecListFromRegion();
    
    useEffect(() => {
        // 접속이 가능한 계정인지 검증
        isAbleAccesser(["C", "E"]);

        // 매니저계정이라면 편집모드를 활성화시킨다.
        setIsAdminMode(whoAmI.isAdmin()); 
    }, [])

    if(isAdminMode){
        return (
            <ThemeBook 
                photoProductList={photoProductList || []}
                selectedPhotoProduct={selectedPhotoProduct}
                setSelectedPhotoProduct={setSelectedPhotoProduct}
                themeList={themeList || []}
                isLoading={isLoading}
            />
        )
    }

    return (
        <ThemeBook
            photoProductList={photoProductList || []}
            selectedPhotoProduct={selectedPhotoProduct}
            setSelectedPhotoProduct={setSelectedPhotoProduct}
            themeList={themeList || []}
            isLoading={isLoading}
        >
            <div className={CSS.l_theme_book_customer_btn_wrap_main}>
                <TitleBtnWrap<regionInterface | null>
                    valueState={selectedRegion}
                    setValueState={setSelectedRegion}
                    value_list={REGION_LIST}
                    innerText_list={REGION_LIST.map((item) => item.name)}
                    is_activated={true}
                    is_print_badge_list={[]}
                    is_loading={isLoading}
                    category_tag="ThemeBook_selectedRegion"
                />
                <TitleBtnWrap<number>
                    valueState={selectedOfficeId}
                    setValueState={setSelectedOfficeId}
                    value_list={!!officeList ? officeList.map((item) => item.office_id) : []}
                    innerText_list={!!officeList ? officeList.map((item) => `${item.office_name}`) : []}
                    is_activated={true}
                    is_print_badge_list={[]}
                    is_loading={isLoading}
                    category_tag="ThemeBook_selectedOfficeId"
                />
            </div>
        </ThemeBook>
    )
}

export default ThemeBookHome;