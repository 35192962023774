import { DayHeaderContentArg } from "@fullcalendar/core";
import Text from "../../Text/Text";

const DayHeader = (dayHeaderCellInfo: DayHeaderContentArg) => {

    const day_header_list: string[] = ["일", "월", "화", "수", "목", "금", "토"];

    return(
        <Text
            size={"size3"}
            bold={true}
        >
            {day_header_list[dayHeaderCellInfo.dow]}
        </Text>
    )
}

export default DayHeader;