import { callAxios, customToast, dev_console, isSuccess, whoAmI } from "../../../Common/ts/commonTools";
import { useQuery } from "@tanstack/react-query";
import { GC_TIME, STALE_TIME } from "../../../Common/ts/const";
import { defaultApiReaponseInterface } from "../../../Common/interface/Common.interface";
import { contractListDataInterace } from "../interface/ContractDocs.interface";
import { useEffect } from "react";

const useContractListREST = () => {
    const isAbleToRequest = () => {
        let result: boolean = false;

        if(whoAmI.isCustomer()){
            result = true;
        }

        return result;
    }

    const {
        data: contractList = [],
        error: contractListError,
        isLoading: contractListIsLoading
    } = useQuery({
        queryKey: ["contractListData"],
        queryFn: callContractListData,
        select: (response) => {
            if(isSuccess(response)){
                const data:defaultApiReaponseInterface<contractListDataInterace[]> = response.data;
                return data.result;
            }else{
                return [];
            }
        },
        enabled: isAbleToRequest(),
        staleTime: STALE_TIME, // 5분동안은 신선함
        gcTime: GC_TIME, // 10분 캐시 유지
        refetchOnMount: false,  // 다시 마운트 되더라도 새로운 요청을 보내지 않게 처리
    })

    async function callContractListData() {
        const contract_list_url = "api/v3/customers/contract/list";
        const result = await callAxios.api({
            url: contract_list_url,
            method: "get",
        })

        dev_console.log(contract_list_url);
        dev_console.log(result);

        return result;
    }

    useEffect(() => {
        if(contractListError){
            customToast.error({msg: "계약서 목록을 요청하는 과정에서 문제가 발생했습니다."});
        }
    }, [contractListError])

    return({
        contractList,
        contractListError,
        contractListIsLoading,
    })
}

export default useContractListREST;