import Text from "../Text/Text";
import CSS from "./static/css/FolderNameTag.module.css";

const FolderNameTag = (
    {
        folder_name
    }:{
        folder_name: string
    }
) => {
    return(
        <Text
            size={"size5"}
            bold={true}
            class_name_list={[CSS.l_folder_name_tag_main]}
            text_wrap={"pretty"}
        >
            {folder_name}
        </Text>
    )
}

export default FolderNameTag;