import { ChangeEvent, useRef, useState } from 'react';
import { addHyphens, customToast, dev_console, validateInput } from '../../Common/ts/commonTools';
import PhoneNumberInput from './components/PhoneNumberInput';
import NameInput from './components/NameInput';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import logo from '../../Common/image/EDRP-OOPS-02.png';
import IDInput from './components/IDInput';
import CSS from "./static/css/resetUserPassword.module.css";
import Button from '../../Component/Elements/Button/Button';
import { toast } from 'react-toastify';
import Text from '../../Component/Elements/Text/Text';
import Img from '../../Component/Elements/Media/components/Img/Img';

const ResetUserPassword = () => {

    const [userId, setUserId] = useState<string>("");
    const [userName, setUserName] = useState<string>("");
    const [userPhone, setUserPhone] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [resultData, setResultData] = useState<{msg:string, sign: boolean} | null>();

    const idInputRef = useRef<HTMLInputElement>(null);
    const nameInputRef = useRef<HTMLInputElement>(null);
    const phoneInputRef = useRef<HTMLInputElement>(null);

    const navigate = useNavigate();

    const handleIDInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setResultData(null);
        setUserId(event.target.value);
    }

    const handleNameInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setResultData(null);
        setUserName(event.target.value)
    }

    const handlePhoneInputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        setResultData(null);
        const formattedPhone = addHyphens(event.target.value);
        setUserPhone(formattedPhone);
    }

    const handleTempPasswordDivOnClick = () => {
        if(resultData){

            navigator.clipboard.writeText(resultData.msg)
            .then(() => {
                toast.success("클립보드에 복사되었습니다.");
            })
            .catch(() => {
                toast.error("클립보드 복사에 실패했습니다.");
            })
        }
    }

    const onSubmite = () => {

        if(isLoading){
            return ;
        }

        if(userId.length === 0){
            idInputRef.current?.focus();
            toast.error("ID를 입력해주세요.");
            return ;
        }else if(!validateInput(userId)){
            setUserId("");
            idInputRef.current?.focus();
            toast.error("사용할수 없는 문자가 감지되었습니다.");
            return ;
        }

        if(userName.length === 0){
            nameInputRef.current?.focus();
            toast.error("이름을 입력해주세요.");
            return ;
        }else if(!validateInput(userName, true)){
            setUserName("");
            nameInputRef.current?.focus();
            toast.error("사용할수 없는 문자가 감지되었습니다.");
            return ;
        }

        if(userPhone.length === 0){
            phoneInputRef.current?.focus();
            toast.error("연락처를 입력해주세요.");
            return ;
        }else if(!validateInput(userPhone)){
            setUserPhone("")
            phoneInputRef.current?.focus();
            toast.error("사용할수 없는 문자가 감지되었습니다.");
            return ;
        }

        if(!window.confirm("비밀번호를 초기화 하시겠습니까?")){
            return ;
        }

        setIsLoading(true);
        setResultData(null);

        const data = {
            username: userId,
            phone: userPhone,
            name: userName,
        }

        const url = `${process.env.REACT_APP_API_URL}/api/v1/accounts/new_password/`
        axios.post(url, data)
        .then((response) => {
            dev_console.log(url);
            dev_console.log(response);

            if(response.status === 200){
                setResultData({
                    msg: `${response.data.password}`,
                    sign: true,
                });
            }else{
                customToast.error({msg: "입력하신 정보를 확인해주세요."});
            }

        })
        .catch((error) => {
            dev_console.error(error);
            
            if(error.response.status === 400){
                if(error.response.data.msg === "user info error"){
                    customToast.error({msg: "입력하신 정보를 확인해주세요."});
                }
            }else{
                customToast.error({msg: "요청중에 문제가 발생했습니다. 다시 시도해주세요."});
            }
        })
        .finally(() => {
            setIsLoading(false);
        })

    }

    return (
        <div className={CSS.l_reset_user_password_main}>
            <div className={CSS.l_reset_user_password__contents}>
                <div
                    className={CSS.l_reset_user_password__contents__l_logo_container}
                    onClick={() => {
                        navigate("/");
                    }}
                >
                    <div className={CSS.l_reset_user_password__contents__l_logo_container__img_cover}>
                        <Img
                            className={CSS.l_reset_user_password__contents__l_logo_container__img_cover__img}
                            src={logo}
                            object_fit={"contain"}
                        />
                    </div>
                    <Text
                        color={"dark"}
                        size={"size6"}
                        bold={true}
                    >
                        비밀번호 초기화
                    </Text>
                </div>

                {
                    resultData
                    ?   <div className={CSS.l_reset_user_password__contents__result_container}>
                            <div
                                className={`g_click_event_item`}
                                onClick={handleTempPasswordDivOnClick}
                            >
                                <Text
                                    size={"size7"}
                                    bold={true}
                                    color={"mint"}
                                >
                                    {resultData.msg}
                                </Text>
                            </div>
                            <div className={CSS.l_reset_user_password__contents__result_container__text_container}>
                                <Text>
                                    임시비밀번호로 로그인 하신 후
                                </Text>
                                <Text>
                                    비밀번호를 변경해주세요.
                                </Text>
                            </div>
                        </div>
                    :   <div className={CSS.l_reset_user_password__contents__input_container}>
                            <div className={CSS.l_reset_user_password__contents__input_container__input_wrap}>
                                <Text color={"dark"}>
                                    ID
                                </Text>
                                <IDInput 
                                    value={userId}
                                    onChange={handleIDInputOnChange}
                                    ref={idInputRef}
                                />
                            </div>

                            <div className={CSS.l_reset_user_password__contents__input_container__input_wrap}>
                                <Text color={"dark"}>
                                    이름
                                </Text>
                                <NameInput 
                                    value={userName}
                                    onChange={handleNameInputOnChange}
                                    ref={nameInputRef}
                                />
                            </div>

                            <div className={CSS.l_reset_user_password__contents__input_container__input_wrap}>
                                <Text color={"dark"}>
                                    전화번호
                                </Text>
                                <div>
                                    <PhoneNumberInput 
                                        value={addHyphens(userPhone)} 
                                        onChange={handlePhoneInputOnChange} 
                                        ref={phoneInputRef}
                                    />
                                </div>
                            </div>
                        </div>
                }

                <div className={CSS.l_reset_user_password__contents__btn_container}>
                    {
                        resultData
                        ?   <Button
                                padding={"large"}
                                size={"size_full"}
                                onClick={() => {
                                    navigate("/")
                                }}
                            >
                                <Text
                                    size={"size7"}
                                    color={"white"}
                                >
                                    홈으로
                                </Text>
                            </Button>
                        :   <>
                                <Button
                                    padding={"large"}
                                    size={"size_full"}
                                    onClick={() => {
                                        navigate("/");
                                    }}
                                >
                                    <div className={CSS.l_login_btn_text}>
                                        뒤로가기
                                    </div>
                                </Button>

                                <Button
                                    padding={"large"}
                                    size={"size_full"}
                                    purpose={"delete"}
                                    onClick={onSubmite}
                                >
                                    <div className={CSS.l_login_btn_text}>
                                        비밀번호 초기화
                                    </div>
                                </Button>
                            </>
                    }
                </div>
            </div>
        </div>
    );
}

export default ResetUserPassword;
