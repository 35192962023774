import { useQuery } from "@tanstack/react-query";
import { callAxios, dev_console, whoAmI } from "../ts/commonTools";
import { GC_TIME, STALE_TIME } from "../ts/const";

const useUserInfoDataREST = () => {

    const isAbleToRequest = () => {
        let result: boolean = false;

        if(whoAmI.isCustomer() || whoAmI.isAdmin()){
            result = true;
        }

        return result;
    }

    const {
        data: userInfoData,
        error: userInfoDataError,
        isLoading: isUserInfoDataLoading,
        refetch: userInfoDataRefetch,
    } = useQuery({
        queryKey: ["userInfoData"],
        queryFn: getMypageData,
        select: (response) => {
            if (response.status === 200 && response.data.msg === "success") {
                return response.data.data;
            }else{
                return null;
            }
        },
        enabled: isAbleToRequest(),
        staleTime: STALE_TIME, // 5분동안은 신선함
        gcTime: GC_TIME, // 10분 캐시 유지
        refetchOnMount: false,  // 다시 마운트 되더라도 새로운 요청을 보내지 않게 처리
    })

    // 유저 개인 정보를 호출하는 함수
    async function getMypageData(){
        const mypage_url = "api/v3/customers/mypage";

        const response = await callAxios.api({
            url: mypage_url,
            method: "get",
        })

        dev_console.log(mypage_url);
        dev_console.log(response);

        return response;
    }

    return{
        userInfoData,
        userInfoDataError,
        isUserInfoDataLoading,
        userInfoDataRefetch,
    }
}

export default useUserInfoDataREST;