import CSS from "./static/css/uploadInputBtnContainer.module.css";
import { scheduleProductInterface } from "../../static/interface/ProductionList.interface";
import TextFlexWrap from "../../../../Component/Elements/TextFlexWrap/TextFlexWrap";
import Button from "../../../../Component/Elements/Button/Button";
import useProductionBtnState from "../../hooks/useProductionBtnState.hook";

const UploadInputBtnContainer = (
    {
        selectedProduct,
        reqestBtnHandler,
        viewReqestedInfoBtnHandler,
    }:{
        selectedProduct: scheduleProductInterface;
        reqestBtnHandler: React.MouseEventHandler<HTMLButtonElement>
        viewReqestedInfoBtnHandler: React.MouseEventHandler<HTMLButtonElement>
    }
) => {

    const { btnState, choicesAvailableNum } = useProductionBtnState(selectedProduct)

    switch(btnState){
        case -1:
            // 옵션 정보 호출 중 오류발생
            return(
                <TextFlexWrap
                    text_list={["제품의 선택 옵션데이터를 가져오는 과정에서 문제가 발생했습니다."]}
                    flex_direction={"column"}
                    justify_content={"center"}
                />
            );
        case 1:
            // 옵션정보 호출 중...
            return(  
                <div className={`g_display_none_object mobile flex ${CSS.l_btn_wrap}`}>
                    <Button
                        size={"size_full"} 
                        disabled={true}
                    >
                        {`잠시만 기다려주세요.`}
                    </Button>
                </div>                          
            );
        case -2:
            // 촬영들이 종료되지 않음
            return(
                <div className={`g_display_none_object mobile flex ${CSS.l_btn_wrap}`}>
                    <Button
                        size={"size_full"}
                        disabled={true}
                    >
                        {"촬영이 모두 완료되지 않았습니다."}
                    </Button>
                </div>
            );
        case 3:
            // 사진 선택 진행 가능
            return(
                <div className={`g_display_none_object mobile flex ${CSS.l_btn_wrap}`}>
                    <Button
                        size={"size_full"}
                        onClick={reqestBtnHandler} 
                    >
                        제품 제작 신청
                    </Button>
                </div>
            );
        case -3:
            // 사진의 수가 설정되지 않아서 진행 불가능
            return(
                <TextFlexWrap
                    text_list={["선택해야할 사진의 수가 설정되지 않았습니다. 지점에 문의해주세요."]}
                    flex_direction={"column"}
                    justify_content={"center"}
                />
            );
            
        case 4:
        case 5:
            // 선택이 완료됨
            return(
                <div className={`g_display_none_object mobile flex ${CSS.l_btn_wrap}`}>
                    <Button 
                        size={"size_full"}
                        onClick={viewReqestedInfoBtnHandler}
                        purpose={"dev"}
                    >
                        신청 내역 확인
                    </Button>
                </div>
            );
        default:
            return(<>{btnState}</>)
    }
}

export default UploadInputBtnContainer;