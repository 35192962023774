import { useQuery } from "@tanstack/react-query";
import { defaultApiReaponseInterface } from "../../../Common/interface/Common.interface";
import { themaBookPhotoListInterface } from "../static/interface/themeBook.interface";
import { callAxios, dev_console, isSuccess, printStateMsg } from "../../../Common/ts/commonTools";
import { useRef } from "react";
import { GC_TIME, STALE_TIME } from "../../../Common/ts/const";

const usePhotoProductDataREST = (
    {
        office_id
    }:{
        office_id: number
    }
) => {

    // "전체" 필터 처리를 위해 필요한 부분
    const all_item: themaBookPhotoListInterface = {
        office_id: office_id,
        photo_id: 0,
        photo_name: "전체",
        type: 3,
    }

    const {
        data: photoProductList, 
        error: photoProductError, 
        isLoading: isPhotoProductLoading,
        refetch: photoProductRefetch,
    } = useQuery({
        queryKey: ["theme_book_photo_product", office_id],
        queryFn: callPhotoProductList,
        enabled: !!office_id,  // selectedOfficeId이 0이 아니면 쿼리 실행
        select: (response) => {
            if(response){
                const response_data: defaultApiReaponseInterface<themaBookPhotoListInterface[]> = response.data;
                if(isSuccess(response)){
                    // type이 2인것이 테마정보다.
                    return [all_item, ...response_data.result];
                }else{
                    printStateMsg(response);
                    return [];
                }
            }else{
                return [];
            }
        },
        staleTime: STALE_TIME, // 5분동안은 신선함
        gcTime: GC_TIME, // 10분 캐시 유지
        refetchOnMount: false,  // 다시 마운트 되더라도 새로운 요청을 보내지 않게 처리
    });

    async function callPhotoProductList(){
        if(!!office_id){
            // office_id가 없는경우엔 전 지점에 대한 데이터를 가져온다.
            const thema_book_photo_list_url = `api/v3/customers/thema_book_photo_list?office_id=${office_id}`;

            const response = await callAxios.api({
                url: thema_book_photo_list_url,
                method: 'get',
            })

            dev_console.log(thema_book_photo_list_url);
            dev_console.log(response);

            return response;
        }else{
            return null;
        }
    }

    return({
        photoProductList, 
        photoProductError, 
        isPhotoProductLoading,
        photoProductRefetch,
    })
}

export default usePhotoProductDataREST