import AlbumInput from "../AlbumInput/AlbumInput";
import CSS from "./static/css/albumProductInputContainer.module.css";
import InputTitle from "../InputTitle/InputTitle";
import { albumDataInterface } from "../../static/interface/ProductionList.interface";
import Text from "../../../../Component/Elements/Text/Text";

const AlbumProductInputContainer = (
    {
        albumData,
        setAlbumData,
        disabled,
    }:{
        albumData: albumDataInterface;
        setAlbumData?: React.Dispatch<React.SetStateAction<albumDataInterface>>;
        disabled?: boolean;
    }
) => {

    /**
     * 영문이름
     * 생년월일
     * 키
     * 몸무게
     * 태어난시간
     */

    const handleBabyName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input_value = event.target.value;

        // const koreanPattern = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
        // if(koreanPattern.test(input_value)){
        //     alert("한글은 입력할 수 없습니다.");
        // }else{
        //     if(setAlbumData && !disabled){
        //         setAlbumData((data) => {
        //             return({
        //                 ...data,
        //                 baby_name: input_value,
        //             })
        //         });
        //     }
        // }

        if(setAlbumData && !disabled){
            setAlbumData((data) => {
                return({
                    ...data,
                    baby_name: input_value,
                })
            });
        }
    }

    const handleBabyBirth = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(setAlbumData && !disabled){
            setAlbumData((data) => {
                return({
                    ...data,
                    baby_birth: event.target.value,
                })
            });
        }
    }

    const handleTall = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(setAlbumData && !disabled){
            setAlbumData((data) => {
                return({
                    ...data,
                    tall: event.target.value,
                })
            });
        }
    }

    const handleWeight = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(setAlbumData && !disabled){
            setAlbumData((data) => {
                return({
                    ...data,
                    weight: event.target.value,
                })
            });
        }
    }

    const handleBabyBirthTime = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(setAlbumData && !disabled){
            setAlbumData((data) => {
                return({
                    ...data,
                    baby_birth_time: event.target.value,
                })
            });
        }
    }

    return (
        <div className={CSS.l_album_product_input_container_main}>
            <InputTitle 
                title="출생 정보 입력"
                sub_title="(표기하지 않는 상품이 일부 있습니다.)"
                tooltip_node={
                    <div className={CSS.l_tooltip_text_container}>
                        <div className={CSS.l_tooltip_text_container__text_line}>
                            <Text color={"red"}>
                                태어날때 당시의
                            </Text>
                            <Text color={"dark"}>
                                아이 정보로 입력해주세요.
                            </Text>
                        </div>

                        <br />

                        <Text color={"dark"}>
                            (입력하지 않으실경우 빈 값으로 사용됩니다.)
                        </Text>
                    </div>
                }
            />
            <div className={CSS.l_album_product_input_container__input_container}>
                <AlbumInput
                    label="아이 이름(영문)"
                    value={albumData.baby_name}
                    onChange={handleBabyName}
                    disabled={disabled}
                />
                <AlbumInput 
                    label="아이 생일"
                    value={albumData.baby_birth}
                    onChange={handleBabyBirth}
                    type={"date"}
                    disabled={disabled}
                />
                <AlbumInput 
                    label="키(cm)"
                    value={albumData.tall}
                    onChange={handleTall}
                    type={"number"}
                    disabled={disabled}
                    min={0}
                />
                <AlbumInput 
                    label="몸무게(kg)"
                    value={albumData.weight}
                    onChange={handleWeight}
                    type={"number"}
                    disabled={disabled}
                    min={0}
                />
                <AlbumInput 
                    label="출생시간"
                    value={albumData.baby_birth_time}
                    onChange={handleBabyBirthTime}
                    type={"time"}
                    disabled={disabled}
                />
            </div>
        </div>
    )
}

export default AlbumProductInputContainer;