import { useQuery } from "@tanstack/react-query";
import { setThemaBookPhotoListQueryParameter } from "../static/ts/themeBook";
import { callAxios, dev_console, isSuccess, printStateMsg } from "../../../Common/ts/commonTools";
import { defaultApiReaponseInterface } from "../../../Common/interface/Common.interface";
import { themaBookDetailListInterface, themaBookPhotoListInterface } from "../static/interface/themeBook.interface";
import { GC_TIME, STALE_TIME } from "../../../Common/ts/const";

const useThemeDataREST = (
    {
        photo_product,
        office_id,
    }:{
        photo_product: themaBookPhotoListInterface | null;
        office_id: number;
    }
) => {
    const {
        data: themeList,
        error: themeListError,
        isLoading: isThemeListLoading,
        refetch: themeListRefetch,
    } = useQuery({
        queryKey: ["theme_book_office", photo_product, office_id],
        queryFn: callThemeList,
        enabled: !!photo_product,  // selectedPhotoProduct이 null이 아니면 쿼리 실행        
        select: (response) => {
            if(response){
                const response_data: defaultApiReaponseInterface<themaBookDetailListInterface[]> = response.data;
                if(isSuccess(response)){
                    return response_data.result;
                }else{
                    printStateMsg(response);
                    return [];
                }
            }else{
                return [];
            }
        },
        staleTime: STALE_TIME, // 5분동안은 신선함
        gcTime: GC_TIME, // 10분 캐시 유지
        refetchOnMount: false,  // 다시 마운트 되더라도 새로운 요청을 보내지 않게 처리
    });

    // 테마 목록을 서버에 요청하는 함수
    async function callThemeList(){        
        if(photo_product){
            const thema_book_detail_list_endpoint:string = "api/v3/customers/thema_book_detail_list";
            const query_parameter_string = setThemaBookPhotoListQueryParameter({selected_photo_product: photo_product, office_id});
            const thema_book_detail_list_url = thema_book_detail_list_endpoint.concat("?", query_parameter_string);
    
            const response = await callAxios.api({
                url: thema_book_detail_list_url,
                method: "get",
            })

            dev_console.log(thema_book_detail_list_url);
            dev_console.log(response);

            return response;
        }else{
            return null;
        }
    }

    return({
        themeList,
        themeListError,
        isThemeListLoading,
        themeListRefetch,
    })
}

export default useThemeDataREST;