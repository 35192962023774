import usePhotoListBadge from "../../../CustomerPage/PhotoList/hooks/usePhotoListBadge.hook";

const usePhotoListNaviBadge = () => {
    const {
        mainTypeBadgeList
    } = usePhotoListBadge(1)

    return({
        isPhotoListNaviBadge: mainTypeBadgeList.some((item) => item)
    })
}

export default usePhotoListNaviBadge;