import { useEffect, useState } from "react";
import usePhotoListDataREST from "../../../../../CustomerPage/PhotoList/hooks/usePhotoListDataREST.hook";
import { photoReadDataInterface } from "../../../../../CustomerPage/PhotoList/static/interface/PhotoList.interface";
import { isCanConfrom } from "../../../../../CustomerPage/PhotoList/static/ts/confirmEvent.tools";
import CSS from "./static/css/FinalApproval.module.css";
import Text from "../../../../../Component/Elements/Text/Text";
import { useNavigate } from "react-router-dom";
import CardContainer from "../../../../../Component/Elements/CardContainer/CardContainer";
import ApprovalItemCard from "./components/ApprovalItemCard";

const FinalApproval = () => {
    
    const [printDataList, setPrintDataList] = useState<photoReadDataInterface[]>([]);

    const {
        photoListData: contractEditingPhotoListData
    } = usePhotoListDataREST({
        main_type: 1,
        status: 2
    })

    const navigate = useNavigate();

    useEffect(() => {
        if(contractEditingPhotoListData){
            const result = contractEditingPhotoListData.filter((item) => {
                return(
                    isCanConfrom({
                        customer_confirmed: item.customer_confirmed,
                        manager_confirmed: item.manager_confirmed,
                    }).flag
                )
            })

            setPrintDataList(result);
        }else{
            setPrintDataList([]);
        }
    }, [contractEditingPhotoListData])

    const viewAllHandler = () => {
        navigate("/photoList", {
            state: {
            main_type: 1,
            status: 2
            }
        })
    }

    if(printDataList.length > 0){
        return(
            <div className={CSS.l_final_approval_main}>
                <div className={CSS.l_final_approval__title_continer}>
                    <Text
                        size={"size7"}
                        bold={true}
                    >
                        사진을 확인해주세요!
                    </Text>
                    <Text
                        size={"size6"}
                        onClick={viewAllHandler}
                    >
                        {"view all >"}
                    </Text>
                </div>
                <CardContainer
                    class_name_list={[CSS.l_card_container]}
                    level={"level1"}
                >
                    <div className={CSS.l_final_approval__card_container__item_list}>
                        {
                            printDataList.map((item, index) => {
                                return(
                                    <ApprovalItemCard 
                                        key={index}
                                        photoData={item}
                                    />
                                )
                            })
                        }
                    </div>
                </CardContainer>
            </div>
        )
    }else{
        return(<></>)
    }
}

export default FinalApproval;