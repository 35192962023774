import { useEffect, useState } from "react";
import CSS from "./static/Text.module.css";

const Text = (
    {
        children,
        size,
        style,
        color,
        weight,
        class_name_list,
        onClick,
        bold,
        is_container_highlight,
        text_wrap,
        is_title,
    }:{
        children?: React.ReactNode;
        size?: "size1" | "size2" | "size3" | "size4" | "size5" | "size6" | "size7";
        style?: "italic";
        color?: "dark" | "green" | "white" | "red" | "mint" | "light_gray";
        weight?: "weight100" | "weight200" | "weight300" | "weight400" | "weight500" | "weight600" | "weight700" | "weight800" | "weight900";
        class_name_list?: string[];
        onClick?: React.MouseEventHandler<HTMLDivElement>;
        bold?: boolean;
        is_container_highlight?: boolean;
        text_wrap?: "nowrap" | "wrap" | "balance" | "pretty";
        is_title?: boolean;
    }
) => {
    const [totalClassName, setTotalClassName] = useState<string>("");

    useEffect(() => {
        const total_class_name_list: string[] = [];

        if(onClick){
            total_class_name_list.push("g_click_event_item");
        }

        if(class_name_list){
            for(const class_name of class_name_list){
                total_class_name_list.push(class_name);
            }
        }

        total_class_name_list.push(CSS.l_text);

        if(weight){
            total_class_name_list.push(CSS.weight);
        }

        if(size){
            total_class_name_list.push(CSS[size]);
        }

        if(color){
            total_class_name_list.push(CSS.color);
            total_class_name_list.push(CSS[color]);
        }

        if(bold){
            total_class_name_list.push(CSS.bold);
        }

        if(style){
            total_class_name_list.push(CSS.style);
        }

        if(is_container_highlight){
            total_class_name_list.push(CSS.is_container_highlight);
        }

        if(text_wrap){
            total_class_name_list.push(CSS.text_wrap);
            total_class_name_list.push(CSS[text_wrap]);
        }

        if(is_title){
            total_class_name_list.push(CSS.is_title);
        }

        setTotalClassName(total_class_name_list.join(" "));
    }, [weight, size, color, style, class_name_list, onClick, is_container_highlight, text_wrap, is_title])

    return(
        <div 
            className={totalClassName}
            onClick={onClick}
        >
            {children}
        </div>
    )
}

export default Text;