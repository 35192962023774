import { useMutation } from "@tanstack/react-query";
import { contractScheduleReservationConfigInterface } from "../static/interface/selectTheme.interface";
import { callAxios, dev_console, printStateMsg } from "../../../Common/ts/commonTools";
import useProgressScheduleDataAllScheduleType from "./useProgressScheduleDataAllScheduleType";

const contract_schedule_reservation_url =  "api/v3/customers/contract_schedule_reservation";

const useSelectedThemeSubmitREST = () => {

    const {
        progressScheduleDataRefetch: progressScheduleAllDataRefetch
    } = useProgressScheduleDataAllScheduleType({
        status_type: 0
    })

    const {
        progressScheduleDataRefetch: progressScheduleBeforeDataRefetch
    } = useProgressScheduleDataAllScheduleType({
        status_type: 1
    })

    const {
        mutate: selectedThemeSubmitMutate
    } = useMutation({
        mutationFn: selectedThemeUpdate,
        onSuccess: (data, variables, context) => {
            if(data.status === 200){
                progressScheduleAllDataRefetch();
                progressScheduleBeforeDataRefetch();
            }else{
                printStateMsg(data);
            }
        },
        onSettled: (data, error, variables, context) => {
            dev_console.log(contract_schedule_reservation_url);

            if(variables){
                dev_console.log(variables);
            }
            
            if(data){
                dev_console.log(data);
            }

            if(error){
                dev_console.error(error);
            }
        },
    })
    
    async function selectedThemeUpdate(
        {
            config
        }:{
            config: contractScheduleReservationConfigInterface
        }
    ){
        const response = await callAxios.api({
            url: contract_schedule_reservation_url,
            method: "post",
            dataType: "json",
            data: config,
        })

        return response
    }

    return({ selectedThemeSubmitMutate })
}

export default useSelectedThemeSubmitREST;