/**
 * 단순 사진 출력용 컴포넌트
 * 추가적인 기능 없이 viewerjs를 통해 사진 목록을 출력하고 모달을 통해 보여주는것에 집중
 */

import { useRef } from 'react';
// viewer.js의 기본 스타일
import 'viewerjs/dist/viewer.css';

import CSS from "./static/css/ImageGallery.module.css";
import { viewerImageInterface } from './interface/ImageGallery.interface';
import useImageGallery from './hooks/useImageGallery.hook';
import ViewerToolBar from './components/ViewerToolBar/ViewerToolBar';
import Media from '../Media/Media';

const ImageGallery = (
    {
        img_src_list,
    }:{
        img_src_list: viewerImageInterface[];
    }
) => {
    const galleryRef = useRef<HTMLDivElement | null>(null);

    const {
        isViewerVisible,
        viewerInstanceRef
    } = useImageGallery({
        galleryRef,
        dependency_key: img_src_list,
    })

    return(
        <div 
            className={CSS.l_image_gallery_main}
            ref={galleryRef}
        >
            {
                img_src_list.map((item, index) =>{
                    return(
                        <div
                            key={index}
                            className={CSS.l_image_gallery__img_cover}
                        >
                            <Media 
                                key={index}
                                src={item.thumbnail_url}
                                object_fit={"contain"}
                                class_name_list={[CSS.l_image_gallery__img_cover__img]}
                                customdata={item.origin_url}
                            />
                        </div>
                    )
                })
            }
            {/* 사용자 정의 툴바 */}
            {
                isViewerVisible
                &&  <ViewerToolBar viewerInstanceRef={viewerInstanceRef} />
            }
        </div>
    )
}

export default ImageGallery;