import { useEffect, useState } from "react";
import { scheduleProductInterface } from "../static/interface/ProductionList.interface";
import usePhotoProductOptionDataListREST from "./usePhotoProductOptionInfoDataListREST.hook";
import useProductionInfo from "./useProductionInfo.hook";

const useProductionBtnState = (initial_production: scheduleProductInterface) => {
    const [btnState, setBtnState] = useState<number>(0);

    // 선택한 제품의 옵션정보
    const {
        photoProductOptionDataListError,
        photoProductOptionDataListIsLoading,
    } = usePhotoProductOptionDataListREST(initial_production);

    const { choicesAvailableNum } = useProductionInfo(initial_production);

    useEffect(() => {
        let node_state_code = 0;

        if(photoProductOptionDataListError){
            // 옵션정보 호출중에 에러발생
            node_state_code = -1;
        }else if(photoProductOptionDataListIsLoading){
            // 옵션정보 호출 중
            node_state_code = 1;
        }else if(initial_production.photo_select_pre_yn === 1){
            if(initial_production.final_select === "N"){
                if(choicesAvailableNum.min > 0 && choicesAvailableNum.max > 0){
                    // 최초 선택 가능상태
                    node_state_code = 3;
                }else{
                    // 최초 선택이 가능하지만, 선택해야할 사진 수가 사용할 수 있는 상태로 설정되지 않음
                    node_state_code = -3;
                }

            }else{
                if(initial_production.staff_download_at.length === 0){
                    // 요청이 완료됨(재요청 가능)
                    node_state_code = 5;
                }else{
                    // 요청이 완료됨(재요청 불가능)
                    node_state_code = 4;
                }
            }
        }else{
            // 촬영이 완료되지 않음(요청 진행 불가능)
            node_state_code = -2;
        }

        setBtnState(node_state_code);
    }, [initial_production, photoProductOptionDataListIsLoading, photoProductOptionDataListError, choicesAvailableNum])

    return({
        btnState,
        choicesAvailableNum
    })
}

export default useProductionBtnState;