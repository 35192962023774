import { forwardRef } from "react";
import MediaCard from "../../../../Component/Elements/MediaCard/MediaCard";
import Text from "../../../../Component/Elements/Text/Text";
import CSS from "./static/css/ImgBlock_vr2.module.css"
import { fileType } from "../../../../Common/ts/const";

interface imgBlockInterface{
    index?: number;
    folder_id: number;
    img_src: string;
    file_name: string;
    removeImgFc?: (index: number, folder_id: number) => void;
    imgClickHandler?: (src: string) => void;
    file_type?: fileType;
    video_controls?: boolean;
}

const ImgBlock_vr2 = forwardRef<HTMLImageElement | HTMLDivElement | HTMLVideoElement, imgBlockInterface>((
    {
        index,
        folder_id,
        img_src,
        file_name,
        removeImgFc,
        imgClickHandler,
        file_type,
        video_controls,
    }: imgBlockInterface, ref
) => {
    const removeBtnHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();

        if(removeImgFc && index !== undefined){
            removeImgFc(index, folder_id);
        }
    }

    return(
        <div 
            className={CSS.l_img_block_main}
            onClick={!!imgClickHandler ? imgClickHandler.bind(null, img_src) : undefined}
        >
            {
                !!removeImgFc
                &&  <div 
                        className={`g_click_event_item ${CSS.l_remove_img_btn}`}
                        onClick={removeBtnHandler}
                    >
                        X
                    </div>
            }
            <MediaCard
                src={img_src}
                object_fit={"contain"}
                size={"full"}
                children_container_class_name_list={[CSS.l_media_card_text_container]}
                ref={ref}
                file_type={file_type}
                video_controls={video_controls}
            >
                <Text
                    text_wrap={"pretty"}
                    class_name_list={[CSS.l_media_card_text_container__text]}
                >
                    {file_name}
                </Text>
            </MediaCard>
        </div>
    )
});

export default ImgBlock_vr2;