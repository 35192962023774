import { useEffect, useState } from "react";
import usePhotoProductDataREST from "./usePhotoProductDataREST.hook";
import { isNeedToSeletItem } from "../static/ts/productionListTools";

const usePhotoProductBadge = () => {
    const [photoProductBadgeList, setPhotoProductBadgeList] = useState<boolean[]>([false, false, false]);

    const {
        photoProductDataList: photoProductDataListAbleRequest,
    } = usePhotoProductDataREST({
        search_type: 1,
    })

    useEffect(() => {
        setPhotoProductBadgeList((item) => {
            item[1] = photoProductDataListAbleRequest.some((item) => isNeedToSeletItem(item))
            return item
        })
    }, [photoProductDataListAbleRequest])

    return({photoProductBadgeList})
}

export default usePhotoProductBadge;