import _ from "lodash";
import SwipeLine from "../SwipeLine/SwipeLine";
import SwipeTextItem from "../SwipeLine/components/SwipeTextItem/SwipeTextItem";
import CSS from "./static/css/titleBtnWrap.module.css";
import { useEffect } from "react";

const TitleBtnWrap = <T, >(
    {
        valueState,
        setValueState,
        value_list,
        innerText_list,
        is_activated,
        is_print_badge_list,
        is_loading,
        additional_function,
        category_tag,
    }:{
        valueState: T;
        setValueState: React.Dispatch<React.SetStateAction<T>>;
        value_list: T[];
        innerText_list: string[];
        is_activated: boolean;
        is_print_badge_list: boolean[];
        is_loading?: boolean;
        additional_function?: Function;
        category_tag: string;
    }
) => {
    const session_name: string = `TitleBtnWrap_${category_tag}_state`;

    const textBtnOnClickHandler = (index: number) => {
        if(is_activated && value_list.length > index && !is_loading){
            setValueState(value_list[index]);
            if(additional_function){
                additional_function(value_list[index]);
            }

            // 이전에 선택했던 필터를 유지하기 위해 필터값이 변경되면 해당값을 state에 저장한다.
            if(category_tag){
                sessionStorage.setItem(session_name, JSON.stringify(value_list[index]));
            }
        }
    }

    useEffect(() => {
        const filter_state = sessionStorage.getItem(session_name);

        if(!!filter_state){
            const filter_state_data = JSON.parse(filter_state) as T;
            setValueState(filter_state_data);

            if(additional_function){
                additional_function(filter_state_data);
            }
        }
    }, [])

    return (
        <div className={CSS.l_title_btn_wrap_main}>
            <SwipeLine>
                {
                    innerText_list.map((item, index) => {
                        return (
                            <SwipeTextItem
                                key={index}
                                text={item}
                                onClick={textBtnOnClickHandler.bind(null, index)}
                                is_highlight={_.isEqual(value_list[index], valueState)}
                                is_print_badge={is_print_badge_list[index]}
                                disabled={is_loading}
                            />
                        )
                    })
                }
            </SwipeLine>
        </div>
    )
}

export default TitleBtnWrap;