import { useEffect, useState } from "react";
import useProgressScheduleDataAllScheduleType from "./useProgressScheduleDataAllScheduleType";
import { isNeedToThemeSelect } from "../static/ts/reservation.tool";

const useProgressScheduleFilterBadge = () => {
    const [badgeList, setBadgeList] = useState<boolean[]>([]);
    
    const {
        progressScheduleDataList,
    } = useProgressScheduleDataAllScheduleType({
        status_type: 1
    })

    useEffect(() => {
        setBadgeList([
            false,
            progressScheduleDataList.some((item) => isNeedToThemeSelect(item)),
            false
        ])
    }, [progressScheduleDataList])

    return({
        badgeList
    })
}

export default useProgressScheduleFilterBadge;