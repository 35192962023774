import CardInfoTextWrap from "./CardInfoTextWrap";
import MediaCard from "../../../Component/Elements/MediaCard/MediaCard";
import CSS from "../static/css/themeCardBlock.module.css";
import { imageUrlInterface } from "../../../Common/interface/Common.interface";
import { fileType } from "../../../Common/ts/const";

const ThemeCardBlock = (
    {
        img_src,
        theme_name,
        title,
        office_name,
        onClick,
        is_highlight,
        is_empty_content,
        size,
    }:{
        img_src: imageUrlInterface | null;
        theme_name: string;
        title: string;
        office_name: string;
        onClick?: React.MouseEventHandler<HTMLDivElement>;
        is_highlight?: boolean;
        is_empty_content?: boolean;
        size?: "small" | "middle" | "big" | "full" | "auto";
    }
) => {
    return (
        <MediaCard 
            src={img_src?.middle_url}
            object_fit={"contain"}
            size={size}
            onClick={onClick}
            is_highlight={is_highlight}
            is_hover_action_activate={true}
            file_type={fileType.img}
        >
            <CardInfoTextWrap
                text_list={[theme_name]}
                type={"normal"}
            />
            <CardInfoTextWrap
                text_list={[title]}
                type={"title"}
            />
            <CardInfoTextWrap
                text_list={[office_name]}
                type={"normal"}
            />
            {
                is_empty_content
                ?   <div className={CSS.l_empty_content_layer}>
                        <div className={CSS.l_empty_content_layer__text}>
                            상세 페이지 내용 없음
                        </div>
                        <div className={CSS.l_empty_content_layer__text}>
                            상세 페이지 작성을 위해 클릭
                        </div>
                    </div>
                :   null
            }
        </MediaCard>
    )
}

export default ThemeCardBlock;