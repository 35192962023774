import { useEffect, useRef, useState } from "react";
import Button from "../../../../Component/Elements/Button/Button";
import CardContainer from "../../../../Component/Elements/CardContainer/CardContainer";
import CSS from "./static/css/ThumbnailImageSelect.module.css";
import ImageViewer from "../../../../Component/Elements/ImageViewer/ImageViewer";

const ThumbnailImageSelect = (
    {
        origin_src,
        input_ref,
        uploadedThumbnailSrc,
        setUploadedThumbnailSrc,
        uploadedThumbnailFileRef,
        readonly,
    }:{
        origin_src: string,
        input_ref?: React.MutableRefObject<HTMLInputElement | null>;
        uploadedThumbnailSrc: string;
        setUploadedThumbnailSrc?: React.Dispatch<React.SetStateAction<string>>;
        uploadedThumbnailFileRef?: React.MutableRefObject<File | null>;
        readonly?: boolean;
    }
) => {
    const [viewingImg, setViewingImg] = useState<string>("");

    // const [isViewUploadIcon, setIsViewUploadIcon] = useState<boolean>(false);

    const thumbnail_image_ref = useRef<HTMLDivElement | null>(null);

    const thumbnailChangeHandler = () => {
        if(input_ref){
            input_ref.current?.click();
        }
    }

    // const thumbnailOnMouseEnterHandler = () => {
    //     setIsViewUploadIcon(true);
    // }

    // const thumbnailOnMouseLeaveHandler = () => {
    //     setIsViewUploadIcon(false)
    // }

    const onCancelHandler = () => {
        if(!window.confirm("썸네일 이미지 변경사항을 취소하시겠습니까?")){

        }

        if(setUploadedThumbnailSrc){
            setUploadedThumbnailSrc("");
        }

        if(uploadedThumbnailFileRef){
            uploadedThumbnailFileRef.current = null;
        }
    }

    useEffect(() => {
        if(!!uploadedThumbnailSrc){
            setViewingImg(uploadedThumbnailSrc)
        }else{
            setViewingImg(origin_src)
        }
    }, [uploadedThumbnailSrc, origin_src])

    return(
        <CardContainer class_name_list={[CSS.l_thumbnail_image_select_main]}>
            <div 
                className={`${CSS.l_thumbnail_image_select__image_container}`}
                ref={thumbnail_image_ref}
            >
                <ImageViewer 
                    img_src={viewingImg}
                    thumbnail_src={viewingImg}
                    is_view_toolbar={false}
                />
            </div>

            {
                !readonly
                &&  <div className={CSS.l_thumbnail_image_select__btn_container}>
                        <Button
                            size={"size_full"}
                            onClick={thumbnailChangeHandler}
                            purpose={"dev"}
                        >
                            썸네일 변경
                        </Button>
                        {
                            !!uploadedThumbnailFileRef && !!uploadedThumbnailFileRef.current
                            &&  <Button
                                    size={"size_full"}
                                    purpose={"cancel"}
                                    onClick={onCancelHandler}
                                >
                                    취소
                                </Button>
                        }
                    </div>
            }
        </CardContainer>
    )
}

export default ThumbnailImageSelect;