import { forwardRef, useEffect, useState } from "react";
import Img from "./components/Img/Img";
import { getFileType } from "../../../Common/ts/commonTools";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import { fileType } from "../../../Common/ts/const";

interface mediaInterface{
    origin_src?: string;
    src?: string;
    id?: string;
    className?: string;
    alt?: string;
    onClick?: React.MouseEventHandler<HTMLImageElement> | React.MouseEventHandler<HTMLDivElement>;
    onLoad?: React.ReactEventHandler<HTMLImageElement>; 
    loading?: "eager" | "lazy";
    title?: string,
    object_fit?: "cover" | "fill" | "contain" | "scale-down" | "none";
    height_standard?: boolean;
    file_type?: fileType;
    class_name_list?: string[];
    video_controls?: boolean;
    customdata?: string;
}

const Media = forwardRef<HTMLImageElement | HTMLDivElement | HTMLVideoElement, mediaInterface> ((
    {
        origin_src, // file_type의 자동설정이 필요한 경우 경로를통해 자료형을 가져온다.
        src,
        id,
        alt,
        onClick,
        onLoad,
        loading,
        title,
        object_fit,
        height_standard,
        file_type, // file_type을 직접 설정하고싶은경우 사용
        class_name_list,
        video_controls,
        customdata,
    }, ref
) => {
    let final_file_type: fileType = 0;

    if(file_type){
        final_file_type = file_type;
    }else{
        // 자료형이 정해지지 않은경우, 주소를 통해 자료형 추출을 시도한다.
        final_file_type = getFileType(src || "");

        if(final_file_type === fileType.none && origin_src){
            final_file_type = getFileType(origin_src || "");
        }        
    }

    if(final_file_type === fileType.img){
        return(
            <Img
                src={src}
                id={id}
                alt={alt}
                onClick={onClick as React.MouseEventHandler<HTMLImageElement>}
                onLoad={onLoad}
                loading={loading}
                title={title}
                object_fit={object_fit}
                height_standard={height_standard}
                class_name_list={class_name_list}
                customdata={customdata}
                ref={ref as React.Ref<HTMLImageElement>}
            />
        )
    }else if(final_file_type === fileType.video){
        return(
            <VideoPlayer
                ref={ref}
                src={src}
                video_controls={video_controls}
                class_name_list={class_name_list}
                onClick={onClick as React.MouseEventHandler<HTMLDivElement>}
            />
        )
    }else if(final_file_type === fileType.zip){
        return(
            <div 
                ref={ref as React.Ref<HTMLDivElement>}
                id={id}
                onClick={onClick as React.MouseEventHandler<HTMLDivElement>}
                className={class_name_list?.join(" ")}
            >
                ZIP
            </div>
        )
    }else{
        return(
            <Img
                src={src}
                id={id}
                alt={alt}
                onClick={onClick as React.MouseEventHandler<HTMLImageElement>}
                onLoad={onLoad}
                loading={loading}
                title={title}
                object_fit={object_fit}
                height_standard={height_standard}
                class_name_list={class_name_list}
                customdata={customdata}
                ref={ref as React.Ref<HTMLImageElement>}
            />
        )
    }
})

export default Media;