import { useEffect, useState } from "react";
import CSS from "./static/css/Checkbox.module.css";

const Checkbox = (
    {
        isChecked,
        onClickHander,
        disabled,
    }:{
        isChecked: boolean;
        onClickHander?: React.MouseEventHandler<HTMLDivElement>;
        disabled?: boolean;
    }
) => {
    const [totalClassName, setTotalClassName] = useState<string>("");

    useEffect(() => {
        const total_class_name_list: string[] = ["g_click_event_item"];

        if(disabled){
            total_class_name_list.push("not_allowed");
        }

        total_class_name_list.push(CSS.l_checkbox_main);
        setTotalClassName(total_class_name_list.join(" "));
    }, [disabled, onClickHander])

    const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if(disabled){
            return ;
        }

        if(onClickHander){
            onClickHander(event);
        }
    }

    return(
        <div
            className={totalClassName}
            onClick={onClick}
        >
            {
                isChecked
                &&  <div className={CSS.l_checkbox__mark}>
                    </div>
            }
        </div>
    )
}

export default Checkbox