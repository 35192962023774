import CSS from "./static/css/ReloadBtn.module.css";
import reset_btn_icon from "./static/img/reset_btn.png";

const ReloadBtn = () => {
    const reloadHandler = () => {
        window.location.reload();
    }

    return(
        <div 
            className={`g_click_event_item ${CSS.l_reload_btn_main}`}
            onClick={reloadHandler}
        >
            <img 
                className={CSS.l_reload_btn__icon}
                src={reset_btn_icon}
            />
        </div>
    )
}

export default ReloadBtn;