import { formatDate, formatTime } from "../../../../../../Common/ts/commonTools";
import Text from "../../../../../../Component/Elements/Text/Text";
import { progressScheduleInterface } from "../../../../../../CustomerPage/Reservation/static/interface/reservationHome.interface";
import { useNavigate } from "react-router-dom";
import CSS from "../../../static/css/scheduleCard.module.css";
import ViewDetailBtn from "./ViewDetailBtn";

const ScheduleCard = (
    {
        schedule,
    }:{
        schedule: progressScheduleInterface
    }
) => {
    const schedule_date = new Date(schedule.schedule_date);
    const schedule_time_string = formatDate(schedule_date, "yyyy년 MM월 dd일");

    const navigate = useNavigate();

    const viewDetailEventHandler = () => {
        navigate("/reservationHome", {state: schedule})
    }

    return(
        <div 
            className={CSS.l_schedule_card_main}
            onClick={viewDetailEventHandler}
        >
            <div className={CSS.l_schedule_card__info_container}>
                <Text
                    size={"size3"}
                    bold={true}
                    class_name_list={[CSS.l_schedule_card__info_container__title]}
                >
                    {schedule.photo_name}
                </Text>
                {
                    schedule.detail
                    &&  <Text
                            size={"size1"}
                            class_name_list={[CSS.l_schedule_card__info_container__office]}
                        >
                            {schedule.detail?.office_name}
                        </Text>
                }
            </div>
            <Text
                size={"size2"}
                color={"dark"}
                class_name_list={[CSS.l_schedule_card__time_container]}
            >
                {schedule_time_string}
            </Text>
            <Text
                size={"size2"}
                color={"dark"}
                class_name_list={[CSS.l_schedule_card__time_container]}
            >
                {formatTime(schedule.detail?.photo_start || "")} ~ {formatTime(schedule.detail?.photo_end || "")}
            </Text>
            <ViewDetailBtn 
                onClick={viewDetailEventHandler}
            />
        </div>
    )
}

export default ScheduleCard;