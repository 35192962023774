import React, { createContext, useEffect, useRef, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { logout } from './Common/ts/appTools';
import { callAxios, dev_console, getCloseInAppBrowserUrl, getMyCookieData, getRedirectUrl, isInAppBrowser, isSuccess, openInExternalBrowser, printStateMsg, whatIsMode } from './Common/ts/commonTools';
// import * as Sentry from "@sentry/react";

// 공용 페이지
import Login from './PublicPage/Login/Login';
import Header from './PublicPage/Header/Header';

// 고객 페이지
import MyInfo from './CustomerPage/Mypage/MyInfo';
import ReservationHome from './CustomerPage/Reservation/ReservationHome';

import ResetUserPassword from './PublicPage/ResetUserPassword/ResetUserPassword';

import SelectTheme from './CustomerPage/Reservation/SelectTheme';
import PhotoList from './CustomerPage/PhotoList/PhotoList';
import PhotoDetail from './CustomerPage/PhotoList/PhotoDetail';
import ProductionList from './CustomerPage/ProductionList/ProductionList';
import ProductionRequest from './CustomerPage/ProductionList/ProductionRequest';
import ThemeBookHome from './PublicPage/ThemeBook/ThemeBookHome';

import ThemeBookManagement from './AdminPage/ThemeBookManagement/ThemeBookManagement';

import { userInfoInterface, windowSizeInterface } from './Common/interface/App.interface';

import CSS from "./Common/css/common.module.css";
import { defaultApiReaponseInterface, tokenCheckInterface } from './Common/interface/Common.interface';
import DeleteURL from './PublicPage/DeleteURL/DeleteURL';
import TestPage from './TestPage/TestPage';
import CustomerPageHome from './PublicPage/Home/CustomerPage/CustomerPageHome';
import useUserInfoDataREST from './Common/hooks/useUserInfoDataREST.hook';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import Toast from './Component/Elements/Toast/Toast';
import CustomerNavi from './PublicPage/Navi/CustomerNavi';
import AdminNavi from './PublicPage/Navi/AdminNavi';
import ContractDocs from './CustomerPage/ContractDocs/ContractDocs';
import ContractDetailViewerMobile from './CustomerPage/ContractDocs/ContractDetailViewerMobile';
import RedirectToAnotherBrowser from './PublicPage/RedirectToAnotherBrowser/RedirectToAnotherBrowser';
import ProductionDetail from './CustomerPage/ProductionList/ProductionDetail';

export interface commonContextInterface{
    userInfoData?: userInfoInterface | null;
    userInfoDataRefetch?: (options?: RefetchOptions) => Promise<QueryObserverResult<any, Error>>;

    windowSize: windowSizeInterface;
    history: string[];
    historyManager: ({path, action}:{path?: string, action: "add" | "sub" | "clear" | "get_last" | "pop"}) => void | string;
}

const commonContextDafuiltValue:commonContextInterface = {
    windowSize: {
        width: 0, 
        height: 0,
    },
    history: [],
    historyManager: () => {},
}

// 고객 계정에서 사용하는 context
export const commonContext = createContext<commonContextInterface>(commonContextDafuiltValue);

function App() {

    // Sentry.init({
    //     dsn: "http://7a7a5b86a4a24b1548ee7ccc299c247d@124.111.208.229:9009/3",
    //     integrations: [
    //       Sentry.browserTracingIntegration(),
    //     //   Sentry.replayIntegration(),
    //     ],
    //     // Performance Monitoring
    //     tracesSampleRate: 1.0, //  Capture 100% of the transactions
    //     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //     tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    //     // Session Replay
    //     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    //     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // });

    // 접속한 환경이 카카오톡, 라인 인앱브라우저인 경우 해당 페이지로 리다이렉트 시킨다.
    openInExternalBrowser.auto_open();

    // 네비게이션바 컨트롤
    const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);

    // 고객 정보
    const {
        userInfoData,
        userInfoDataRefetch,
    } = useUserInfoDataREST();

    //  윈도우 창 크기 변화 감지
    const [windowSize, setWindowSize] = useState<{width: number, height:number}>({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    // 뒤로가기 버튼의 정보를 저장하는 state
    const [history, setHistory] = useState<string[]>([]);

    // 실제로 컨텐츠들이 출력되는 영역의 ref
    const containerRef = useRef<HTMLDivElement>(null);

    const location = useLocation();

    // 헤더와 네비게이션 바를 제외시킬 경로들
    const popupPaths = [
        '/myContract/print/',
    ];
    // 현재 경로가 excludedPaths 배열에 포함되는지 확인
    const isPopupUrl = popupPaths.some(path => location.pathname.startsWith(path));

    // 로그인한 계정의 타입 (C : 고객, E : OOPS관리자.)
    const user_type = getMyCookieData.user_type();
    // 로그인한 계정의 고유 토큰값
    const token = getMyCookieData.token();

    const historyManager = (
        {
            path,
            action,
        }:{
            path?: string, 
            action: "add" | "sub" | "clear" | "get_last" | "pop"
        }
    ) => {
        if(action === "add"){
            setHistory(prevHistory => [...prevHistory, location.pathname]);
        }else if(action === "sub"){
            setHistory(history => history.filter((filter_item) => filter_item !== path));
        }else if(action === "clear"){
            setHistory([]);
        }else if(action === "get_last"){
            return history.pop();
        }else if(action === "pop"){
            if(history.length > 0){
                const last = history.pop();
                setHistory((history) => {
                    if (history.length > 0) {
                        return history.slice(0, history.length - 1);
                    }
                    return history;
                })
                return last;
            }
        }
    };

    useEffect(() => {
        if (token) {
            // 토큰값 검증
            const web_token_api = 'api/v3/accounts/web-token';
            callAxios.api({
                url: web_token_api,
                method: 'post',
            })
            .then((response) => {
                dev_console.log(web_token_api);
                dev_console.log(response);

                const data: defaultApiReaponseInterface<tokenCheckInterface> = response.data;
                if (isSuccess(response)) {
                    if (data.result.msg === "valid") {
                        if(data.result.user_type === user_type){
                            dev_console.log('유효한 토큰');
                        }else{
                            printStateMsg(response);
                            alert("잘못된 로그인 입니다.");
                            logout();
                        }
                    }else{
                        dev_console.error('만료된 토큰');
                        alert("로그인 정보가 만료되었습니다");
                        logout();
                    }
                }else{
                    printStateMsg(response);
                    alert("토큰 검증에 실패했습니다.");
                    logout();
                }
            })
            .catch((error) => {
                dev_console.error(error);
                alert('토큰 검증과정에서 문제가 발생했습니다.');
                logout();
            })
        }

        // service worker
        if ('serviceWorker' in navigator) {
            const registInit = async () => {
                const registration = await navigator.serviceWorker.register('/service-worker.js');

                registration.waiting?.postMessage('SKIP_WATING');
            };
            registInit();
        }


        // 창 크기 변경 이벤트 핸들러
        const handleAppResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
    
        // 이벤트 리스너 추가
        window.addEventListener('resize', handleAppResize);

        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
            window.removeEventListener('resize', handleAppResize);
        };

    }, []);
    
    if(isInAppBrowser() === "KakaoTalk" || isInAppBrowser() === "LINE"){
        return(
            <div className={CSS.l_body}>
                <RedirectToAnotherBrowser />
            </div>
        )
    }

    // 로그인한 사람이 고객
    if (token && user_type === 'C') {
        return (
            isPopupUrl
            ?   <Routes>
                </Routes>
            :   <commonContext.Provider value={{
                    userInfoData,
                    userInfoDataRefetch,
                    windowSize,
                    history, 
                    historyManager
                }}>
                    <div className={CSS.l_body}>
                        <Toast 
                            position={"top-center"}
                        />

                        <Header
                            setIsOpenMenu={setIsOpenMenu}
                        />

                        <div 
                            className={CSS.l_container}
                            ref={containerRef}
                        >
                            {/* 네비게이션 바 */}
                            <CustomerNavi 
                                isOpenMenu={isOpenMenu}
                                setIsOpenMenu={setIsOpenMenu}
                            />

                            <main className={CSS.l_main_contents}>
                                {/* 라우팅 */}
                                <Routes>
                                    <Route path='/' element={<CustomerPageHome />} />
                                    
                                    <Route path='/myInfo' element={<MyInfo />} />

                                    <Route path='/contractDocs' element={<ContractDocs />} />
                                    <Route path="/contractDetailViewerMobile" element={<ContractDetailViewerMobile />} />

                                    <Route path='/reservationHome' element={<ReservationHome />} />
                                    <Route path="/selectTheme" element={<SelectTheme />} />

                                    <Route path="/photoList" element={<PhotoList />} />
                                    <Route path="/photoDetail" element={<PhotoDetail />} />

                                    <Route path="/productionList" element={<ProductionList />} />
                                    <Route path="/productionDetail" element={<ProductionDetail />} />
                                    <Route path="/productionRequest" element={<ProductionRequest />} />

                                    <Route path="/themeBook" element={<ThemeBookHome />} />

                                    {
                                        whatIsMode().is_not_product_mode_safe
                                        &&  <Route path="/test_page" element={<TestPage />} />
                                    }

                                    <Route path='*' element={<Navigate replace to='/' />} />
                                </Routes>
                            </main>
                        </div>
                    </div>
                </commonContext.Provider>
        );
    }

    if(token && user_type === 'E'){
        return (
            <commonContext.Provider 
                value={{
                    userInfoData,
                    windowSize,
                    history, 
                    historyManager
                }}
            >
                <div className={CSS.l_body}>
                    <Toast 
                        position={"top-center"}
                    />
                    <Header
                        setIsOpenMenu={setIsOpenMenu}
                    />

                    <div 
                        className={CSS.l_container}
                        ref={containerRef}
                    >
                        <AdminNavi 
                            isOpenMenu={isOpenMenu}
                            setIsOpenMenu={setIsOpenMenu}
                        />

                        <main className={CSS.l_main_contents}>
                            <Routes>
                                <Route path='/' element={<ThemeBookHome />} />
                                <Route path="/themeBook" element={<ThemeBookHome />} />
                                <Route path="/themeBookManagement" element={<ThemeBookManagement />} />
                                {
                                    whatIsMode().is_not_product_mode_safe
                                    &&  <Route path="/test_page" element={<TestPage />} />
                                }
                                <Route path='*' element={<Navigate replace to='/' />} />
                            </Routes>
                        </main>
                    </div>
                </div>
            </commonContext.Provider>
        )
    }

    // 로그인 페이지
    return (
        <div className={CSS.l_body}>
            <Toast 
                position={"top-left"}
            />
            <Routes>
                <Route path='/' element={<Login />} />
                <Route path="/resetUserPassword" element={<ResetUserPassword />} />
                <Route path="/deleteM" element={<DeleteURL />} />
                {
                    whatIsMode().is_not_product_mode_safe
                    &&  <Route path="/test_page" element={<TestPage />} />
                }
                <Route path='*' element={<Navigate replace to='/' />} />
            </Routes>
        </div>
    );
}

export default App;
