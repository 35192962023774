import Text from "../../Text/Text";
import CSS from "../static/css/calendarTitle.module.css";

const CalendarTitle = (
    {
        title,
    }:{
        title: string;
    }
) => {
    return(
        <Text
            size={"size6"}
            bold={true}
            class_name_list={[CSS.l_calendar_title_main]}
        >
            {title}
        </Text>
    )
}

export default CalendarTitle;