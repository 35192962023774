import { useEffect, useState } from "react";
import useProgressScheduleDataREST from "./useProgressScheduleDataREST.hook";
import { progressScheduleInterface } from "../static/interface/reservationHome.interface";
import { progressScheduleSort } from "../../../Common/ts/hooks.tools";

const useProgressScheduleDataAllScheduleType = (
    {
        status_type
    }:{
        status_type: 0 | 1 | 2;  // 0: "전체" | 1: "미완료" | 2: "완료"
    }
) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [progressScheduleDataList, setProgressScheduleDataList] = useState<progressScheduleInterface[]>([]);

    const {
        progressScheduleDataList: contractProgressScheduleDataList,
        progressScheduleDataListIsLoading: contractProgressScheduleDataListIsLoading,
        progressScheduleDataListRefetch: contractProgressScheduleDataListRefetch,
    } = useProgressScheduleDataREST({
        main_type: 0,
        status_type
    })

    const {
        progressScheduleDataList: singleShotProgressScheduleDataList,
        progressScheduleDataListIsLoading: singleShotProgressScheduleDataListIsLoading,
        progressScheduleDataListRefetch: singleShotProgressScheduleDataListRefetch,
    } = useProgressScheduleDataREST({
        main_type: 1,
        status_type
    })

    const progressScheduleDataRefetch = () => {
        contractProgressScheduleDataListRefetch();
        singleShotProgressScheduleDataListRefetch();
    }

    useEffect(() => {
        let before_progress_schedule_data: progressScheduleInterface[] = [];

        let contract_progress_schedule_data: progressScheduleInterface[] = [];
        if(!!contractProgressScheduleDataList){
            contract_progress_schedule_data = [...contractProgressScheduleDataList];
        }

        let single_shot_progress_schedule_data: progressScheduleInterface[] = [];
        if(!!singleShotProgressScheduleDataList){
            single_shot_progress_schedule_data = [...singleShotProgressScheduleDataList];
        }

        before_progress_schedule_data = [...contract_progress_schedule_data, ...single_shot_progress_schedule_data];
        before_progress_schedule_data.sort(progressScheduleSort);

        setProgressScheduleDataList(before_progress_schedule_data);
    }, [contractProgressScheduleDataList, singleShotProgressScheduleDataList])

    useEffect(() => {
        setIsLoading(!!contractProgressScheduleDataListIsLoading || !!singleShotProgressScheduleDataListIsLoading);
    }, [contractProgressScheduleDataListIsLoading, singleShotProgressScheduleDataListIsLoading])

    return ({
        progressScheduleDataList,
        isLoading,
        progressScheduleDataRefetch,
    })
}

export default useProgressScheduleDataAllScheduleType;