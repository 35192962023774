import { useEffect, useState } from "react";
import usePhotoListDataREST from "./usePhotoListDataREST.hook";
import { isCanConfrom } from "../static/ts/confirmEvent.tools";

const usePhotoListBadge = (
    main_type: 1 | 2, // 1: "나의 사진" | 2: "후기 사진" 
) => {
    
    const [mainTypeBadgeList, setMainTypeBadgeList] = useState<boolean[]>([false, false]);
    const [statusBadgeList, setStatusBadgeList] = useState<boolean[]>([false, false, false]);
    
    const {
        photoListData: contractOriginPhotoListData
    } = usePhotoListDataREST({
        main_type: 1,
        status: 1
    })

    const {
        photoListData: contractEditingPhotoListData
    } = usePhotoListDataREST({
        main_type: 1,
        status: 2
    })

    const {
        photoListData: contractEditedPhotoListData
    } = usePhotoListDataREST({
        main_type: 1,
        status: 3
    })

    const {
        photoListData: eventOriginPhotoListData
    } = usePhotoListDataREST({
        main_type: 2,
        status: 1
    })

    useEffect(() => {
        const contract_status_badge: boolean[] = [false, false, false];
        let single_shot_status_badge: boolean = false;

        // 원본사진 badge처리
        if(contractOriginPhotoListData.length > 0){
            contract_status_badge[0] = contractOriginPhotoListData.some((item) => !(!!item.download_at));
        }

        // 편집 진행중인 사진 badge처리
        if(contractEditingPhotoListData.length > 0){
            const reuslt_sign = contractEditingPhotoListData.some((item) => {
                return(
                    isCanConfrom({
                        customer_confirmed: item.customer_confirmed,
                        manager_confirmed: item.manager_confirmed,
                    }).flag
                );
            })

            contract_status_badge[1] = reuslt_sign;
        }

        // 편집 완료된 사진 badge처리
        if(contractEditedPhotoListData.length > 0){
            contract_status_badge[2] = contractEditedPhotoListData.some((item) => !(!!item.download_at));
        }

        // 이벤트 사진 badge처리
        if(eventOriginPhotoListData.length > 0){
            single_shot_status_badge = eventOriginPhotoListData.some((item) => !(!!item.download_at));
        }

        setMainTypeBadgeList([
            contract_status_badge.some((item) => item),
            single_shot_status_badge,
        ])

        if(main_type === 1){
            setStatusBadgeList(contract_status_badge);
        }else{
            setStatusBadgeList([]);
        }

    }, [contractOriginPhotoListData, contractEditingPhotoListData, contractEditedPhotoListData, eventOriginPhotoListData, main_type])

    return({
        mainTypeBadgeList,
        statusBadgeList,
    })
}

export default usePhotoListBadge;