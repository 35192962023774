import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TitleBtnWrap from "../../Component/Elements/TitleBtnWrap/TitleBtnWrap";
import PhotoListBlock from "./components/PhotoListBlock/PhotoListBlock";
import { isAbleAccesser } from "../../Common/ts/commonTools";
import WideCard from "../../Component/Elements/WideCard/WideCard";
import style from "./static/css/photoList.module.css";
import Grid from "../../Component/Elements/Grid/Grid";
import LoopAnimation from "../../Component/Elements/LoopAnimation/LoopAnimation";
import { isCanConfrom } from "./static/ts/confirmEvent.tools";
import { flagInterface } from "./static/interface/confirmEvent.Interface";
import usePhotoListDataREST from "./hooks/usePhotoListDataREST.hook";
import usePhotoListBadge from "./hooks/usePhotoListBadge.hook";
import CardContainer from "../../Component/Elements/CardContainer/CardContainer";
import Text from "../../Component/Elements/Text/Text";
import TitleContainerG from "../../Component/Elements/TitleContainerG/TitleContainerG";
import ReloadBtn from "../../Component/Elements/ReloadBtn/ReloadBtn";

const PhotoList = () => {

    const [mode, setMode] = useState<number>(1);
    const [viewRange, setViewRange] = useState<number>(1);

    const [photoListNode, setPhotoListNode] = useState<React.ReactNode | null>(null);

    const {
        photoListData,
        photoListDataError,
        photoListDataIsLoading,
        photoListDataRefetch,
    } = usePhotoListDataREST({
        main_type: mode as 1 | 2,
        status: viewRange as 1 | 2 | 3
    })

    const {
        mainTypeBadgeList,
        statusBadgeList,
    } = usePhotoListBadge(mode as 1 | 2);

    const navigate = useNavigate();

    const location = useLocation();
    const location_state_data = location.state as {
        main_type: number;
        status: number;
    };

    useEffect(() => {
        isAbleAccesser(["C"]);

        if(!!location_state_data){
            setMode(location_state_data.main_type)
            setViewRange(location_state_data.status)
        }
    }, [])

    useEffect(() => {
        let status = 0;
        if(photoListDataIsLoading){
            status = 1;
        }else if(!!photoListDataError){
            status = -1;
        }else{
            if(photoListData.length > 0){
                status = 2;
            }else{
                status = -2;
            }
        } 

        switch(status){
            case 1:
                setPhotoListNode(
                    <WideCard>
                        <LoopAnimation />
                    </WideCard>
                );
                break;
            case -1:
                setPhotoListNode(
                    <WideCard>
                        문제가 발생했습니다.
                    </WideCard>
                );
                break;
            case 2:
                setPhotoListNode(
                    <Grid>
                        {
                            photoListData.map((item, index) => {
                                let is_print_badge: boolean = false;
                                if(mode === 1 && viewRange === 2){
                                    is_print_badge = isCanConfrom({
                                        customer_confirmed: item.customer_confirmed,
                                        manager_confirmed: item.manager_confirmed,
                                    }).flag;
                                }else if(!item.download_at){
                                    is_print_badge = true;
                                }

                                let is_print_join_event_btn = false;
                                if(mode === 1 && viewRange === 1){
                                    is_print_join_event_btn = true;
                                }

                                let is_print_confirm: flagInterface | null = null;
                                if(mode === 1 && viewRange ===2){
                                    is_print_confirm = isCanConfrom({
                                        customer_confirmed: item.customer_confirmed,
                                        manager_confirmed: item.manager_confirmed,
                                    })
                                }

                                return (
                                    <PhotoListBlock 
                                        key={index}
                                        img_src={item.thumbnail?.small_url || ""}
                                        title={item.photo_shoot_name}
                                        date={item.schedule_date}
                                        onClick={() => {
                                            navigate(
                                                "/photoDetail"
                                                ,{
                                                    state:{
                                                        selected_photo_upload_id: `${item.photo_upload_id}`,
                                                        main_type: `${mode}`,
                                                        viewRange_type: `${viewRange}`,
                                                        photo_type: item.photo_type,
                                                    }
                                                }
                                            );
                                        }}
                                        is_print_badge={is_print_badge}
                                        schedule_id={item.schedule_id}
                                        upload_date={item.upload_at}
                                        joined_event={item.joined_event}
                                        is_print_join_event_btn={is_print_join_event_btn}
                                        is_print_confirm={is_print_confirm}
                                        photo_upload_id={item.photo_upload_id}
                                    />
                                )
                            })
                        }
                    </Grid>
                );
                break;
            case -2:
                setPhotoListNode(
                    <WideCard>
                        사진 정보가 없습니다
                    </WideCard>
                );
                break;
            default:
                setPhotoListNode(null);
                break;
        }
    }, [photoListDataIsLoading, photoListDataError, photoListData])

    return (
        <div className={`${style.l_photo_list_main}`}>
            <TitleContainerG>
                <Text is_title={true}>
                    내 사진
                </Text>
                <ReloadBtn />
            </TitleContainerG>

            <TitleBtnWrap<number>
                valueState={mode}
                setValueState={setMode}
                value_list={[1, 2]}
                innerText_list={["나의 사진", "후기 사진"]}
                is_activated={true}
                is_print_badge_list={mainTypeBadgeList}
                category_tag="PhotoList_mode"
            />

            <CardContainer
                class_name_list={[style.l_photo_list_view]}
            >
                {
                    mode === 1
                    &&  <TitleBtnWrap<number>
                            valueState={viewRange}
                            innerText_list={["원본", "편집 진행중", "편집 완료"]}
                            setValueState={setViewRange}
                            value_list={[1, 2, 3]}
                            is_activated={true}
                            is_print_badge_list={statusBadgeList}
                            category_tag="PhotoList_viewRange"
                        />
                }
                <CardContainer
                    class_name_list={[style.l_photo_list_container]}
                    padding={"none"}
                >
                    {photoListNode}
                </CardContainer>
            </CardContainer>
        </div>
    )
}

export default PhotoList;