import { useEffect, useState } from "react";
import Badge from "../../../Badge/Badge";
import CSS from "./static/css/swipeTextItem.module.css"

const SwipeTextItem = (
    {
        text,
        onClick,
        is_highlight,
        is_print_badge,
        disabled,
    }:{
        text: string;
        onClick?: React.MouseEventHandler<HTMLDivElement>;
        is_highlight?: boolean;
        is_print_badge: boolean;
        disabled?: boolean;
    }
) => {
    const [totalClassName, setTotalClassName] = useState<string>("");

    useEffect(() => {
        const class_name_list: string[] = [];

        class_name_list.push("l_swipe_item")
    
        if(onClick){
            class_name_list.push("g_click_event_item");

            //  마우스 호버 애니메이션
            //  class_name_list.push("g_hover_animation");
        }

        class_name_list.push(CSS.l_swipe_text_item_main);
        
        if(is_highlight){
            class_name_list.push(CSS.l_item_highlight);
        }

        if(disabled){
            class_name_list.push(CSS.l_item_disabled);
        }

        setTotalClassName(class_name_list.join(" "));
    }, [onClick, disabled, is_highlight])

    return (
        <div
            className={totalClassName}
            onClick={onClick}
        >
            <Badge is_print_badge={is_print_badge}>
                {text}
            </Badge>
        </div>
    )
}

export default SwipeTextItem;