import { useEffect, useState } from "react";
import CSS from "./static/css/TextFlexWrap.module.css";
import { insertBetweenList } from "../../../Common/ts/commonTools";
import Text from "../Text/Text";

const TextFlexWrap = (
    {
        title,
        text_list,
        onClick,
        is_highlight,
        classNameList,
        separator,
        flex_direction,
        justify_content,
        text_list_justify_content,
    }:{
        title?: string;
        text_list: string[];
        onClick?: React.MouseEventHandler<HTMLDivElement>;
        is_highlight?: boolean;
        classNameList?: string[];
        separator?: string;
        flex_direction?: "column" | "row";
        justify_content?: "flex_start" | "flex_end" | "center" | "space_around" | "space_between" | "space_evenly";
        text_list_justify_content?: "flex_start" | "flex_end" | "center" | "space_around" | "space_between" | "space_evenly";
    }
) => {
    const [totalClassName, setTotalClassName] = useState<string>("");
    const [totalTextList, setTotalTextList] = useState<string[]>([]);

    useEffect(() => {
        const class_name_list: string[] = [];

        if(onClick){
            class_name_list.push("g_click_event_item");
        }

        if(classNameList){
            for(const class_name of classNameList){
                class_name_list.push(class_name);
            }
        }

        if(flex_direction){
            class_name_list.push(CSS.l_flex_direction);
            class_name_list.push(CSS[flex_direction]);
        }

        if(justify_content){
            class_name_list.push(CSS.justify_content);
            class_name_list.push(CSS[justify_content]);
        }

        if(is_highlight){
            class_name_list.push("highlight");
        }

        class_name_list.push(CSS.l_text_flex_wrap_main);

        setTotalClassName(class_name_list.join(" "));
    }, [onClick, is_highlight, classNameList, flex_direction, justify_content])

    useEffect(() => {
        if(separator){
            setTotalTextList(insertBetweenList(text_list, separator));
        }else{
            setTotalTextList(text_list);
        }
    }, [separator, text_list])

    return (
        <div
            className={totalClassName}
            onClick={onClick}
        >
            {
                title   
                &&  <Text
                        class_name_list={[CSS.l_text_title]}
                        bold={true}
                    >
                        {title}
                    </Text>
            }
            <div 
                className={
                    text_list_justify_content
                    ?   `${CSS.l_text_item_list} ${CSS[text_list_justify_content]}`
                    :   CSS.l_text_item_list}
            >
                {
                    totalTextList.map((text, index) => {
                        if(text){
                            return (
                                <Text
                                    class_name_list={[CSS.l_text_item]}
                                    key={index}
                                >
                                    {text}
                                </Text>
                            )
                        }else{
                            return null;
                        }
                    })
                }
            </div>
        </div>
    )
}

export default TextFlexWrap;