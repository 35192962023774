import { downloadFile } from "../../../../../../../Common/ts/commonTools";

// API와 연결할 툴바 함수들
export const handleZoomIn = (viewerInstanceRef: React.MutableRefObject<Viewer | null>) => {
    viewerInstanceRef.current?.zoom(0.1); // Viewer.js의 zoom 메서드로 확대
};

export const handleZoomOut = (viewerInstanceRef: React.MutableRefObject<Viewer | null>) => {
    viewerInstanceRef.current?.zoom(-0.1); // Viewer.js의 zoom 메서드로 축소
};

export const handleNextImage = (viewerInstanceRef: React.MutableRefObject<Viewer | null>) => {
    viewerInstanceRef.current?.next(true); // 다음 이미지로 이동
};

export const handlePrevImage = (viewerInstanceRef: React.MutableRefObject<Viewer | null>) => {
    viewerInstanceRef.current?.prev(true); // 이전 이미지로 이동
};

export const handleResetImage = (viewerInstanceRef: React.MutableRefObject<Viewer | null>) => {
    viewerInstanceRef.current?.reset(); // 이미지 초기화
};

export const handleDownloadImage = (viewerInstanceRef: React.MutableRefObject<Viewer | null>) => {
    //  new Viewer 객체의 interface가 설계되다 말았는지... 로그에 찍히는 접근 가능한 객체들과 차이가 있기에 어쩔수 없이 any로 처리한다.
    const view: any = viewerInstanceRef.current;
    const src: string = view.image.src || "";
    const alt: string = view.image.alt || "image.jpg";
    if(!!src){
        downloadFile(src, alt);
    }
}