import CSS from "./static/css/productList.module.css";
import ProductCard from "../ProductCard/ProductCard";
import { useNavigate } from "react-router-dom";
import Text from "../../../../../Component/Elements/Text/Text";
import CardContainer from "../../../../../Component/Elements/CardContainer/CardContainer";
import usePhotoProductDataREST from "../../../../../CustomerPage/ProductionList/hooks/usePhotoProductDataREST.hook";
import WideCard from "../../../../../Component/Elements/WideCard/WideCard";
import LoopAnimation from "../../../../../Component/Elements/LoopAnimation/LoopAnimation";

const ProductList = () => {

    const {
        photoProductDataList,
        photoProductDataListIsLoading,
    } = usePhotoProductDataREST({
        search_type: 1,
    })

    const navigate = useNavigate();

    const viewAllHandler = () => {
        navigate("/productionList")
    }

    return(
        <div className={`${CSS.l_product_list_main}`}>
            <div className={CSS.l_product_list__title_continer}>
                <Text is_title={true}>
                    제품 제작 신청
                </Text>
                <Text
                    size={"size6"}
                    onClick={viewAllHandler}
                >
                    {"view all >"}
                </Text>
            </div>
            <CardContainer
                class_name_list={[CSS.l_card_container]}
                level={"level1"}
            >
                {
                    photoProductDataListIsLoading
                    ?   <WideCard>
                            <LoopAnimation />
                        </WideCard>
                    :   <div
                            className={CSS.l_product_list__product_list}
                        >
                            {
                                photoProductDataList.length > 0
                                ?   photoProductDataList.map((item, index) => {
                                        return(
                                            <ProductCard 
                                                key={index}
                                                schedule_product={item}
                                            />
                                        )
                                    })
                                :   <Text
                                        size={"size4"}
                                        class_name_list={[CSS.l_alert_card_text_container]}
                                    >
                                        제작 신청을 진행해야할 제품이 없습니다.
                                    </Text>
                            }
                        </div>
                }
            </CardContainer>
        </div>
    )
}

export default ProductList;