import DaumPostcodeEmbed, { Address } from 'react-daum-postcode';
import { useState } from 'react';
import Modal from '../Modal/Modal';
import CSS from "./static/css/postcode.module.css";
import Input from '../Input/Input';
import { addressDataInterface } from './interface/useDaumPostcode.interface';
import Button from '../Button/Button';

const Postcode = (
    {
        customAddressData,
        setRowAddressData,
        detailAddress,
        setDetailAddress,
        disabled,
        children,
    }:{
        customAddressData: addressDataInterface
        setRowAddressData?: React.Dispatch<React.SetStateAction<Address | null>>;
        detailAddress: string;
        setDetailAddress?: React.Dispatch<React.SetStateAction<string>>;
        disabled?: boolean;
        children?: React.ReactNode;
    }
) => {

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const handleOpenModal = () => {
        if(disabled){
            return ;
        }

        setIsModalOpen(true);
    }

    const handleCloseModal = () => {
        if(disabled){
            return ;
        }

        setIsModalOpen(false);
    }

    const handleComplete = (data: Address) => {
        if(setRowAddressData){
            setRowAddressData(data);
        }
        setIsModalOpen(false);
    }

    const handleDetailAddressOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(setDetailAddress){
            setDetailAddress(event.target.value);
        }
    }

    return (
        <div
            className={CSS.l_postcode_main}
        >
            <div
                className={`${!disabled ? "g_click_event_item" : ""} ${CSS.l_postcode__input_container}`}
                onClick={handleOpenModal}
            >
                <div className={CSS.l_postcode__zonecode_container}>
                    <div>우편번호</div>
                    <Input
                        size={"size1_harf"}
                        value={customAddressData.zonecode}
                        readOnly={true}
                        disabled={disabled}
                    />
                    {
                        !disabled
                        &&  <Button
                                size={"auto"}
                                onClick={handleOpenModal}
                            >
                                주소 검색
                            </Button>
                    }

                    {/* 커스텀 기능 버튼 */}
                    {children}
                </div>
                <Input 
                    size={"size_full"}
                    value={customAddressData.mainAddress}
                    readOnly={true}
                    disabled={disabled}
                />
            </div>
            <Input 
                size={"size_full"}
                value={detailAddress}
                onChange={handleDetailAddressOnChange}
                disabled={disabled}
            />

            <Modal
                isModalOpen={isModalOpen}
            >
                <div className={CSS.l_postcode__modal_container}>
                    <div 
                        className={`g_icon g_click_event_item g_no_print g_modal__modal_close_btn`}
                        onClick={handleCloseModal}
                    >
                        X
                    </div>
                    <DaumPostcodeEmbed 
                        onComplete={handleComplete}
                    />
                </div>
            </Modal>
        </div>
    )
}

export default Postcode;