import { useEffect, useState } from "react";
import useProgressScheduleDataREST from "../../../CustomerPage/Reservation/hooks/useProgressScheduleDataREST.hook";
import { progressScheduleInterface } from "../../../CustomerPage/Reservation/static/interface/reservationHome.interface";
import { progressScheduleSort } from "../../../Common/ts/hooks.tools";
import { isNeedToThemeSelect } from "../../../CustomerPage/Reservation/static/ts/reservation.tool";

const useProgressScheduleNaviBadge = () => {
    const [beforeProgressScheduleDataList, setBeforeProgressScheduleDataList] = useState<progressScheduleInterface[]>([]);
    const [isProgressScheduleBadge, setIsProgressScheduleBadge] = useState<boolean>(false);

    const {
        progressScheduleDataList: contractProgressScheduleDataList
    } = useProgressScheduleDataREST({
        main_type: 0,
        status_type: 1,
    })

    const {
        progressScheduleDataList: singleShotProgressScheduleDataList
    } = useProgressScheduleDataREST({
        main_type: 1,
        status_type: 1,
    })

    useEffect(() => {
        let before_progress_schedule_data: progressScheduleInterface[] = [];

        let contract_progress_schedule_data: progressScheduleInterface[] = [];
        if(!!contractProgressScheduleDataList){
            contract_progress_schedule_data = [...contractProgressScheduleDataList];
        }

        let single_shot_progress_schedule_data: progressScheduleInterface[] = [];
        if(!!singleShotProgressScheduleDataList){
            single_shot_progress_schedule_data = [...singleShotProgressScheduleDataList];
        }

        before_progress_schedule_data = [...contract_progress_schedule_data, ...single_shot_progress_schedule_data];
        before_progress_schedule_data.sort(progressScheduleSort);

        setBeforeProgressScheduleDataList(before_progress_schedule_data);

        const contract_sign: boolean = contract_progress_schedule_data.some((item) => isNeedToThemeSelect(item));
        const single_shot_sign: boolean = single_shot_progress_schedule_data.some((item) => isNeedToThemeSelect(item));

        setIsProgressScheduleBadge(contract_sign || single_shot_sign);
    }, [contractProgressScheduleDataList, singleShotProgressScheduleDataList])
    
    return {
        beforeProgressScheduleDataList,
        isProgressScheduleBadge
    }
}

export default useProgressScheduleNaviBadge;