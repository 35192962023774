import { useEffect, useState } from "react";
import ThemeCardBlock from "./components/ThemeCardBlock";
import WideCard from "../../Component/Elements/WideCard/WideCard";
import TitleBtnWrap from "../../Component/Elements/TitleBtnWrap/TitleBtnWrap";
import { themaBookPhotoListInterface, themaBookDetailListInterface } from "./static/interface/themeBook.interface";
import { useLocation, useNavigate } from "react-router-dom";
import RadioBtnWrap from "../../Component/Elements/RadioBtnWrap/RadioBtnWrap";
import CSS from "./static/css/themeBook.module.css";
import LoopAnimation from "../../Component/Elements/LoopAnimation/LoopAnimation";
import ThemaDetailPageModal from "./components/ThemaDetailPageModal";
import ThemeDetailModalBtnContainer from "./components/ThemeDetailModalBtnContainer";
import CardContainer from "../../Component/Elements/CardContainer/CardContainer";
import { whoAmI } from "../../Common/ts/commonTools";
import Grid from "../../Component/Elements/Grid/Grid";
import ReloadBtn from "../../Component/Elements/ReloadBtn/ReloadBtn";
import TitleContainerG from "../../Component/Elements/TitleContainerG/TitleContainerG";
import Text from "../../Component/Elements/Text/Text";

const ThemeBook = (
    {
        photoProductList,
        selectedPhotoProduct,
        setSelectedPhotoProduct,
        themeList,
        isLoading,
        children,
    }:{
        photoProductList: themaBookPhotoListInterface[];
        selectedPhotoProduct: themaBookPhotoListInterface | null;
        setSelectedPhotoProduct: React.Dispatch<React.SetStateAction<themaBookPhotoListInterface | null>>
        themeList: themaBookDetailListInterface[];
        isLoading: boolean;
        children?: React.ReactNode;
    }
) => {

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [modelTheme, setModelTheme] = useState<themaBookDetailListInterface | null>(null);
    const [viewMode, setViewMode] = useState<number>(0);
    const [filtedSubThemeList, setFiltedSubThemeList] = useState<themaBookDetailListInterface[]>([]);
    
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if(!!location.state && !!location.state.theme_id && themeList.length > 0){
            const theme_id = location.state.theme_id;

            if(!isNaN(theme_id)){
                const theme = themeList.find((item) => item.thema_id === theme_id);
    
                // 테마를 찾고 해당 테마에 컨텐츠가 있을경우에만 모달을 자동활성화 시킴(ThemeBookManagement에서 '취소' 버튼으로 다시 돌아왔을때, 출력할수 있는 컨텐츠가 없음에도 모달이 활성화 되는것을 막기위함)
                if(!!theme && !!theme.content){
                    setModelTheme(theme);
                    setIsModalOpen(true);
                }
            }

            // 전달받은 location.state값을 제거하여 새로고침이 일어났을 때 동작이 반복적으로 실행되는것을 막는다.
            navigate(".", { replace: true });
        }
    }, [themeList, location])

    useEffect(() => {
        if(whoAmI.isAdmin()){
            if(viewMode === 0){
                setFiltedSubThemeList(themeList);
            }else if(viewMode === 1){
                setFiltedSubThemeList(themeList.filter((item) => item.content));
            }else if(viewMode === 2){
                setFiltedSubThemeList(themeList.filter((item) => !item.content));
            }
        }else if(whoAmI.isCustomer()){
            setFiltedSubThemeList(themeList.filter((item) => item.content));
        }
    }, [themeList, viewMode])

    // 관리자 계정일 때 신규 글 작성버튼 온클릭 이벤트
    const handleOnNewContent = (selected_sub_theme: themaBookDetailListInterface) => {
        navigate("/ThemeBookManagement", {
            state: selected_sub_theme
        });
    };

    const handleOnGoToDetailPage = (selected_sub_theme: themaBookDetailListInterface) => {
        setModelTheme(selected_sub_theme);
        setIsModalOpen(true);
    }

    const modalCloseHandler = () => {
        setIsModalOpen(false);
    }

    return (
        <div className={CSS.l_theme_book_main}>
            <TitleContainerG>
                <Text is_title={true}>
                    테마북
                </Text>
                <ReloadBtn />
            </TitleContainerG>
            
            {children}
            
            <div className={CSS.l_theme_book__contents}>
                <CardContainer class_name_list={[CSS.l_theme_selecet_contents]}>
                    {
                        photoProductList.length > 0
                        ?   <TitleBtnWrap<themaBookPhotoListInterface | null>
                                valueState={selectedPhotoProduct}
                                setValueState={setSelectedPhotoProduct}
                                value_list={photoProductList}
                                innerText_list={photoProductList.map((item) => item.photo_name)}
                                is_activated={true}
                                is_print_badge_list={[]}
                                is_loading={isLoading}
                                category_tag="ThemeBook_selectedPhotoProduct"
                            />
                        :   null
                    }
                    
                    {
                        whoAmI.isAdmin()
                        &&  <RadioBtnWrap 
                                item_list={[
                                    {
                                        label: "전체",
                                        value: 0,
                                    },
                                    {
                                        label: "내용있음",
                                        value: 1,
                                    },
                                    {
                                        label: "미작성",
                                        value: 2,
                                    },
                                ]}
                                name={"view_mode"}
                                onChange={(e) => {setViewMode(parseInt(e.target.value))}}
                                value={viewMode}
                            />
                    }

                    <CardContainer
                        padding={"none"}
                    >
                        {
                            isLoading
                            ?   <WideCard>
                                    <LoopAnimation />
                                </WideCard>
                            :   filtedSubThemeList.length > 0
                                ?   <Grid>
                                        {
                                            filtedSubThemeList.map((item, index) => {
                                                if(item.content && item.content.length > 0){
                                                    return (
                                                        <ThemeCardBlock 
                                                            key={index}
                                                            img_src={item.thema_thumbnail}
                                                            theme_name={item.photo_name}
                                                            title={item.thema_name}
                                                            office_name={item.office_name}
                                                            onClick={handleOnGoToDetailPage.bind(null, item)}
                                                            is_empty_content={false}
                                                        />
                                                    )
                                                }else{
                                                    if(whoAmI.isAdmin()){
                                                        return (
                                                            <ThemeCardBlock 
                                                                key={index}
                                                                img_src={item.thema_thumbnail}
                                                                theme_name={item.photo_name}
                                                                title={item.thema_name}
                                                                office_name={item.office_name}
                                                                onClick={handleOnNewContent.bind(null, item)}
                                                                is_empty_content={true}
                                                            />
                                                        );
                                                    }else{
                                                        return null;
                                                    }
                                                }
                                            })
                                        }
                                    </Grid>
                                :   <WideCard>
                                        테마 정보가 없습니다.
                                    </WideCard>
                        }
                    </CardContainer>
                </CardContainer>
            </div>

            {
                modelTheme
                &&  <ThemaDetailPageModal 
                        isModalOpen={isModalOpen}
                        modalCloseHandler={modalCloseHandler}
                        photo_name={modelTheme.photo_name || ""}
                        title={modelTheme.thema_name || ""}
                        office_name={modelTheme.office_name || ""}
                        content={modelTheme.content || ""}
                        office_id={modelTheme.office_id}
                        thema_id={modelTheme.thema_id}
                        type={modelTheme.type}
                        thema_thumbnail={modelTheme.thema_thumbnail}
                    >
                        {
                            whoAmI.isAdmin() && modelTheme
                            &&  <div className={CSS.l_theme_selecet_contents__btn_container}>
                                    <ThemeDetailModalBtnContainer 
                                        themeData={modelTheme}
                                        isInEditPage={false}
                                        closeModalHandler={modalCloseHandler}
                                        uploadedThumbnailFile={null}
                                    />
                                </div>
                        }
                    </ThemaDetailPageModal>
            }
        </div>
    )
}

export default ThemeBook;