import { useEffect, useState } from "react";
import LoopAnimationModal from "../../../../Component/Elements/LoopAnimationModal/LoopAnimationModal";
import CSS from "./static/css/DownloadLoadingModal.module.css";
import Text from "../../../../Component/Elements/Text/Text";

const DownloadLoadingModal = (
    {
        isModalOpen,
        fileSize,
        download_time,
    }:{
        isModalOpen: boolean;
        fileSize: string;
        download_time: number;
    }
) => {
    const [msgIndex, setMsgIndex] = useState<number>(0);

    const msg_list: string[] = [
        "파일을 다운로드하기 위해 압축하고 있습니다.", 
        "잠시만 기다려 주세요.",
        `${fileSize}의 파일이 준비되고 있습니다.`,
        `예상시간 : 약 ${download_time}분 미만`
    ]

    useEffect(() => {

        const interval_id = setInterval(()=>{
            setMsgIndex((index) => {
                if(msg_list.length > index + 1){
                    return index + 1
                }else{
                    return index - 1
                }
            });
        }, 3000);

        return(() => {
            clearInterval(interval_id);
        })
    }, [])

    return(
        <LoopAnimationModal 
            isModalOpen={isModalOpen}
        >
            <div className={CSS.l_download_loading_modal_text_container}>
                {
                    msg_list.length > 0
                    &&  <Text
                            class_name_list={[CSS.l_download_loading_modal_text_container__text]}
                            size={"size3"}
                            color={"white"}
                            
                        >
                            {msg_list[msgIndex]}
                        </Text>  
                }
            </div>
        </LoopAnimationModal>
    )
}

export default DownloadLoadingModal;