import Text from "../Text/Text";
import CSS from "./static/css/table.module.css";

const Table = (
    {
        header_item_list,
        body_item_list,
    }:{
        header_item_list: React.ReactNode[];
        body_item_list: React.ReactNode[][];
    }
) => {
    return (
        <table className={CSS.l_table}>
            {
                header_item_list.length > 0
                &&  <thead className={CSS.l_thead}>
                        <tr className={CSS.l_tr}>
                            {
                                header_item_list.map((item, index) => {
                                    return (
                                        <th 
                                            key={index}
                                            className={CSS.l_th}
                                        >
                                            <Text
                                                color={"dark"}
                                            >
                                                {item}
                                            </Text>
                                        </th>
                                    )
                                })
                            }
                        </tr>
                    </thead>
            }
            {
                body_item_list.length > 0
                &&  <tbody className={CSS.l_tbody}>
                        {
                            body_item_list.map((line, index) => {
                                return (
                                    <tr 
                                        key={index}
                                        className={CSS.l_tr}
                                    >
                                        {
                                            line.map((item) => {
                                                return (
                                                    <td className={CSS.l_td}>
                                                        <Text>
                                                            {item}
                                                        </Text>
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
            }
        </table>
    )
}

export default Table;