import { Address } from "react-daum-postcode";
import { addressDataInterface } from "../../../../Component/Elements/Postcode/interface/useDaumPostcode.interface";
import Postcode from "../../../../Component/Elements/Postcode/Postcode";
import CSS from "./static/css/addressContainer.module.css";
import InputTitle from "../InputTitle/InputTitle";

const AddressContainer = (
    {
        customAddressData,
        setRowAddressData,
        detailAddress,
        setDetailAddress,
        disabled,
        children,
    }:{
        customAddressData: addressDataInterface
        setRowAddressData?: React.Dispatch<React.SetStateAction<Address | null>>;
        detailAddress: string;
        setDetailAddress?: React.Dispatch<React.SetStateAction<string>>;
        disabled?: boolean;
        children?: React.ReactNode;
    }
) => {
    return(
        <div className={CSS.l_address_container_main}>
            <InputTitle
                title="배송지"
            />
            <div className={CSS.l_address_container__postcode_container}>
                <Postcode 
                    customAddressData={customAddressData}
                    setRowAddressData={setRowAddressData}
                    detailAddress={detailAddress}
                    setDetailAddress={setDetailAddress}
                    disabled={disabled}
                >
                    {children}
                </Postcode>
            </div>
        </div>
    )
}

export default AddressContainer;