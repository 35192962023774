import { useEffect, useState } from "react";
import usePhotoProductDataREST from "../../../CustomerPage/ProductionList/hooks/usePhotoProductDataREST.hook";
import { isNeedToSeletItem } from "../../../CustomerPage/ProductionList/static/ts/productionListTools";

const usePhotoProductRequestNaviBadge = () => {
    const [isPhotoProductNaviBadge, setIsPhotoProductNaviBadge] = useState<boolean>(false);

    const {
        photoProductDataList,
    } = usePhotoProductDataREST({
        search_type: 1
    })

    useEffect(() => {
        if(photoProductDataList.length > 0){
            setIsPhotoProductNaviBadge(photoProductDataList.some((item) => isNeedToSeletItem(item)));
        }else{
            setIsPhotoProductNaviBadge(false);
        }
    }, [photoProductDataList])

    return({
        isPhotoProductNaviBadge
    })
}

export default usePhotoProductRequestNaviBadge;