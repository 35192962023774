import { useEffect, useState } from "react";
import { albumDataInterface, choicesAvailableNumInterface, scheduleProductFolderInterface, scheduleProductInterface } from "../static/interface/ProductionList.interface";
import { getMinMaxPhoto, isAlbum } from "../static/ts/productionListTools";
import useDaumPostcode from "../../../Component/Elements/Postcode/hooks/useDaumPostcode.hook";
import usePhotoProductOptionDataListREST from "./usePhotoProductOptionInfoDataListREST.hook";
import { themaBookDetailListInterface } from "../../../PublicPage/ThemeBook/static/interface/themeBook.interface";
import { dev_console } from "../../../Common/ts/commonTools";

const useProductionInfo = (initial_production: scheduleProductInterface) => {

    // 선택할수 있는 사진의 수
    const [choicesAvailableNum, setChoicesAvailableNum] = useState<choicesAvailableNumInterface>({
        min: 0,
        max: 0,
    });

    const [isAlbumProduct, setIsAlbumProduct] = useState<boolean>(false);

    // 앨범 데이터
    const [initialAlbumData, setInitialAlbumData] = useState<albumDataInterface>({
        baby_name: "",
        baby_birth: "",
        tall: "",
        weight: "",
        baby_birth_time: "",
    })

    // 요청사항
    const [initialRequest, setInitialRequest] = useState<string>("");

    // 주소
    const { customAddressData, setCustomAddressData, detailAddress, setDetailAddress } = useDaumPostcode(null);

    // 기존에 선택한 옵션 정보
    const [initialSelectedOptionList, setinitialSelectedOptionList] = useState<themaBookDetailListInterface[]>([]);

    //  옵션 정보
    const {
        photoProductOptionDataList,
        photoProductOptionDataListError,
        photoProductOptionDataListIsLoading,
    } = usePhotoProductOptionDataListREST(initial_production);

    // 폴더 수
    const [folderList, setFolderList] = useState<scheduleProductFolderInterface[]>([]);
    
    useEffect(() => {
        if(initial_production.detail && initial_production.detail.length > 0){
            setChoicesAvailableNum(getMinMaxPhoto(initial_production.detail[0]));
        }

        setInitialAlbumData({
            baby_name: initial_production.name || "",
            baby_birth: initial_production.birth || "",
            tall: initial_production.height || "",
            weight: initial_production.weight || "",
            baby_birth_time: initial_production.birth_time || "",
        })

        setInitialRequest(initial_production.common_memo || "");

        let address_data_is_set:boolean = false;
        if(initial_production.zip_code && initial_production.addr && initial_production.building_no){
            address_data_is_set = true;
        }

        setCustomAddressData((item) => {
            return({
                ...item,
                zonecode: initial_production.zip_code || "",
                mainAddress: initial_production.addr || "",
                buildingCode: initial_production.building_no || "",
                is_set: address_data_is_set,
            })
        })

        setDetailAddress(initial_production.addr_detail || "");

        const selected_product_opiton_id_list = [initial_production.option_id];
        const result = photoProductOptionDataList.filter((item) => selected_product_opiton_id_list.includes(item.thema_id));
        
        if(!!result){
            setinitialSelectedOptionList(result);
        }else{
            setinitialSelectedOptionList([]);
        }

        setIsAlbumProduct(isAlbum(initial_production.product_type));

        setFolderList(initial_production.folder_list);

    }, [initial_production, photoProductOptionDataList])

    return({
        choicesAvailableNum,
        isAlbumProduct,
        initialAlbumData,
        initialRequest,
        initialCustomAddressData: customAddressData,
        initialDetailAddress: detailAddress,
        initialSelectedOptionList,
        photoProductOptionDataList,
        photoProductOptionDataListError,
        // initialImgList,
        folderList,
    })
}

export default useProductionInfo;