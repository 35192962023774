import { useQuery } from "@tanstack/react-query";
import { callAxios, dev_console, isSuccess, printStateMsg, setQueryParameter, whoAmI } from "../../../Common/ts/commonTools";
import { defaultApiReaponseInterface, queryParameterInterface, regionInterface, regionOfficeInterface } from "../../../Common/interface/Common.interface";
import { useRef } from "react";
import { GC_TIME, STALE_TIME } from "../../../Common/ts/const";

const useOfficeDataREST = (
    {
        selectedRegion
    }:{
        selectedRegion: regionInterface | null;
    }
) => {
    const isAbleToRequest = () => {
        let result: boolean = false;

        if(whoAmI.isCustomer() || whoAmI.isAdmin()){
            if(!!selectedRegion){
                result = true;
            }
        }

        return result;
    }

    const { 
        data: officeList,
        error: officeListError,
        isLoading: isOfficeListLoading,
        refetch: officeListRefetch
    } = useQuery({
        queryKey: ["theme_book_office", selectedRegion?.name],
        queryFn: callOfficeList,
        enabled: isAbleToRequest(),
        select: (response) => {
            if(response){
                const response_data: defaultApiReaponseInterface<regionOfficeInterface[]> = response.data;
                if(isSuccess(response)){
                    return response_data.result;
                }else{
                    printStateMsg(response);
                    return [];
                }
            }else{
                return [];
            }
        },
        staleTime: STALE_TIME, // 5분동안은 신선함
        gcTime: GC_TIME, // 10분 캐시 유지
        refetchOnMount: false,  // 다시 마운트 되더라도 새로운 요청을 보내지 않게 처리
    });

    // 고객이 지역에 있는 지점 정보들을 서버에 요청하는 함수
    async function callOfficeList(){
        if(selectedRegion){
            let region_office_url = "api/v3/customers/region_office";
            const query_list: queryParameterInterface[] = [];
            
            query_list.push({
                key: "region",
                value: selectedRegion.value,
            })

            const query_string: string = setQueryParameter(query_list);
    
            region_office_url = `${region_office_url}?${query_string}`

            const response = await callAxios.api({
                url: region_office_url,
                method: "get"
            })

            dev_console.log(region_office_url);
            dev_console.log(response);

            return response;
        }else{
            return null;
        }
    }

    return({
        officeList,
        officeListError,
        isOfficeListLoading,
        officeListRefetch
    })
}

export default useOfficeDataREST;