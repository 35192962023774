import Button from "../../../../Component/Elements/Button/Button"
import useEventApplication from "../../hooks/useEventApplication.hook";
import { joinedEventType } from "../../static/interface/PhotoList.interface";
import { btnSizeType } from "../../../../Component/Elements/Button/static/interface/Button.interface";
import { callAxios, customToast, dev_console } from "../../../../Common/ts/commonTools";
import usePhotoListDataREST from "../../hooks/usePhotoListDataREST.hook";
import usePhotoDetailDataREST from "../../hooks/usePhotoDetailDataREST.hook";
import { useState } from "react";

const EventApplication = (
    {
        schedule_id,
        upload_date,
        joined_event,
        size,
        photo_upload_id
    }:{
        schedule_id: string;
        upload_date: string;
        joined_event: joinedEventType;
        size?: btnSizeType;
        photo_upload_id: number;
    }
) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {
        photoDetailDataRefetch,
    } = usePhotoDetailDataREST({
        photo_upload_id,
        main_type: 1,
    });

    const {
        photoListDataRefetch,
    } = usePhotoListDataREST({
        main_type: 1,
        status: 1,
    })

    const {
        state,
    } = useEventApplication({        
        upload_date: upload_date,
        joined_event: joined_event,
    });

    const eventApplicationBtnHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        
        if(!window.confirm("후기 이벤트를 신청하시겠습니까?")){
            return null;
        }

        setIsLoading(true);
        requestEventApplication(schedule_id)
        .then(async (response) => {
            dev_console.log(response);
            await photoDetailDataRefetch();
            await photoListDataRefetch();
            customToast.success({msg: "후기 이벤트 신청이 완료되었습니다."});
        })
        .catch((error) => {
            dev_console.error(error);
            customToast.success({msg: "후기 이벤트 신청중에 문제가 발생했습니다."});
        })
        .finally(() => {
            setIsLoading(false);
        })
    }

    async function requestEventApplication( schedule_id: string ){
        const config = {
            schedule_id,
        }
    
        const event_check = "api/v3/customers/event-check";
        const response = await callAxios.api({
            url: event_check,
            method: "post",
            dataType: "json",
            data: config,
        })
    
        return response;
    }

    switch(state.state){
        case 1:
            return(
                <Button
                    onClick={eventApplicationBtnHandler}
                    size={size || "size_full"}
                    purpose={"save"}
                    disabled={isLoading}
                >
                    후기 이벤트 신청
                </Button>
            );
        case -1:
            return(
                <Button
                    size={size || "size_full"}
                    purpose={"disabled"}
                >
                    후기 이벤트 신청 완료
                </Button>
            );
        default:
            return(<></>);
    }
}

export default EventApplication;