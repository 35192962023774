import { useNavigate } from "react-router-dom";
import { customToast, whereAmI } from "../../../../Common/ts/commonTools";
import CardContainer from "../../../../Component/Elements/CardContainer/CardContainer";
import { contractListDataInterace } from "../../interface/ContractDocs.interface";
import DocsItem from "../DocsItem/DocsItem";
import CSS from "./static/css/DocsList.module.css";
import WideCard from "../../../../Component/Elements/WideCard/WideCard";

const DocsList = (
    {
        contractList,
        selectedContractDocs,
        setSelectedContractDocs,
    }:{
        contractList: contractListDataInterace[];
        selectedContractDocs: contractListDataInterace | null;
        setSelectedContractDocs: React.Dispatch<React.SetStateAction<contractListDataInterace | null>>;
    }
) => {

    const navigate = useNavigate();

    const docsItemOnClick = (item: contractListDataInterace) => {
        if(whereAmI.isMobile()){
            if(item.img_list.length > 0){
                navigate("/contractDetailViewerMobile", {
                    state: {
                        contractData: item
                    }
                });
            }else{
                customToast.info({msg: "업로드된 계약서가 없습니다."})
            }

        }else{
            setSelectedContractDocs(item);
        }
    };

    return(
        <CardContainer
            class_name_list={[CSS.l_docs_list_main]}
            level={"level1"}
        >
            {
                contractList.length > 0
                ?   <div className={CSS.l_docs_list__inner_container}>
                        {
                            contractList.map((item, index) => {
                                return(
                                    <DocsItem
                                        key={index}
                                        doce_date={item.contract_date}
                                        doce_package_name={item.package_name}
                                        onClick={docsItemOnClick.bind(null, item)}
                                        is_highlight={whereAmI.isWeb() && (item.contract_id === selectedContractDocs?.contract_id)}
                                    />
                                )
                            })
                        }
                    </div>
                :   <WideCard
                        background_color={"sky_blue"}
                    >
                        업로드된 계약서 정보가 없습니다.
                    </WideCard>
            }
        </CardContainer>
    )
}

export default DocsList;