import Text from "../../../../Component/Elements/Text/Text";
import Tooltip from "../../../../Component/Elements/Tooltip/Tooltip";
import CSS from "./static/css/inputTitle.module.css";

const InputTitle = (
    {
        title,
        sub_title,
        tooltip_node,
    }:{
        title: string;
        sub_title?: string;
        tooltip_node?: React.ReactNode;
    }
) => {
    return(
        <div className={CSS.l_input_title_main}>
            <Text
                size={"size6"}
                bold={true}
            >
                {title}
            </Text>
            {
                tooltip_node
                &&  <Tooltip
                        tooltip_node={tooltip_node}
                        isUseIcon={true}
                    >
                    </Tooltip>
            }
            {
                sub_title
                &&  <Text
                        size={"size1"}
                        color={"mint"}
                    >
                        {sub_title}
                    </Text>
            }
        </div>
    )
}

export default InputTitle;