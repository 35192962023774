import { useRef } from "react";
import Img from "../Media/components/Img/Img";
import CSS from "./static/css/ImageViewer.module.css";
import useImageGallery from "../ImageGallery/hooks/useImageGallery.hook";
import ViewerToolBar from "../ImageGallery/components/ViewerToolBar/ViewerToolBar";
import FunctionIcon from "../FunctionIcon/FunctionIcon";
import full_img_icon from "./static/img/full_img_icon@2x.png";

const ImageViewer = (
    {
        img_src,
        thumbnail_src,
        is_view_toolbar,
        object_fit,
        thumbnail_hidden,
    }:{
        img_src: string;
        thumbnail_src: string;
        is_view_toolbar: boolean;
        object_fit?: "cover" | "fill" | "contain" | "scale-down" | "none";
        thumbnail_hidden?: boolean;
    }
) => {
    const galleryRef = useRef<HTMLDivElement | null>(null);

    const {
        isViewerVisible,
        viewerInstanceRef
    } = useImageGallery({
        galleryRef,
        dependency_key: img_src,
    })

    return(
        <div 
            className={`g_click_event_item ${CSS.l_image_viewer_main}`}
            ref={galleryRef}
        >
            <Img
                src={thumbnail_hidden ? full_img_icon : thumbnail_src}
                object_fit={object_fit}
                class_name_list={[CSS.l_image_gallery__img_cover__img]}
                customdata={img_src}
            />

            {
                !thumbnail_hidden
                &&  <FunctionIcon
                        class_name_list={[CSS.l_image_viewer__zoom_img]}
                        src={full_img_icon}
                    />
            }

            {/* 사용자 정의 툴바 */}
            {
                (isViewerVisible && is_view_toolbar)
                &&  <ViewerToolBar viewerInstanceRef={viewerInstanceRef} />
            }
        </div>
    )
}

export default ImageViewer;