import Text from "../../../../Component/Elements/Text/Text";
import CSS from "../../static/css/textWrap.module.css";

const TextWrap = (
    {
        label,
        text,
    }:{
        label?: string;
        text: string;
    }
) => {
    return(
        <div className={CSS.l_text_wrap_main}>
            {
                label
                &&  <Text
                        size={"size1"}
                        bold={true}
                        class_name_list={[CSS.l_text_wrap__label]}
                    >
                        {label}
                    </Text>
            }
            <Text
                size={"size1"} 
                class_name_list={[CSS.l_text_wrap__text]}>
                {text}
            </Text>
        </div>
    )
}

export default TextWrap;