import { MouseEventHandler, useEffect } from "react";
import LoopAnimationModal from "../../../../Component/Elements/LoopAnimationModal/LoopAnimationModal";
import Text from "../../../../Component/Elements/Text/Text";
import CSS from "./static/css/DownloadLoadingModal.module.css";

const DownloadLoadingModalForKakao = (
    {
        isModalOpen,
        modalCloseHandler,
        fileSize,
        download_time,
        auto_close,
    }:{
        isModalOpen: boolean;
        modalCloseHandler: MouseEventHandler<HTMLDivElement>;
        fileSize: string;
        download_time: number;
        auto_close: number;
    }
) => {

    const msg_list: string[] = [
        "보고계신 화면을 벗어날 경우",
        "다운로드가 정상적으로 진행되지 못할 수 있습니다.",
        `${fileSize}의 파일이 준비되고 있습니다.`,
        `예상시간 : 약 ${download_time}분 미만`
    ]

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;

        if (isModalOpen && auto_close && !isNaN(auto_close)) {
            // auto_close 값이 설정되어 있으면 타이머 설정
            timer = setTimeout(() => {
                modalCloseHandler({} as any); // 모달 닫기 핸들러 호출
            }, auto_close * 1000); // 초 단위 -> 밀리초 변환
        }

        return () => {
            // 모달이 닫히거나 재렌더링될 때 타이머 정리
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [isModalOpen, auto_close, modalCloseHandler]);

    return(
        <LoopAnimationModal 
            isModalOpen={isModalOpen}
        >
            <div className={CSS.l_download_loading_modal_text_container}>
                {
                    msg_list.map((item, index) => {
                        return(
                            <Text
                                class_name_list={[CSS.l_download_loading_modal_text_container__text]}
                                key={index}
                                size={"size3"}
                                color={"white"}
                            >
                                {item}
                            </Text>
                        )
                    })
                }
            </div>
        </LoopAnimationModal>
    )
}

export default DownloadLoadingModalForKakao;