import { progressScheduleInterface } from "../../static/interface/reservationHome.interface";
import CSS from "../../static/css/scheduleBlock.module.css";
import { useEffect, useState } from "react";
import Button from "../../../../Component/Elements/Button/Button";
import TextWrap from "./TextWrap";
import Dday from "./Dday";
import Text from "../../../../Component/Elements/Text/Text";
import { useNavigate } from "react-router-dom";
import { setProgressScheduleBtn } from "../../static/ts/reservation.tool";
import { getTimeDifferenceInMinutes } from "../../../../Common/ts/commonTools";
import InfoDataTextWrap from "../../../../Component/Elements/InfoDataTextWrap/InfoDataTextWrap";

const ScheduleBlock = (
    {
        schedule
    }:{
        schedule: progressScheduleInterface
    }
) => {

    const [totalClassName, setTotalClassName] = useState<string>("");

    const navigate = useNavigate();

    const result = setProgressScheduleBtn({item: schedule, navigate})
    const d_day_number: number = parseInt(schedule.d_day_num);
    
    const photo_time_list: string[] = [];
    if(schedule.detail){
        photo_time_list.push(schedule.detail.photo_start);
        photo_time_list.push("-");
        photo_time_list.push(schedule.detail.photo_end);
        
        const photo_lenth = getTimeDifferenceInMinutes(`${schedule.detail.photo_start}`, `${schedule.detail.photo_end}`, true);
        if(photo_lenth > -1){
            photo_time_list.push(`(${photo_lenth} 분)`);
        }
    }

    let preparation_item: React.ReactNode = <div></div>;
    if(schedule.detail && schedule.detail.memo.length > 0){
        preparation_item = (
                                <InfoDataTextWrap 
                                    label_text={"전달사항"} 
                                    value_text={schedule.detail.memo}
                                    is_bold={true}
                                />
                            )
    }

    useEffect(() => {

        const class_name_list: string[] = ["g_card", "g_click_event_item"];

        class_name_list.push(CSS.l_card_item);

        setTotalClassName(class_name_list.join(" "));

    }, [])

    return (
        <div 
            className={totalClassName}
        >
            <Text
                class_name_list={[CSS.l_info_text_wrap]}
            >
                <div className={CSS.l_text_box}>
                    <Dday
                        d_day={d_day_number}
                    />
                </div>
                <div className={CSS.l_text_box}>
                    <TextWrap 
                        label="촬영 명"
                        text={schedule.detail?.schedule_title || ""}
                    />
                </div>
                <div className={CSS.l_text_box}>
                    <TextWrap
                        label="촬영 일자" 
                        text={schedule.schedule_date_format}
                    />
                </div>
                <div className={CSS.l_text_box}>
                    <TextWrap 
                        label="촬영 시간" 
                        text={photo_time_list.join(" ")}
                    />
                </div>
                <div className={CSS.l_text_box}>
                    <TextWrap 
                        label="계약번호" 
                        text={schedule.detail?.contract_id || ""}
                    />
                </div>
                <div className={CSS.l_text_box}>
                    <TextWrap 
                        label="테마"
                        text={schedule.detail?.thema_list_name.join(", ") || ""}
                    />
                </div>
                <div className={CSS.l_text_box}>
                    <TextWrap 
                        label="상태"
                        text={schedule.status_text || ""}
                    />
                </div>
                <div className={CSS.l_text_box}>
                    {preparation_item}
                </div>
                <Button
                    size={"size2"}
                    onClick={result.onClick_btn}
                    purpose={result.purpose}
                >
                    {result.btn_title}
                </Button>
            </Text>
        </div>
    )
}

export default ScheduleBlock;