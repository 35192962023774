import CSS from "../static/css/cardInfoTextWrap.module.css"
import Text from "../../../Component/Elements/Text/Text";

const CardInfoTextWrap = (
    {
        text_list,
        type,
    }:{
        text_list: string[];
        type: "normal" | "title"
    }
) => {
    return (
        <div className={CSS.l_card_info_text_wrap_main}>
            {
                text_list.map((item, index) => {
                    return (
                        <Text
                            key={index}
                            class_name_list={[CSS.l_text]}
                            size={type === "title" ? "size7" : undefined}
                            bold={type === "title"}
                        >
                            {item}
                        </Text>
                    )
                })
            }
        </div>
    )
}

export default CardInfoTextWrap;