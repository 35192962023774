import { useState } from "react";
import Text from "../../../Text/Text";
import CSS from "./static/css/ImageGalleryToolbarBtn.module.css";

const ImageGalleryToolbarBtn = (
    {
        onClick,
        src,
        src_type,
    }:{
        onClick: React.MouseEventHandler<HTMLDivElement>;
        src: string;
        src_type: "img" | "text";
    }
) => {
    const [isHover, setIsHover] = useState<boolean>(false);

    const onHoverHandler = () => {
        setIsHover(true);
    }

    const hoverOutHandler = () => {
        setIsHover(false);
    }

    return(
        <div
            className={`g_click_event_item ${CSS.l_image_gallery_toolbar_btn_main}`}
            onClick={onClick}
            onMouseEnter={onHoverHandler}
            onMouseLeave={hoverOutHandler}
        >
            {
                src_type === "text"
                ?   <Text
                        color="white"
                        size={isHover ? "size7" : undefined}
                    >
                        {src}
                    </Text>
                :   <div className={CSS.l_image_gallery_toolbar_btn__icon_cover}>
                        <img 
                            className={CSS.l_image_gallery_toolbar_btn__icon_cover__icon}
                            src={src}
                        />
                    </div>
            }
        </div>
    )
}

export default ImageGalleryToolbarBtn;