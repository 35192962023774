import Text from "../Text/Text";
import CSS from "./static/css/symbolText.module.css";

const SymbolText = (
    {
        symbol,
        text,
    }:{
        symbol: string;
        text: string;
    }
) => {
    return (
        <div className={CSS.l_symbol_text_main}>
            <Text
                class_name_list={[CSS.l_symbol_text_main__symbol]}
                color={"red"}
            >
                {symbol}
            </Text>
            <Text
                class_name_list={[CSS.l_symbol_text_main__text]}
                color={"red"}
            >
                {text}
            </Text>
        </div>
    )
}

export default SymbolText;