import { useQuery } from "@tanstack/react-query";
import { callAxios, customToast, dev_console, isSuccess, printStateMsg, whoAmI } from "../../../Common/ts/commonTools";
import { useEffect, useState } from "react";
import { defaultApiReaponseInterface } from "../../../Common/interface/Common.interface";
import { themaInterface, themaListInterface } from "../static/interface/selectTheme.interface";
import { GC_TIME, STALE_TIME } from "../../../Common/ts/const";

const useThemeDataREST = (is_oops_display: "theme_select" | "theme_book") => {

    const [scheduleId, setScheduleId] = useState<number>(0);
    const [themaIdList, setThemaIdList] = useState<string>("");

    const isAbleToRequest = () => {
        let result: boolean = false;

        if(whoAmI.isCustomer()){
            if(scheduleId > 0){            
                result = true;
            }
        }

        return result
    }

    const {
        data: themeData,
        error: themeDataError,
        isLoading: themeDataIsLoading,
        refetch: themeDataRefetch,
    } = useQuery({
        queryKey: ["themeData", scheduleId, themaIdList],
        queryFn: callThemeData,
        enabled: isAbleToRequest(),
        select: (response) => {
            if(response){
                if(isSuccess(response)){
                    const config: {
                        schedule_data: themaListInterface | null;
                        theme_data_list: {
                            list: themaInterface[];
                            selected_theme_list: never[];
                            photo_id: string;
                            photo_name: string;
                            theme_num: string;
                        }[];
                    } = {
                        schedule_data: null,
                        theme_data_list: [],
                    }

                    const data: defaultApiReaponseInterface<themaListInterface> = response.data;

                    config.schedule_data = data.result;

                    const remove_empty_date = data.result.thema_list.filter((item) => item.list.length > 0 && parseInt(item.theme_num) > 0);
                    const collator = new Intl.Collator('ko-KR');
                    const theme_data = remove_empty_date.map((item) => {
                        const solt_list = item.list.sort((a, b) => collator.compare(a.thema_name, b.thema_name));

                        return({
                            ...item,
                            list: solt_list,
                            selected_theme_list: [],
                        })
                    })

                    config.theme_data_list = [...theme_data];

                    return config;
                }else{
                    customToast.error({msg: "테마 데이터 처리 과정에서 문제가 발생했습니다."});
                    printStateMsg(response);
                    return null;
                }
            }else{
                printStateMsg(response);
                customToast.error({msg: "테마 데이터 처리 과정에서 문제가 발생했습니다."});
                return null;
            }
        },
        staleTime: STALE_TIME, // 5분동안은 신선함
        gcTime: GC_TIME, // 10분 캐시 유지
        refetchOnMount: false,  // 다시 마운트 되더라도 새로운 요청을 보내지 않게 처리
    })

    async function callThemeData(){
        const is_oops_display_code = is_oops_display === "theme_select" ? 0 : 1;
        let thema_list_url = `api/v3/customers/thema_list?schedule_id=${scheduleId}&thema_id=${themaIdList}&is_oops_display=${is_oops_display_code}`;

        const response = await callAxios.api({
            url: thema_list_url,
            method: "get",
        })

        dev_console.log(thema_list_url);
        dev_console.log(response);

        return response;
    }

    useEffect(() => {
        if(!!themeDataError){
            dev_console.error(themeDataError);
            customToast.error({msg: "테마 데이터 처리 과정에서 문제가 발생했습니다."});
        }
    }, [themeDataError])

    return({
        themeData,
        themeDataIsLoading,
        themeDataRefetch,
        setScheduleId,
        setThemaIdList,
    })
}

export default useThemeDataREST