import { Item, useGallery } from 'react-photoswipe-gallery';
import { viewerImageInterface } from '../../../ImageGallery/interface/ImageGallery.interface';
import { fileType } from '../../../../../Common/ts/const';
import { getFileType, getViewportSize } from '../../../../../Common/ts/commonTools';
import VideoPlayer from '../../../VideoPlayer/VideoPlayer';
import Grid from '../../../Grid/Grid';
import ImgBlock_vr2 from '../../../../../CustomerPage/ProductionList/components/ImgBlock/ImgBlock_vr2';

const ReactPhotoSwipeGalleryContents = (
    {
        img_list,
    }:{
        img_list: viewerImageInterface[];
    }
) => {

    // 동영상 썸네일을 클릭했을 때 라이트박스를 출력하기위해 사용하는 api hook
    const { open: open_api } = useGallery()

    // 사진 확대기능을 위해서 필수적으로 사진의 가로/세로길이를 고정값으로 정해줘야한다.
    // css를 통해 사진에 contain속성을 적용시켰다.

    const { viewport_width, viewport_height } = getViewportSize()

    return(
        <Grid>
            {
                img_list.map((img_data, index) => {
                    
                    let file_type: fileType = fileType.none;

                    if(img_data.file_type === fileType.img){
                        file_type = fileType.img;
                    }else if(img_data.file_type === fileType.video){
                        file_type = fileType.video;
                    }else if(img_data.file_type === fileType.zip){
                        file_type = fileType.zip;
                    }else{
                        file_type = getFileType(img_data.origin_url);
                    }

                    if(img_data.file_type === fileType.video){
                        return(
                            <Item
                                key={index}
                                original={img_data.origin_url}
                                thumbnail={img_data.thumbnail_url}
                                width={viewport_width}
                                height={viewport_height}
                                content={
                                    <VideoPlayer src={img_data.origin_url}/>
                                }
                            >     
                                {({ ref, open }) => (
                                    <ImgBlock_vr2
                                        index={index}
                                        folder_id={img_data.folder_id}
                                        img_src={img_data.thumbnail_url}
                                        file_name={img_data.file_name}
                                        file_type={file_type}
                                        video_controls={false}
                                        ref={ref}
                                        imgClickHandler={() => {
                                            open_api(index)
                                        }}
                                    />
                                )}
                            </Item>
                        )
                    }else{
                        return(
                            <Item
                                key={index}
                                original={img_data.origin_url}
                                thumbnail={img_data.thumbnail_url}
                                width={viewport_width}
                                height={viewport_height}
                            >     
                                {({ ref, open }) => (
                                    <ImgBlock_vr2
                                        index={index}
                                        folder_id={img_data.folder_id}
                                        img_src={img_data.thumbnail_url}
                                        file_name={img_data.file_name}
                                        file_type={file_type}
                                        ref={ref}
                                        imgClickHandler={() => {
                                            open_api(index)
                                        }}
                                    />
                                )}
                            </Item>
                        )
                    }

                })
            }
        </Grid>
    )
}

export default ReactPhotoSwipeGalleryContents;