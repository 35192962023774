import { useEffect, useState } from "react";
import CSS from "./static/css/CardContainer.module.css";

type cardLevelType = "level0" | "level1" | "level2";
type backgroundColorType = "gray" | "ligth_gray";
type paddingType = "none";

const CardContainer = (
    {
        children,
        class_name_list,
        level,
        onClick,
        is_hidden_cover_in_mobile,
        background_color,
        padding,
        onDragEnter,
        onDragLeave,
        onDragOver,
        onDrop,
    }:{
        children?: React.ReactNode;
        class_name_list?: string[];
        level?: cardLevelType;
        onClick?: React.MouseEventHandler<HTMLDivElement>;
        is_hidden_cover_in_mobile?: boolean;
        background_color?: backgroundColorType;
        padding?: paddingType;
        onDragEnter?: (event: React.DragEvent<HTMLDivElement>) => void;
        onDragLeave?: (event: React.DragEvent<HTMLDivElement>) => void;
        onDragOver?: (event: React.DragEvent<HTMLDivElement>) => void;
        onDrop?: (event: React.DragEvent<HTMLDivElement>) => void;
    }
) => {
    const [totalClassName, setTotalClassName] = useState<string>("");

    useEffect(() => {
        const total_class_name_list: string[] = [];

        if(onClick){
            total_class_name_list.push("g_click_event_item");
        }

        if(class_name_list){
            for(const class_name of class_name_list){
                total_class_name_list.push(class_name);
            }
        }

        total_class_name_list.push(CSS.l_card_container_main);

        if(!!level){
            total_class_name_list.push(CSS[level]);
        }

        if(is_hidden_cover_in_mobile){
            total_class_name_list.push(CSS.is_hidden_cover_in_mobile);
        }

        if(background_color){
            total_class_name_list.push(CSS.background_color);
            total_class_name_list.push(CSS[background_color]);
        }

        if(padding){
            total_class_name_list.push(CSS.padding);
            total_class_name_list.push(CSS[padding]);
        }
        
        setTotalClassName(total_class_name_list.join(" "));        
    }, [class_name_list, level, onClick, background_color, padding])

    return(
        <div 
            className={totalClassName}
            onClick={onClick}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            onDrop={onDrop}
        >
            {children}
        </div>
    )
}

export default CardContainer;