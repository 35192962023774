import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { callAxios, customToast, dev_console, isSuccess, printStateMsg, whoAmI } from "../../../Common/ts/commonTools";
import { themaBookDetailListInterface } from "../../../PublicPage/ThemeBook/static/interface/themeBook.interface";
import { defaultApiReaponseInterface } from "../../../Common/interface/Common.interface";
import { scheduleProductInterface } from "../static/interface/ProductionList.interface";
import { GC_TIME, STALE_TIME } from "../../../Common/ts/const";

const usePhotoProductOptionDataListREST = (initial_production: scheduleProductInterface) => {
    const isAbleToRequest = () => {
        let result: boolean = false;

        if(whoAmI.isCustomer()){
            result = true;
        }

        return result;
    }

    const {
        data: photoProductOptionDataList = [],
        error: photoProductOptionDataListError,
        isLoading: photoProductOptionDataListIsLoading,
        refetch: photoProductOptionDataListRefetch,
    } = useQuery({
        queryKey: ["usePhotoProductOptionDataListREST", initial_production.office_id, initial_production.detail_product_id],
        queryFn: callPhotoProductOptionDataList,
        enabled: isAbleToRequest,
        staleTime: STALE_TIME,
        gcTime: GC_TIME,
        refetchOnMount: false,
        select: (response) => {
            if(!!response && isSuccess(response)){
                const row_response: defaultApiReaponseInterface<themaBookDetailListInterface[]> = response.data;
                return row_response.result;
            }else{
                printStateMsg(response);
                customToast.error({msg: "옵션 정보 호출과정에서 문제가 발생했습니다.", toastId: `${initial_production.contract_product_id}`})
                return [];
            }
        }
    })

    async function callPhotoProductOptionDataList(){
        const thema_book_detail_list_url = `api/v3/customers/thema_book_detail_list?type=1&office_id=${initial_production.office_id}&photo_id=${initial_production.detail_product_id}&thema_id=`;
        const response = await callAxios.api({
            url: thema_book_detail_list_url,
            method: "get",
        })

        dev_console.log(thema_book_detail_list_url);
        dev_console.log(response);

        return response;
    }
    
    useEffect(() => {
        if(!!photoProductOptionDataListError){
            customToast.error({msg: "옵션 정보 호출 과정에서 문제가 발생했습니다."});
            dev_console.error(photoProductOptionDataListError);
        }
    }, [photoProductOptionDataListError])

    return({
        photoProductOptionDataList,
        photoProductOptionDataListError,
        photoProductOptionDataListIsLoading,
        photoProductOptionDataListRefetch,
    })
}

export default usePhotoProductOptionDataListREST;