import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { dev_console, downloadFile, isAbleAccesser, whereAmI } from "../../Common/ts/commonTools";
import { contractListDataInterace } from "./interface/ContractDocs.interface";
import useContractDetailREST from "./hooks/useContractDetailREST.hook";
import CSS from "./static/css/ContractDetailViewerMobile.module.css";
import Text from "../../Component/Elements/Text/Text";
import Img from "../../Component/Elements/Media/components/Img/Img";
import Button from "../../Component/Elements/Button/Button";
import ImageGalleryContainer from "../../Component/Elements/ImageGallery/ImageGalleryContainer";
import LoopAnimationModal from "../../Component/Elements/LoopAnimationModal/LoopAnimationModal";

const ContractDetailViewerMobile = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const state = location.state as {
        contractData: contractListDataInterace,
    };

    if(!!state && !!state.contractData){
        dev_console.log(state.contractData);
    }else{
        window.location.href = "/contractDocs";
    }

    const {
        contractDetailData,
        contractDetailDataError,
        contractDetailDataIsLoading,
    } = useContractDetailREST(state.contractData.contract_id)

    useEffect(() => {
        isAbleAccesser(["C"]);

        // 모바일 환경이 아닌경우 내보낸다.
        if(!whereAmI.isMobile()){
            navigate("/contractDocs");
        }
    }, [])

    useEffect(() => {
        if(contractDetailDataError){
            navigate("/contractDocs");
        }
    }, [contractDetailDataError])

    const downloadBtnHandler = (url: string, file_name: string) => {
        if(!window.confirm("계약서를 다운로드 하시겠습니까?")){
            return ;
        }

        downloadFile(url, file_name);
    }

    return(
        <div className={CSS.l_contract_detail_viewer_mobile_main}>
            <div className={CSS.l_contract_detail_viewer_mobile__title_container}>
                <Text is_title={true}>
                    계약서
                </Text>
                <div className={CSS.l_contract_detail_viewer_mobile__title_container__contract_info}>
                    <Text>
                        {`계약일: ${state.contractData.contract_date}`}
                    </Text>
                    <Text>
                        {`패키지 명: ${state.contractData.package_name}`}
                    </Text>
                </div>
            </div>
            <div className={CSS.l_contract_detail_viewer_mobile__contents_container}>
                <ImageGalleryContainer class_name_list={[CSS.l_contract_detail_viewer_mobile__contents_container__contents_cover]}>
                    {
                        contractDetailData.map((item, index) => {
                            const file_name: string = `${state.contractData.contract_id}_${state.contractData.package_name}_${state.contractData.contract_date}.jpg`;

                            return(
                                <div
                                    className={CSS.l_contract_detail_viewer_mobile__contents_container__doce_item}
                                    key={index}
                                >
                                    <div 
                                        className={CSS.l_contract_detail_viewer_mobile__contents_container__doce_item__img_cover}
                                    >
                                        <Img 
                                            class_name_list={[CSS.l_contract_detail_viewer_mobile__contents_container__doce_item__img]}
                                            src={item.img_list.middle_url}
                                            customdata={item.img_list.org_url}
                                            object_fit={"contain"}
                                        />
                                    </div>
                                    <Button
                                        purpose={"dev"}
                                        onClick={downloadBtnHandler.bind(null, item.img_list.org_url, file_name)}
                                    >
                                        저장
                                    </Button>
                                </div>
                            )
                        })
                    }
                </ImageGalleryContainer>
            </div>

            <LoopAnimationModal 
                isModalOpen={contractDetailDataIsLoading}
            />
        </div>
    )
}

export default ContractDetailViewerMobile;