import { toast } from "react-toastify";
import { customToast, dev_console, getMyCookieData, isInAppBrowser } from "../../../Common/ts/commonTools";
import Badge from "../../../Component/Elements/Badge/Badge";
import Button from "../../../Component/Elements/Button/Button";
import streamSaver from "streamsaver";
import { useEffect } from "react";

const ImageZipDownload = (
    {
        download_type,
        file_name,
        is_print_badge,
        photo_upload_id,
        isDownloading,
        setIsDownloading,
    }:{
        download_type: number;
        file_name: string;
        is_print_badge: boolean;
        photo_upload_id: number;
        isDownloading: boolean;
        setIsDownloading: React.Dispatch<React.SetStateAction<boolean>>;
    }
) => {
    // 서버로부터 이미지를 직접 요청하는 함수
    const callImageData = async () => {
        try{ 
            const api_url = process.env.REACT_APP_API_URL;        
            const token = getMyCookieData.token();
            const endpoint_url = `api/v3/customers/photo-download?token=${token}&download_type=${download_type}&preview_id=${photo_upload_id}&file_name=${file_name}`;
            const final_url =  `${api_url}/${endpoint_url}`;

            dev_console.log(final_url);

            const callImageData = () => {
                return fetch(final_url, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    }
                })
            }
            const response = await toast.promise(
                callImageData, 
                {
                    pending: "다운로드를 준비중입니다. 잠시 기다려주세요",
                    success: "다운로드 준비가 완료되었습니다.",
                    error: "다운로드를 준비하는 과정에서 문제가 발생했습니다."
                }
            )

            const content_length = response.headers.get('content-length');

            return {response, content_length};
        }catch(error){
            dev_console.error(error);
            customToast.error({msg: "서버로부터 사진 데이터를 호출하는데 실패했습니다."});

            return {response: null, content_length: null};
        }
    }

    const saveFileStream = (file_name: string, content_length: string | null) => {
        try{
            const fileStream = streamSaver.createWriteStream(`${file_name}.zip`, {
                size: content_length ? parseInt(content_length, 10) : undefined,
            });

            return fileStream;
        }catch(error){
            dev_console.error(error);
            customToast.error({msg: "압축파일을 생성하는 과정에서 문제가 발생했습니다."})

            return null;
        }
    }

    const doFileStream = async (response: Response, fileStream: WritableStream<Uint8Array>) => {
        try{    
            if(response.body){
                const readableStream = response.body;
        
                if (window.WritableStream && readableStream.pipeTo) {
                    await readableStream.pipeTo(fileStream);
                } else {
                    const writer = fileStream.getWriter();
                    const reader = readableStream.getReader();
                    const pump = () =>
                        reader.read().then(({ done, value }) => {
                            if (done) {
                                writer.close();
                                return;
                            }
                            writer.write(value!).then(pump);
                        });
                    pump();
                }
            }
        }catch(error){
            dev_console.error(error);

            customToast.error({
                msg: "압축파일을 저장하는 과정에서 문제가 발생했습니다."
            })
        }
    }

    // 다운로드 버튼 핸들러
    const handleDownload = async () => {
        if(isDownloading){
            alert("다운로드가 진행중입니다.");
            return ;
        }

        if(!window.confirm("사진들을 다운로드 하시겠습니까?")){
            return ;
        }

        setIsDownloading(true);

        const {response, content_length} = await callImageData();

        setIsDownloading(false);

        if(!response || !response.body){
            customToast.error({
                msg: "사진 다운로드 기능을 지원하지 않는 브라우저 입니다"
            })

            return ;
        }
 
        const fileStream =  saveFileStream(file_name ,content_length);
        if(!fileStream){
            return ;
        }

        // 파일 스트리밍 처리 시작
        await doFileStream(response, fileStream);
    }

    const handleDownload_for_kakao = () => {
        if(!window.confirm("파일을 다운로드 하시겠습니까?\n 파일용량에따라 대기시간이 발생할 수 있습니다.")){
            return ;
        }

        setIsDownloading(true);

        setTimeout(() => {
            const api_url = process.env.REACT_APP_API_URL;        
            const token = getMyCookieData.token();
            const endpoint_url = `api/v3/customers/photo-download?token=${token}&download_type=${download_type}&preview_id=${photo_upload_id}&file_name=${file_name}`;
            const final_url =  `${api_url}/${endpoint_url}`;
            window.location.href = final_url;
        }, 500);
    }

    useEffect(() => {
        const handleBeforeUnload = (event: Event) => {
            setIsDownloading(false); // 다운로드가 시작되면 모달 닫기
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    return(
        <>
            {
                isDownloading
                ?   <Button
                        size={"size_full"}
                        purpose={"disabled"}
                    >
                        다운로드가 진행중입니다.
                    </Button>
                :   <Button
                        onClick={
                            (isInAppBrowser() === "KakaoTalk" || isInAppBrowser() === "LINE")
                            ?   handleDownload_for_kakao
                            :   handleDownload
                        }
                        size={"size_full"}
                        purpose={"dev"}
                    >
                        <Badge
                            is_print_badge={is_print_badge}
                        >
                            다운로드
                        </Badge>
                    </Button>
            }
        </>
    )
}

export default ImageZipDownload;