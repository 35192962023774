import { DayCellContentArg } from "@fullcalendar/core";
import CSS from "../static/css/dayCell.module.css"
import { resetTime } from "../../../../Common/ts/commonTools";
import Text from "../../Text/Text";

const DayCell = (
    {
        dayCellInfo,
        selectedDate
    }:{
        dayCellInfo: DayCellContentArg;
        selectedDate: Date | null;
    }
) => {

    const events = dayCellInfo.view.calendar.getEvents();

    const hasEvents = events.some((event) => {

        let init_today_date = resetTime(dayCellInfo.date);

        let init_event_start: Date | null = null;
        if(event.start){
            init_event_start = resetTime(event.start);
        }
        
        let init_event_end: Date | null = null;
        if(event.end){
            init_event_end = resetTime(event.end);
        }
        
        if(init_today_date){
            if(init_event_start && init_event_start.getTime() <= init_today_date.getTime()){
                if(init_event_end){
                    if(init_event_end.getTime() >= init_today_date.getTime()){
                        return true;
                    }else{
                        return false;
                    }
                }else{
                    if(init_event_start.getTime() === init_today_date.getTime()){
                        return true;
                    }else{
                        return false;
                    }
                }
            }else{
                return false;
            }
        }else{
            return false;
        }
    });

    let init_selected_date: Date | null = null;
    if(selectedDate){
        init_selected_date = resetTime(selectedDate);
    }
    const total_class_name_list: string[] = [CSS.l_day_cell];
    if(init_selected_date && init_selected_date.getTime() === dayCellInfo.date.getTime()){
        total_class_name_list.push(CSS.l_selected)
    }

    return (
        <div className={CSS.l_day_cell_main}>
            {
                hasEvents
                &&  <div className={CSS.l_event_mark}>.</div>
            }
            <Text 
                size={"size1"}
                class_name_list={total_class_name_list}
            >
                {dayCellInfo.date.getDate()}
            </Text>
        </div>
    );
}

export default DayCell;