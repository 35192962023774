// 컴포넌트 시뮬레이션을을 위한 테스트페이지
import { useState } from "react";
import DownloadLoadingModalForKakao from "../CustomerPage/PhotoList/components/DownloadLoadingModal/DownloadLoadingModalForKakao";
import CSS from "./static/css/testPage.module.css";
import Button from "../Component/Elements/Button/Button";
import RedirectToAnotherBrowser from "../PublicPage/RedirectToAnotherBrowser/RedirectToAnotherBrowser";

const TestPage = () => {
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    
    return (
        <div className={CSS.l_test_page_main}>
            <RedirectToAnotherBrowser />
        </div>
    )
}

export default TestPage