import { productTypeType, scheduleProductDetailInterface, scheduleProductInterface } from "../interface/ProductionList.interface";

export const isNeedToSeletItem = (item: scheduleProductInterface) => {
    let result:boolean = false;

    if(item.photo_select_pre_yn === 1 && item.final_select === "N"){
        result = true;
    }

    return result;
}

export const getMinMaxPhoto = (product_detail: scheduleProductDetailInterface) => {
    const config: {
        min: number;
        max: number;
    } = {
        min: 0,
        max: 0,
    }

    const min:number = parseInt(product_detail.min_photo_count);
    const max:number =  parseInt(product_detail.max_photo_count);
    
    if(!isNaN(min) && !isNaN(max)){
        config.min = min;
        config.max = max;
    }
    
    return(config)
}

export const isAlbum = (product_type: productTypeType) => {
    return product_type === "2";
}