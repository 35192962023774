import { useEffect, useState } from "react";
import CardContainer from "../../../../Component/Elements/CardContainer/CardContainer";
import CSS from "./static/css/DocsItem.module.css";
import Text from "../../../../Component/Elements/Text/Text";

const DocsItem = (
    {
        doce_date,
        doce_package_name,
        onClick,
        is_highlight,
    }:{
        doce_date: string;
        doce_package_name: string;
        onClick: React.MouseEventHandler<HTMLDivElement>;
        is_highlight?: boolean;
    }
) => {
    const [totalClassNameList, setTotalClassNameList] = useState<string[]>([]);

    useEffect(() => {
        const total_class_name_list: string[] = ["g_click_event_item", CSS.l_docs_item_main];

        if(is_highlight){
            total_class_name_list.push(CSS.is_highlight)
        }

        setTotalClassNameList(total_class_name_list);
    }, [is_highlight])

    return(
        <CardContainer
            class_name_list={totalClassNameList}
            onClick={onClick}
        >
            <Text
                color={is_highlight ? "white" : undefined}
                bold={is_highlight}
            >
                {doce_date}
            </Text>
            <Text
                color={is_highlight ? "white" : undefined}
                bold={is_highlight}
            >
                {doce_package_name}
            </Text>
        </CardContainer>
    )
}

export default DocsItem;