import { useQuery } from "@tanstack/react-query";
import { callAxios, customToast, dev_console, whoAmI } from "../../../Common/ts/commonTools";
import { useEffect } from "react";
import { photoReadDataInterface } from "../static/interface/PhotoList.interface";
import { GC_TIME, STALE_TIME } from "../../../Common/ts/const";

const MAIN_TYPE_VALUE_LIST = ["나의 사진", "후기 사진"];
const STATUS_VALUE_LIST = ["원본", "편집 진행중", "편집 완료"];

const usePhotoListDataREST = (
    {
        main_type,
        status,
    }:{
        main_type: 1 | 2, // 1: "나의 사진" | 2: "후기 사진"
        status: 1 | 2 | 3, // 1: "원본" | 2: "편집 진행중" | 3: "편집 완료"
    }
) => {

    const isAbleToRequest = () => {
        let result: boolean = false;

        if(whoAmI.isCustomer()){
            if(main_type > 0){
                if(status > 0){
                    result = true;
                }
            }
        }

        return result;
    }

    const {
        data: photoListData = [],
        error: photoListDataError,
        isLoading: photoListDataIsLoading,
        refetch: photoListDataRefetch,
    } = useQuery({
        queryKey: ["photoListData", main_type, status],
        queryFn: callPhotoListData,
        select: (response) => {
            if(response){
                if(response.status === 200 && response.data.msg === "success"){
                    const result_data: photoReadDataInterface[]  = response.data.datas
                    return result_data;
                }else{
                    return [];
                }
            }else{
                return [];
            }
        },
        enabled: isAbleToRequest(),
        staleTime: STALE_TIME, // 5분동안은 신선함
        gcTime: GC_TIME, // 10분 캐시 유지
        refetchOnMount: false,  // 다시 마운트 되더라도 새로운 요청을 보내지 않게 처리
    })
    
    async function callPhotoListData(){
        const photo_read_url = `api/v3/customers/photo_read?main_type=${main_type}&status=${status}`;
        const response = await callAxios.api({
            url: photo_read_url,
            method: "get",
        })  

        dev_console.log(photo_read_url);
        dev_console.log(response);

        return response;
    }

    useEffect(() => {
        if(!!photoListDataError){
            dev_console.error(photoListDataError);
            customToast.error({msg: `${MAIN_TYPE_VALUE_LIST[main_type]}의 ${STATUS_VALUE_LIST[status]} 사진 목록을 요청하는 과정에서 문제가 발생했습니다.`});
        }
    }, [photoListDataError, main_type, status])

    return ({
        photoListData,
        photoListDataError,
        photoListDataIsLoading,
        photoListDataRefetch,
    })
}

export default usePhotoListDataREST;