import { forwardRef, useEffect, useState } from "react";
import noimg from "./static/img/noimage2.svg";
import CSS from "./static/css/img.module.css";

interface imgInterface{
    src?: string;
    id?: string;
    className?: string;
    alt?: string;
    onClick?: React.MouseEventHandler<HTMLImageElement>
    onLoad?: React.ReactEventHandler<HTMLImageElement>; 
    loading?: "eager" | "lazy";
    title?: string,
    object_fit?: "cover" | "fill" | "contain" | "scale-down" | "none";
    height_standard?: boolean;
    class_name_list?: string[];
    customdata?: string;
    hidden?: boolean;
}

const Img = forwardRef<HTMLImageElement, imgInterface> ((
    {
        src,
        id,
        alt,
        onClick,
        onLoad,
        loading,
        title,
        object_fit,
        height_standard,
        class_name_list,
        customdata,
        hidden,
    }, ref
) => {
    const [totalClassName, setTotalClassName] = useState<string>("");
    // const [isLoaded, setIsLoaded] = useState<boolean>(false);
    // const [isError, setIsError] = useState<boolean>(false);

    useEffect(() => {
        const total_class_name_list: string[] = [];

        if(class_name_list){
            for(const class_name of class_name_list){
                total_class_name_list.push(class_name)
            }
        }
        
        total_class_name_list.push(CSS.l_img_main)

        if(object_fit){
            total_class_name_list.push(CSS.object_fit);

            if(object_fit === "cover"){
                total_class_name_list.push(CSS.cover);
            }else if(object_fit === "fill"){
                total_class_name_list.push(CSS.fill);
            }else if(object_fit === "contain"){
                total_class_name_list.push(CSS.contain);
            }else if(object_fit === "scale-down"){
                total_class_name_list.push(CSS.scale_down);
            }else{
                total_class_name_list.push(CSS.none);
            }
        }

        if(height_standard){
            total_class_name_list.push(CSS.height_standard);
        }

        // if(isLoaded){
        //     total_class_name_list.push(CSS.isLoaded);
        // }

        setTotalClassName(total_class_name_list.join(" "))

    }, [object_fit, height_standard, class_name_list])
    
    // useEffect(() => {
    //     if(!!src){
    //         setIsLoaded(false);
    //         setIsError(false);
    //     }
    // }, [src])

    return(
        <img
            className={totalClassName}
            ref={ref}
            id={id}
            // src={isError || !(!!src) ? noimg : src}
            src={src || noimg}
            // onLoad={(e) => {
            //     setIsLoaded(true);
            //     if(onLoad){
            //         onLoad(e);
            //     }
            // }}
            // onError={(e) => {
            //     setIsError(true);
            // }}
            alt={alt}
            onClick={onClick}
            loading={loading}
            title={title}
            draggable="false"
            data-customdata={customdata}
            onContextMenu={(e) => {
                e.preventDefault(); // 기본 메뉴 방지
            }}
            hidden={hidden}
        />
    )
})

export default Img;