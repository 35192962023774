import { Link } from "react-router-dom";
import Button from "../../../Component/Elements/Button/Button";
import Input from "../../../Component/Elements/Input/Input";
import CSS from "../static/css/loginInputContainer.module.css";
import logo from "../../../Common/image/EDRP-OOPS-02.png";
import { callAxios, customToast, dev_console } from "../../../Common/ts/commonTools";
import { setCookie } from "../../../Common/ts/appTools";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import Text from "../../../Component/Elements/Text/Text";
import Img from "../../../Component/Elements/Media/components/Img/Img";
import CheckbaxContainer from "../../../Component/Elements/CheckbaxContainer/CheckbaxContainer";

const LoginInputContainer = () => {

    // input에 입력된 id
    const [id, setId] = useState('');
    
    // input에 입력된 password
    const [password, setPassword] = useState('');

    // 아이디 기억하기 여부
    const [isRememberedId, setIsRememberedId] = useState(false);

    // 자동 로그인 여부
    const [isAutoLogin, setIsAutoLogin] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    const [errorMsg, setErrorMsg] = useState<string>("");

    const id_input = useRef<HTMLInputElement>(null);
    const password_input = useRef<HTMLInputElement>(null);
    const button_ref = useRef<HTMLButtonElement>(null);

    async function requestLogin({ id, password }: { id: string; password: string }) {
        const data = new FormData();
        data.append('username', id);
        data.append('password', password);

        const login_url = "api/v1/accounts/login/";
        const response = await callAxios.api({
            url: login_url,
            method: 'post',
            data: data,
            dataType: 'formdata',
        })

        dev_console.log(login_url);
        dev_console.log(response);

        return response;
    }

    useEffect(() => {
        
        // 아이디 저장상태 state에 업데이트
        const localStorage_is_remembered_id = localStorage.getItem('isRememberedId');
        if(localStorage_is_remembered_id === "true"){
            const localStorage_username = localStorage.getItem('username');
            if(localStorage_username){
                setIsRememberedId(true);
                setId(localStorage_username);
            }
        }else{
            setIsRememberedId(false);
        }

    }, []);

    useEffect(() => {
        if(!isRememberedId){
            setIsAutoLogin(false);
        }
    }, [isRememberedId])

    useEffect(() => {
        if(isAutoLogin){
            setIsRememberedId(true);
        }
    }, [isAutoLogin])

    const printError = (error: string) => {
        customToast.error({
            msg: error, 
            toastId: "login_fail"
        });
    }

    // 로그인 요청 함수
    const loginAction = () => {
        setErrorMsg("");

        if (id.length < 1) {
            id_input.current?.focus();
            printError("아이디를 입력해주세요.")
        } else if (password.length < 1) {
            password_input.current?.focus();
            printError('비밀번호를 입력해주세요.');
        } else {
            setIsLoading(true);
            requestLogin({id, password})
            .then((response) => {
                if(response.status === 200){
                    if(response.data.user_type !== "C" && response.data.user_type !== "E"){
                        printError('아이디 혹은 비밀번호를 확인해주세요.');
                    }else{
                        let days = 0;
                        // 자동 로그인
                        if (isAutoLogin) {
                            days = 7;
                        }
    
                        setCookie({name:'token', value: response.data.token, days});
                        setCookie({name:'username', value: response.data.username, days});
                        setCookie({name:'name', value: response.data.name, days});
                        setCookie({name:'user_type', value: response.data.user_type, days});
                        setCookie({name:'user_id', value: response.data.user_id, days});
                        setCookie({name:'office_id', value: response.data.office_id, days});
                        setCookie({name:'office_name', value: response.data.office_name, days});
                        setCookie({name: "office_addr", value: response.data.office_addr, days});
    
                        // 아이디 기억하기
                        if (isRememberedId) {
                            localStorage.setItem('isRememberedId', 'true');
                            localStorage.setItem('username', id);
                        }
    
                        // 로그인 결과를 적용하기위해 페이지 자체를 새로고침
                        window.location.href = '/';
                    }
                } else {
                    printError("로그인에 실패했습니다.");
                }
            })
            .catch((error) => {
                if(axios.isAxiosError(error)){
                    dev_console.log(error);
                    if(error.response && error.response.status === 400){
                        // 서버에서 아이디와 비밀번호를 통해 계정 조회에 실패(잘못 입력한 계정 id 혹은 비밀번호)할 경우 400 status code로 응답한다.
                        printError("아이디 혹은 비밀번호를 확인해주세요.");
                    }else if(error.response && error.response.data && error.response.data.msg === "로그인이 불가능 합니다."){
                        printError("아이디 혹은 비밀번호를 확인해주세요.");
                    }else{
                        printError("로그인에 실패했습니다.");
                    }
                }else{
                    // AxiosError가 아닌 일반적인 에러 처리
                    dev_console.log(error);
                    printError("예상치 못한 오류가 발생했습니다.");
                }
            })
            .finally(() => {
                setIsLoading(false);
            })
        }
    };

    const onKeyPressAction = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            loginAction();
        }
    };

    return (
        <div className={CSS.l_login_input_container_main}>
            <div className={CSS.l_login_input_container__login_logo_container}>
                <div className={CSS.l_login_input_container__login_logo_container__logo_img_cover}>
                    <Img
                        class_name_list={[CSS.l_login_input_container__login_logo_container__logo_img_cover__img]}
                        src={logo}
                    />
                </div>
                <Text
                    size={"size6"}
                    color={"dark"}
                    bold={true}
                >
                    로그인
                </Text>
            </div>
            <div className={CSS.l_login_input_container__contents}>
                <div className={CSS.l_login_input_container__input_container}>
                    <div className={CSS.input_container}>
                        <Text
                            color={"light_gray"}
                        >
                            아이디
                        </Text>
                        <Input
                            type='text'
                            name='username'
                            id='username'
                            maxLength={20}
                            value={id}
                            onChange={(e) => {
                                setErrorMsg("");
                                setId(e.target.value);
                            }}
                            onKeyDown={onKeyPressAction}
                            ref={id_input}
                            size={"size_full"}
                            placeholder={"아이디를 입력하세요"}
                        />
                    </div>

                    <div className={CSS.input_container}>
                        <Text
                            color={"light_gray"}
                        >
                            비밀번호
                        </Text>
                        <Input
                            type='password'
                            name='password'
                            id='password'
                            maxLength={20}
                            value={password}
                            onChange={(e) => {
                                setErrorMsg("");
                                setPassword(e.target.value);
                            }}
                            onKeyDown={onKeyPressAction}
                            ref={password_input}
                            size={"size_full"}
                            placeholder={"비밀번호를 입력하세요"}
                        />
                    </div>
                </div>
                <div className={CSS.l_login_input_container__option_container}>
                    <CheckbaxContainer 
                        label={"아이디 기억하기"}
                        isChecked={isRememberedId}
                        onClickHander={() => {
                            setIsRememberedId(!isRememberedId);
                        }}
                    />

                    <CheckbaxContainer 
                        label={"자동 로그인"}
                        isChecked={isAutoLogin}
                        onClickHander={() => {
                            setIsAutoLogin(!isAutoLogin);
                        }}
                    />
                </div>
                <div className={CSS.l_login_input_container__button_container}>
                    {
                        !!errorMsg
                        &&  <Text
                                size={"size2"}
                                color={"red"}
                            >
                                {errorMsg}
                            </Text>
                    }
                    <Button
                        size={"size_full"}
                        padding={"large"}
                        onClick={loginAction}
                        type='button'
                        ref={button_ref}
                        disabled={isLoading}
                    >
                        <div className={CSS.l_login_btn_text}>
                            로그인
                        </div>
                    </Button>
                </div>
                <div className={CSS.l_login_input_container__function_container}>
                    <Link to='/resetUserPassword'>
                        <Text
                            size={"size2"}
                        >
                            비밀번호 초기화
                        </Text>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default LoginInputContainer;