import { useEffect, useState } from "react";
import CSS from "./static/css/FunctionIcon.module.css"

const FunctionIcon = (
    {
        src,
        onClick,
        class_name_list,
    }:{
        src: string;
        onClick?: React.MouseEventHandler<HTMLSpanElement>;
        class_name_list?: string[];
    }
) => {

    const [totalClassName, setTotalClassName] = useState<string>("")

    useEffect(() => {
        const total_class_name_list: string[] = [];

        if(onClick){
            total_class_name_list.push("g_click_event_item");
        }

        if(!!class_name_list){
            for(const class_name of class_name_list){
                total_class_name_list.push(class_name);
            }
        }

        total_class_name_list.push(CSS.l_function_icon_main);

        setTotalClassName(total_class_name_list.join(" "));
    }, [onClick, class_name_list])
    
    return(
        <span
            className={totalClassName}
            onClick={onClick}
        >
            <img
                className={CSS.l_function_icon__img}
                src={src}
            />
        </span>
    )
}

export default FunctionIcon;