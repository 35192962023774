import { useEffect, useState } from "react";
import MediaCard from "../../../../Component/Elements/MediaCard/MediaCard";
import CSS from "./static/css/productBlockDetail.module.css";
import { scheduleProductDetailInterface, scheduleProductInterface } from "../../static/interface/ProductionList.interface";
import Text from "../../../../Component/Elements/Text/Text";
import useDateString from "../../../../Common/hooks/useDateString.hook";

const ProductBlockDetail = (
    {
        selectedProduct,
        onClick,
        is_highlight,
        is_hover_action_activate,
        is_print_badge,
    }:{
        selectedProduct: scheduleProductInterface;
        onClick?: React.MouseEventHandler<HTMLDivElement>;
        is_highlight: boolean;
        is_hover_action_activate: boolean;
        is_print_badge?: boolean;
    }
) => {

    const [totalClassNameList, setTotalClassNameList] = useState<string[]>([]);
    const [detailData, setDetailData] = useState<scheduleProductDetailInterface | null>(null);
    const {datetime, dateString, setOriginDateData} = useDateString(null);

    useEffect(() => {
        if(selectedProduct.detail && selectedProduct.detail.length > 0){
            setDetailData(selectedProduct.detail[0]);
            setOriginDateData(new Date(selectedProduct.staff_download_at))
        }else{
            setDetailData(null);
        }
    }, [selectedProduct])

    useEffect(() => {
        const total_class_name_list: string[] = ["g_grid_item"];

        if(onClick){
            total_class_name_list.push("g_click_event_item");
        }

        total_class_name_list.push(CSS.l_product_block__media_card);

        setTotalClassNameList(total_class_name_list);
    }, [onClick])

    return (
        <MediaCard
            class_name_list={totalClassNameList}
            onClick={onClick}
            src={selectedProduct.img_url.middle_url}
            size={"full"}
            is_highlight={is_highlight}
            is_hover_action_activate={is_hover_action_activate}
            is_print_badge={is_print_badge}
            children_container_class_name_list={[CSS.l_product_block__media_card__children]}
        >
            <div className={CSS.l_product_block__info_continer}>
                <Text 
                    size={"size5"}
                    bold={true}
                    class_name_list={[CSS.l_product_block__info_continer__title]}
                >
                    {selectedProduct.product_name}
                </Text>
                <div className={CSS.l_product_block__info_continer__info_data_text_container}>                    
                    <Text
                        size={"size3"}
                    >
                        계약번호 : {selectedProduct.contract_id}
                    </Text>
                    <Text
                        size={"size3"}
                    >
                        패키지 명 : {selectedProduct.package_name}
                    </Text>
                    {
                        detailData
                        &&  <>
                                <Text
                                    size={"size3"}
                                >
                                    수량 : {detailData.product_qty}개
                                </Text>
                                <Text
                                    size={"size3"}
                                >
                                    필요한 사진 수 : {detailData.min_photo_count}개 ~ {detailData.max_photo_count}개
                                </Text>
                            </>
                    }
                    {
                        dateString
                        &&  <Text
                                size={"size3"}
                            >
                                편집 시작일: {dateString}
                            </Text>
                    }
                    <Text
                        size={"size3"}
                    >
                        {selectedProduct.goout === "Y" ? `배송시작(${selectedProduct.goout_date})` : "미출고"}
                    </Text>
                </div>
            </div>
        </MediaCard>
    )
}

export default ProductBlockDetail