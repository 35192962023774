/**
 * children 내부에 있는 img테그들을 추적하여 출력함
 * className을 통해 전체 적인 스타일을 적용해야한다.
 */

import React, { useRef } from "react";
// viewer.js의 기본 스타일
import 'viewerjs/dist/viewer.css';
import useImageGallery from "./hooks/useImageGallery.hook";
import ViewerToolBar from "./components/ViewerToolBar/ViewerToolBar";

const ImageGalleryContainer = (
    {
        class_name_list,
        children,
    }:{
        class_name_list?: string[];
        children?: React.ReactNode;
    }
) => {

    const galleryRef = useRef<HTMLDivElement | null>(null);

    const {
        isViewerVisible,
        viewerInstanceRef
    } = useImageGallery({
        galleryRef,
        dependency_key: children,
    })

    return(
        <>
            <div 
                className={class_name_list?.join("")}
                ref={galleryRef}
            >
                { children }

            </div>
            {/* 사용자 정의 툴바 */}
            {
                isViewerVisible
                &&  <ViewerToolBar viewerInstanceRef={viewerInstanceRef} />
            }
        </>
    )
}

export default ImageGalleryContainer;