import { useRef } from "react";
import Modal from "../../../Component/Elements/Modal/Modal";
import { scheduleProductInterface } from "../static/interface/ProductionList.interface";
import useProductionBtnState from "../hooks/useProductionBtnState.hook";
import Button from "../../../Component/Elements/Button/Button";
import { useNavigate } from "react-router-dom";
import ProductionRequestThemplete from "./ProductionRequestThemplete/ProductionRequestThemplete";
import useProductionRequestinitialData from "../hooks/useProductionRequestinitialData.hook";

const UploadedImageContainerModal = (
    {
        selectedProduct,
        isModalOpen,
        setIsModalOpen,
    }:{
        selectedProduct: scheduleProductInterface,
        isModalOpen: boolean;
        setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    }
) => {

    const { btnState } = useProductionBtnState(selectedProduct);

    // 각 폴더들에 대한 ref들 -> 최종적으로 서버에 요청을 보내서 저장할 때 스크롤을 컨트롤해야하기 때문에 외부에서 관리
    const cardRefs = useRef<Array<HTMLDivElement | null>>([]); 

    const {
        selectedOptionList, 
        request, 
        isSatisfiedPhotoNums, 
        customAddressData,
        detailAddress,
        setDetailAddress,
        albumData, 
        isAlbumProduct,
        photoProductOptionDataList,
        folderList,
        imgFiles, 
        isUseMultiFolder,
        album_save_id,
        contract_product_id
    } = useProductionRequestinitialData(selectedProduct);

    const navigate = useNavigate();
    
    // 모달 닫기버튼 동작
    const handleCloseModal = () => {
        setIsModalOpen(false);
    }

    const reRequestBtnHandler = () => {
        if(!window.confirm("요청사항을 변경하실경우 사용하실 사진들을 다시 올려주셔야합니다.\n진행하시겠습니까?")){
            return ;
        }

        navigate("/productionRequest", {
            state: {
                production: selectedProduct,
                isReRequest: true,
            }
        })
    }

    return (
        <Modal
            isModalOpen={isModalOpen}
            modalCloseHandler={handleCloseModal}
        >
            <ProductionRequestThemplete 
                folderList={folderList}
                imgFiles={imgFiles}
                isSatisfiedPhotoNums={isSatisfiedPhotoNums}
                photoProductOptionDataList={photoProductOptionDataList}
                selectedOptionList={selectedOptionList}
                isAlbumProduct={isAlbumProduct}
                albumData={albumData}
                request={request}
                customAddressData={customAddressData}
                detailAddress={detailAddress}
                setDetailAddress={setDetailAddress}
                
                cardRefs={cardRefs}
        
                isReadOnly={true}

                isUseMultiFolder={isUseMultiFolder}

                is_show_after_change_address_btn={selectedProduct.goout === "N"}

                album_save_id={album_save_id}

                contract_product_id={contract_product_id}
            >
                {
                    btnState === 5
                    &&  <div className="g_btn_wrap">
                            <Button
                                size={"size_full"}
                                purpose={"save"}
                                onClick={reRequestBtnHandler}
                            >
                                {"요청사항 변경"}
                            </Button>
                        </div>
                }
            </ProductionRequestThemplete>
        </Modal>
    )
}

export default UploadedImageContainerModal;