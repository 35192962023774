import { themaBookDetailListInterface } from "../../../../PublicPage/ThemeBook/static/interface/themeBook.interface";
import CSS from "./static/css/optionsContainer.module.css";
import OptionItemBlock from "../OptionItemBlock/OptionItemBlock";

const OptionsContainer = (
    {
        selectedProductOptionDataList,
        selectedOptionList,
        setSelectedOptionList,
        disabled,
    }:{
        selectedProductOptionDataList: themaBookDetailListInterface[];
        selectedOptionList: themaBookDetailListInterface[];
        setSelectedOptionList?: React.Dispatch<React.SetStateAction<themaBookDetailListInterface[]>>;
        disabled?: boolean;
    }
) => { 
    const handleSelectOption = (option_item: themaBookDetailListInterface) => {
        if(disabled === true){
            return ;
        }

        if(setSelectedOptionList){
            if(selectedOptionList.some((item) => item.thema_id === option_item.thema_id)){
                setSelectedOptionList((item) => item.filter((filter_item) => filter_item.thema_id !== option_item.thema_id));
            }else{
                setSelectedOptionList([option_item]);
            }
        }
    }

    return (
        <div className={CSS.l_options_container_main}>
            {
                disabled
                ?   selectedOptionList.map((item, index) => {
                        return(
                            <OptionItemBlock
                                key={index}
                                option_name={item.thema_name}
                                img_src={item.thema_thumbnail.middle_url}
                                is_checked={true}
                                isReadOnly={true}
                            />
                        )
                    })
                :   selectedProductOptionDataList.map((item, index) => {
                        return(
                            <OptionItemBlock
                                key={index}
                                img_src={item.thema_thumbnail.middle_url}
                                is_checked={selectedOptionList.some((selected_option_item) => selected_option_item.thema_id === item.thema_id)}
                                option_name={item.thema_name}
                                selectOptionHandler={() => {
                                    handleSelectOption(item);
                                }}
                            />
                        )
                    })
            }
        </div>
    )
}

export default OptionsContainer;