import { historyMapInterface } from "../interface/Header.interface";

export const getBackPath = (path: string) => {
    const history_map: historyMapInterface[] = [
        {
            parents: "/",
            children: [],
        },
        {
            parents: "/reservationHome",
            children: ["/selectTheme"],
        },
        {
            parents: "/photoList",
            children: ["/photoDetail"],
        },
        {
            parents: "/productionList",
            children: ["/productionRequest", "/productionDetail"]
        },
        {
            parents: "/themeBook",
            children: ["/themeBookDetailPage"],
        },
        {
            parents: "/contractDocs",
            children: ["/contractDetailViewerMobile"],
        },
        {
            parents: "/myInfo",
            children: [],
        },
    ]
    return history_map.find(item => item.children.includes(path));
};