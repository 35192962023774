import 'photoswipe/dist/photoswipe.css'
import { Gallery } from 'react-photoswipe-gallery';
import { viewerImageInterface } from '../ImageGallery/interface/ImageGallery.interface';
import ReactPhotoSwipeGalleryContents from './components/ReactPhotoSwipeGalleryContents/ReactPhotoSwipeGalleryContents';

// 커스텀 css. 
import "./static/css/PhotoSwipe.css";
import CardContainer from '../CardContainer/CardContainer';

const ReactPhotoSwipeGallery = (
    {
        img_list,
    }:{
        img_list: viewerImageInterface[]
    }
) => {
    const options = {
        arrowPrev: true,
        arrowNext: true,
        zoom: true,
        close: true,
        counter: true,
        bgOpacity: 1,
        // padding: { top: 20, bottom: 40, left: 100, right: 100 },
        wheelToZoom: true,
        showHideAnimationType: "none" as "none",
        // showAnimationDuration: 0,
        // hideAnimationDuration: 0,
        pswpModule: () => import('photoswipe'),
    }

    return(
        <CardContainer
            background_color={"ligth_gray"}
        >
            <Gallery 
                options={options} 
                // withDownloadButton
                withCaption
            >
                <ReactPhotoSwipeGalleryContents img_list={img_list} />
            </Gallery>
        </CardContainer>
    )
}

export default ReactPhotoSwipeGallery;