const totalLogout = () => {
    deleteAllCookies();
    localStorage.clear();

    window.location.reload();
}

const logout = () => {
    deleteAllCookies();
    
    window.location.reload();
}

class ActiveRequestsManager_Class {
    public activeRequestsRef: React.MutableRefObject<string[]>;

    constructor(activeRequestsRef: React.MutableRefObject<string[]>){
        this.activeRequestsRef = activeRequestsRef;
    }

    add(path: string) {
        if (path) {
            const list = [...this.activeRequestsRef.current];
            list.push(path);
            this.activeRequestsRef.current = list;
        } else {
            console.error("path를 입력해주세요.");
        }
    }

    sub(path: string) {
        if (path) {
            const list = [...this.activeRequestsRef.current];
            this.activeRequestsRef.current = list.filter((item) => item !== path);
        } else {
            console.error("path를 입력해주세요.");
        }
    }

    clear() {
        this.activeRequestsRef.current = [];
    }

    status() {
        return this.activeRequestsRef.current.length > 0;
    }
}

// 쿠키를 설정하는 함수
const setCookie = (
    {
        name, 
        value, 
        days,
        path,
    }:{
        name: string;
        value: string;
        days?: number;
        path?: string;
    }
) => {
    let path_value = "/";
    if(path){
        path_value = path
    }

    const cookie_parts: string[] = [`${name}=${value}`, `path=${path_value}`]

    // HTTPS 환경에서만 'secure' 추가
    if (window.location.protocol === 'https:') {
        cookie_parts.push("secure")
        cookie_parts.push("SameSite=Strict")
    }else{
        cookie_parts.push("SameSite=Lax")
    }
    
    if (days !== undefined && days > 0) {
        const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString();
        cookie_parts.push(`expires=${expires}`)
    }
    
    document.cookie = cookie_parts.join("; ");
}

// 쿠키값을 가져옴
const getCookie = (name: string) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2){
        return parts[1].split(';').shift() || null;
    }
    return null;
}

// 특정 쿠키 제거 함수
const deleteCookie = ({name, path}:{name: string; path?: string;}) => {
    let path_value = '/';
    if(path){
        path_value = path;
    }

    const cookie_parts: string[] = [`${name}=`, `path=${path_value}`, "expires=Thu, 01 Jan 1970 00:00:00 GMT"];

    // HTTPS 환경에서만 'secure' 추가
    if (window.location.protocol === 'https:') {
        cookie_parts.push("secure")
        cookie_parts.push("SameSite=Strict")
    }else{
        cookie_parts.push("SameSite=Lax")
    }

    document.cookie = cookie_parts.join("; ");
};

// 모든 쿠키 제거 함수
const deleteAllCookies = () => {
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.slice(0, eqPos) : cookie;

        deleteCookie({name, path: "/"})
    }
};

export { totalLogout, logout, ActiveRequestsManager_Class, setCookie, getCookie, deleteCookie, deleteAllCookies }