import loginMainPic from '../../Common/image/login/OOPS_1024x1024.png';
import Img from '../../Component/Elements/Media/components/Img/Img';
import LoginInputContainer from './components/LoginInputContainer';
import CSS from "./static/css/login.module.css";

function Login() {

    return (
        <div className={CSS.l_login_main}>
            <div className={CSS.l_login__main_contents_container}>
                <LoginInputContainer />
            </div>
            <div className={CSS.l_login__background_contents}>
                <div className={CSS.l_login__background_contents__logo_cover}>
                    <Img 
                        class_name_list={[CSS.l_login__background_contents__logo_cover__img]}
                        src={loginMainPic} 
                        object_fit="contain"
                    />
                </div>
            </div>
        </div>
    );
}
export default Login;
